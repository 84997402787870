export function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function joinStrList(strList, delimeter) {
    return strList.filter((str) => str && (str.length > 0)).join(delimeter);
}

export function spacedList(strList) {
    return joinStrList(strList, " ");
}

export function calculatePercentage(part, total) {
    if (total === 0) {
        return 0; // avoid division by zero
    }
    let percentage = (part / total) * 100;
    return parseFloat(percentage.toFixed(1))
}

export function queryParamsString(params) {
    return joinStrList(
        Object
            .entries(params)
            .map(([key, value]) => key && value && `${key}=${value}`),
        "&"
    );
}

export function limitLength(str, maxLength) {
    return str.slice(0, Math.min(str.length, maxLength))
}