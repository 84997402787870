import { FastArrowDown, FastArrowUp } from "iconoir-react";
import { useContext } from "react";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";
import { calculatePercentage } from "src/util/StringUtil";
import Select from "react-select";
import useFetchStudentAnswers from "src/api/goodpoint/useFetchStudentAnswers";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { customStyles } from "src/components/ui/PopupMathField";


//so fucking weird that this is an array
export function StudentsSelect({ examResults }) {
    const { selectedStudent, setSelectedStudent, students } = useContext(StudentsContext);
    const { examObject } = useContext(ExamContext);
    const fetchStudentAnswers = useFetchStudentAnswers(examObject.id);
    const students_names = fetchStudentAnswers();

    function getGrade(percentage) {
        for (const [grade, threshold] of Object.entries(examObject.exam_config.grade_thresholds)) {
            if (percentage >= threshold) {
                return grade;
            }
        }
    }

    const studentOptions = Object.entries(students ?? {}).map(([index, studentData]) => {
        return {
            value: index,
            label: (
                <div className="graded-student-button-grid fill-width justify-content-left">
                    <div className="flex justify-between w-full">
                        <p>
                            <b>{studentData.student_id}</b>
                        </p>
                        {students_names && studentData && studentData.student_id && students_names[studentData.student_id] && students_names[studentData.student_id].name ? (
                            <p>{students_names[studentData.student_id].name}</p>
                        ) : (
                            <p></p>
                        )}


                    </div>

                    <p className="justify-self-right">
                        {studentData.total_marks}/{examResults?.total_mark} Marks
                    </p>
                    <p className="graded-student-percentage justify-self-right">
                        (
                        {calculatePercentage(
                            studentData.total_marks,
                            examResults?.total_mark
                        )}
                        %)
                    </p>
                    <p className="graded-student-grade justify-self-right">
                        {getGrade(calculatePercentage(studentData.total_marks, examResults?.total_mark))}
                    </p>

                </div>
            ),
        };
    });


    // Handle student selection
    const handleStudentChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedStudent(students[selectedOption.value]);
        }
    };

    return (
        <Select
            className="basic-single shadow-lg"
            classNamePrefix="select"
            value={studentOptions.find((option) => selectedStudent === students[option.value])}
            styles={customStyles}
            isClearable={false}
            isSearchable={true}
            name="student"
            options={studentOptions}
            onChange={handleStudentChange}
            placeholder="Select a student"
        />
    );
}


export default function GradingStudentsList({
    examResults,
    orderBy,
    setOrderBy,
    isAscendingOrder,
    setIsAscendingOrder,
}) {
    return (
        <div className="grading-students-list flex-col gap-mid fill-width">
            <div className="flex-row justify-content-space-between align-center">
                <h2>Students</h2>
                <div className="feedback-order-by flex-row align-center margin-small">
                    <p>Order by:</p>
                    <select
                        className="order-by-select padding-x-small font-size-large margin-small"
                        value={orderBy}
                        onChange={(event) => setOrderBy(event.target.value)}
                    >
                        <option value="grade">Grade</option>
                        <option value="name">Name</option>
                    </select>
                    <div
                        className="cursor-pointer"
                        onClick={() => setIsAscendingOrder(!isAscendingOrder)}
                    >
                        {isAscendingOrder ? (
                            <FastArrowUp color="var(--zanista-orange-dark)" />
                        ) : (
                            <FastArrowDown color="var(--zanista-orange-dark)" />
                        )}
                    </div>
                </div>
            </div>
            <StudentsSelect examResults={examResults} />
        </div>
    );
}
