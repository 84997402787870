import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import { useEffect, useState } from 'react';
import useNewsWitchApi from 'src/api/newswitch/useNewsWitchApi';
//import { protectedResources } from 'src/auth/AuthConfig';
import { Link } from 'react-router-dom';

function ReportsListPage() {
    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="NewsWitch" href=".." />
                <Title title="Daily Reports"/>
                <ReportList />
            </div>
        </div>
    );
}

function ReportList({ publicMode = undefined }) {
    // Once isLoading is false, we can call the API using callAPI
    const callAPI = useNewsWitchApi();

    const [reports, setReports] = useState(undefined);

    // Fetch the inputs from the API
    useEffect(() => {
        const endpoint = publicMode ? "/public/reports" : "/reports";
        callAPI("GET", endpoint)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const reports = Object.values(body["reports"])
                    setReports(reports);
                });
            } else {
                console.error(response);
            }
        });
    }, [callAPI, publicMode]);

    return (
        <div className="flex-col gap-4 my-2">
            {
                reports === undefined ? <div>Loading reports...</div> : ( 
                    reports.length === 0 ? <div>No reports found</div> :
                        reports.map(report => {
                            // Convert each date string to a Date object
                            let date = new Date(report["date"]);
                            let time = ""
                            if (date.toString() === "Invalid Date") {
                                date = new Date(report["date"].slice(0, report["date"].lastIndexOf("-")));
                                time = report["date"].slice(report["date"].lastIndexOf("-") + 1);
                                time = time.slice(0, 2) + ":" + time.slice(2);
                            }
                            return (
                                <Link key={report["path"]} className='p-2 justify-between cursor-pointer rounded outline outline-1 bg-orange-300 flex-row gap-4'
                                    to={"/newswitch/report?blob=" + report["path"]} state={{ prevPage: window.location.pathname, publicMode: publicMode }}
                                >
                                    <div className='flex-row'>
                                        <h3 className='min-w-24'>
                                            {date.toDateString().slice(0, -5)}
                                        </h3>
                                        <h3>
                                            @ {time}
                                        </h3>
                                    </div>
                                    <p>
                                        Click to view
                                    </p>
                                </Link>
                            );
                        })
                )
            }
        </div>
    );
}

function DatabaseReportList() {
    // Once isLoading is false, we can call the API using callAPI
    const callAPI = useNewsWitchApi();

    const [reports, setReports] = useState(undefined);

    // Fetch the inputs from the API
    useEffect(() => {
        callAPI("GET", "/runs")?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const runs = Object.values(body["runs"])
                    setReports(runs);
                });
            } else {
                console.error(response);
                setReports([]);
            }
        });
    }, [callAPI]);

    return (
        <div className="flex-col gap-4 my-2">
            {
                reports === undefined ? <div>Loading reports...</div> : ( 
                    reports.length === 0 ? <div>No reports found</div> :
                        reports.map(report => {
                            // Convert unix timestamp to a Date object
                            let date = new Date(report["start_date"] * 1000);
                            return (
                                <Link key={report["path"]} className='p-2 justify-between cursor-pointer rounded outline outline-1 bg-orange-300 flex-row gap-4'
                                    to={"/newswitch/report?run_id=" + report["id"]} state={{ prevPage: window.location.pathname }}
                                >
                                    <div className='flex-row'>
                                        <h3 className='min-w-24'>
                                            {date.toDateString().slice(0, -5)}
                                        </h3>
                                        <h3>
                                            {date.toTimeString().slice(0, 5)}
                                        </h3>
                                    </div>
                                    <p>
                                        Click to view
                                    </p>
                                </Link>
                            );
                        })
                )
            }
        </div>
    );
}

export { ReportsListPage, ReportList, DatabaseReportList };
