// import "./ExamStructure.css";
import "src/pages/goodpoint/exam/components/structure/ExamStructure.css"
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Menu } from "iconoir-react";

export default function Sortable({ header, id, index, moveCard }) {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: "item",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: "item",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <div
            ref={ref}
            style={{ opacity }}
            data-handler-id={handlerId}
            className="w-full flex-row"
        >
            <div className="p-2 cursor-grabbing">
                <Menu className="active:text-lg transition-all ease-in" />
            </div>
            <div className="mb-[0.5px] shadow-lg padding-mid font-bold bg-orange-light border-mid structure-item-button rounded-small flex-row fill-width">
                {header}
            </div>
        </div>
    );
}
