import { protectedResources } from 'src/auth/AuthConfig';
import { queryParamsString } from "src/util/StringUtil";

const PROD_ENDPOINT = protectedResources.apiGoodPoint.endpoint;
const LOCAL_ENDPOINT = "http://localho.st:5000/goodpoint"

const GoodPointApi = {
    Base: process.env.REACT_APP_IS_DEV === 'true' ? LOCAL_ENDPOINT : PROD_ENDPOINT
}

GoodPointApi.User = `/user`

GoodPointApi.NewExam = `${GoodPointApi.Base}/new-exam`
GoodPointApi.ListExams = `${GoodPointApi.Base}/exams`

const Exam = (id) => `${GoodPointApi.Base}/exam/${id}`
Exam.Name = (id) => `${Exam(id)}/name`
Exam.Info = (id) => `${Exam(id)}/info`
Exam.Share = (id) => `${Exam(id)}/share`
Exam.Delete = (id) => `${Exam(id)}/delete`
GoodPointApi.Exam = Exam

const Rubric = (id) => `${Exam(id)}/rubric`
Rubric.Upload = (id) => `${Rubric(id)}/upload`
Rubric.Upload.Delete = (id, filename) => `${Rubric.Upload(id)}/${filename}`
Rubric.Config = (id) => `${Rubric(id)}/config`
Rubric.Process = (id) => `${Rubric(id)}/process`
Rubric.Logs = (id) => `${Rubric(id)}/logs`
// Rubric.Get = (id, question = null, sub_question = null) => `${Rubric(id)}?${queryParamsString({ question, sub_question })}`
Rubric.All = (id, type) => `${Rubric(id)}/all?${queryParamsString({ type })}`
Rubric.Combined = {
    View: (id) => `${Rubric(id)}/combined/view`,
    Download: (id) => `${Rubric(id)}/combined/download`
}
Rubric.Original = {
    View: (id, filename) => `${Rubric(id)}/original/view/${filename}`,
    Download: (id, filename) => `${Rubric(id)}/original/download/${filename}`
}
Rubric.Link = (id) => `${Rubric(id)}/combined/link`
Rubric.View = (id, rubricType) => `${Rubric(id)}/${rubricType}/view`
Rubric.Download = (id, rubricType) => `${Rubric(id)}/${rubricType}/download`
GoodPointApi.Rubric = Rubric

const Answers = (id) => `${Exam(id)}/answers`
Answers.Upload = (id) => `${Answers(id)}/upload`
Answers.Upload.Delete = (id, filename) => `${Answers.Upload(id)}/${filename}`
Answers.Config = (id) => `${Answers(id)}/config`
Answers.Process = (id) => `${Answers(id)}/process`
Answers.Logs = (id) => `${Answers(id)}/logs`
// Answers.Get = (id, student_id = null, question = null, sub_question = null) =>
//     `${Answers(id)}?${queryParamsString({ student_id, question, sub_question })}`
Answers.Students = (id) => `${Answers(id)}/students`
Answers.All = (id, student_id = null) => `${Answers(id)}${student_id ? `/${student_id}` : ""}/all`
Answers.Original = {
    View: (id, filename) => `${Answers(id)}/original/view/${filename}`,
    Download: (id, filename) => `${Answers(id)}/original/download/${filename}`
}
Answers.Download = (id, type, student_id) => `${Answers(id)}/${student_id}/${type}/download`
Answers.DownloadAll = (id) => `${Answers(id)}/all/download`
GoodPointApi.Answers = Answers

const Grading = (id) => `${Exam(id)}/grading`
Grading.Config = (id) => `${Grading(id)}/config`
Grading.Process = (id) => `${Grading(id)}/process`
Grading.Logs = (id) => `${Grading(id)}/logs`
const Feedback = {
    Class: (id) => `${Grading(id)}/feedback/class`,
    Student: (id, student_id) => `${Grading(id)}/feedback/${student_id}`
}
Feedback.Class.Download = (id, student_id) => `${Grading(id)}/feedback/download?${queryParamsString({ student_id })}`
Feedback.Overall = {
    Download: (id) => `${Grading(id)}/feedback/overall/download`
}
Grading.Feedback = Feedback
const Images = (id, image_name, queryParams = {}) => `${Grading(id)}/images/${image_name}?${queryParamsString(queryParams)}`
Grading.Images = Images
GoodPointApi.Grading = Grading

const Output = {
    Download: (id) => `${Exam(id)}/output/download`
}
GoodPointApi.Output = Output

export default GoodPointApi;