import { BrainResearch, PlusCircle, Upload } from 'iconoir-react';
import { Link } from 'react-router-dom';

import "./ExamItem.css"
import useCallApi from "src/api/useCallApi";
import { protectedResources } from 'src/auth/AuthConfig';
import { useNavigate } from 'react-router-dom';
import { Tab } from 'src/api/goodpoint/Const';
import { StepState } from 'src/api/goodpoint/Const';
import dayjs from 'dayjs';
import GoodPointApi from 'src/api/goodpoint/GoodPointApi';
import { TabIcons } from 'src/pages/goodpoint/exam/tabs/Tabs';
import IconButton from 'src/components/ui/IconButton';


function nextStepIcon({ rubric: rubricState, answers: answersState, grading: gradingState}) {
    if (rubricState === StepState.AVAILABLE) return Upload;
    if (rubricState < StepState.COMPLETED) return TabIcons[Tab.RUBRIC];
    if (answersState === StepState.AVAILABLE) return Upload;
    if (answersState < StepState.COMPLETED) return TabIcons[Tab.ANSWERS];
    if (gradingState < StepState.COMPLETED) return BrainResearch;
    return TabIcons[Tab.GRADING];
}

function nextStep({ rubric: rubricState, answers: answersState, grading: gradingState}) {
    switch (gradingState) {
        case StepState.COMPLETED: return "Graded"
        case StepState.PROCESSING: return "Grading..."
        case StepState.READY:
        case StepState.AVAILABLE: return "Ready to grade"
        default:
    }
    switch (answersState) {
        case StepState.PROCESSING: return "Processing student answers..."
        case StepState.READY: return "Process student answers"
        case StepState.AVAILABLE: return "Upload student answers"
        default:
    }
    switch (rubricState) {
        case StepState.PROCESSING: return "Processing rubric..."
        case StepState.READY: return "Process rubric"
        case StepState.AVAILABLE: return "Upload rubric"
        default:
    }
    return "Click to get started"  // This should never appear
}

export function NewExam() {
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    })
    const navigate = useNavigate();

    function createExam() {
        callApi("POST", GoodPointApi.NewExam)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    navigate(`exam/${body["data"]["id"]}`);
                });
            } else {
                console.error("Failed to create new exam: " + response.status + " " + response.statusText);
            }
        });
    }

    return <IconButton
        text={"New Exam"}
        fontSize='large'
        Icon={PlusCircle}
        iconSize={25}
        onClick={createExam}
        justifyContent='start'
        paddingSize='15px'
        gapSize='var(--gap-mid)'
        className={`fill-width`}
    />
}

export function ExamItem({ item, clickable = true }) {
    const { id: examId, name: examName, info: examInfo, state: examState } = item;
    const examDate = examInfo["date"] ? dayjs(examInfo["date"]).format('DD MMM YYYY') : "";
    const examCourse = examInfo["course_title"] !== "(course title)" ? examInfo["course_title"] : null;
    const examSubject = examInfo["subject"] !== "(subject)" ? examInfo["subject"] : null;

    const bgColour = item.grading_step === StepState.COMPLETED ? "bg-grey-light" : "bg-yellow-light";
    const ActionIcon = nextStepIcon(examState);
    return (
        <Link
            className={
                `exam-item ${clickable ? "clickable" : "cursor-disabled"} ${bgColour} ` +
                `w-full padding-large border-mid rounded-small `
            }
            to={`exam/${examId}`}
        >
            <div className={`exam-item-grid gap-large box-sizing-border-box`}>
                <p className='exam-title'><b>{examName}</b></p>
                <p className='exam-date'>{examDate}</p>
                <p className='exam-course-title'>{examCourse}</p>
                <p className='exam-subject'>{examSubject}</p>
                <div className='exam-action flex-row gap-mid justify-content-right'>
                    {nextStep(examState)}
                    <ActionIcon />
                </div>
            </div>
        </Link>
    )
}