import { useContext, useState, useCallback } from "react";
import { ExamsListContext } from "src/pages/goodpoint/dashboard/ExamsListContext";
import useCallApi from "src/api/useCallApi";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { deleteLocalExam } from "src/storage/ExamDesc";
import { protectedResources } from "src/auth/AuthConfig";


export default function useDeleteExam() {
    const { setExams } = useContext(ExamsListContext);
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    });

    const [deleting, setDeleting] = useState(false);

    const deleteExam = useCallback((id, afterDelete) => {
        setDeleting(true);
        callApi("POST", GoodPointApi.Exam.Delete(id))
            ?.then(response => {
                if (response.status === 200 || response.status === 400) {
                    console.log(`Deleted exam ${id}`);
                    setExams((exams) => {
                        deleteLocalExam(id);
                        const result = {...exams};
                        delete result[id];
                        return result;
                    });
                } else {
                    console.error(`Failed to delete exam: ${response.status} ${response.statusText}`);
                }
            }).finally(() => {
                setDeleting(false);
                afterDelete && afterDelete();
            });
    }, [callApi, setExams]);

    return {
        deleteExam,
        deleting
    };
}