import { useCallback, useEffect, useState } from "react";
import "./ExamPage.css";
import BackLink from "src/components/nav/BackLink";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import Divider from "src/components/layout/Divider";
import FooterControlBar from "src/pages/goodpoint/exam/FooterControlBar";
import { ExamTabs } from "src/pages/goodpoint/exam/tabs/Tabs";
import useCallApi from "src/api/useCallApi";
import { useTabsState } from "src/pages/goodpoint/exam/tabs/useTabsState";
import { Tab } from "src/api/goodpoint/Const";
import { loadCurrentTabById, setCurrentTabById } from "src/storage/CurrentTab";
import { saveRubric } from "src/storage/RubricStorage";
import LoadingExamSkeleton from "src/components/ui/LoadingExamSkeleton";
import { getExamObjectById, saveExamObject } from "src/storage/ExamObject";
import { ExamContext, EditMode } from "src/pages/goodpoint/exam/ExamContext";
import EditableExamName from "src/pages/goodpoint/exam/EditableExamName";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import InfoTab from "src/pages/goodpoint/exam/tabs/info/InfoTab";
import RubricTab from "src/pages/goodpoint/exam/tabs/rubric/RubricTab";
import AnswersTab from "src/pages/goodpoint/exam/tabs/answers/AnswersTab";
import GradingTab from "src/pages/goodpoint/exam/tabs/grading/GradingTab";
import useSendInfo from "src/api/goodpoint/useSendInfo";
import { compareLastModified } from "src/util/Time";


const TabComponentMap = {
    [Tab.INFO]: InfoTab,
    [Tab.RUBRIC]: RubricTab,
    [Tab.ANSWERS]: AnswersTab,
    [Tab.GRADING]: GradingTab,
};


export default function ExamPage() {
    const { examId } = useParams();
    const callApi = useCallApi();
    const [examObject, setExamObject] = useState(getExamObjectById(examId));
    const [queriedServer, setQueriedServer] = useState(false);
    const [rubric, setRubric] = useState(null);
    const [rubricUrl, setRubricUrl] = useState("");
    const [questionFeedback, setQuestionFeedback] = useState(null);
    const [studentFeedback, setStudentFeedback] = useState(null);
    const [classFeedback, setClassFeedback] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [students, setStudents] = useState(null);
    const [editMode, setEditMode] = useState(EditMode.DEFAULT);
    const [showPDF, setShowPDF] = useState(false);
    const [rightPDF, setRightPDF] = useState(false);
    const [file, setFile] = useState("");

    const updateExamObjectWithLastModified = useCallback((updates) => {
        setExamObject((obj) => {
            const newObj = {
                ...obj,
                ...updates,
                last_modified: dayjs().unix(),
            };
            saveExamObject(newObj);
            return newObj;
        });
    }, []);

    const updateExamState = useCallback((updates) => {
        updateExamObjectWithLastModified({ "state": { ...examObject["state"], ...updates }})
    }, [examObject, updateExamObjectWithLastModified])

    const sendInfo = useSendInfo(examId);

    const { tabsState, currentTab, nextTab, futureTab, changeTab } = useTabsState(
        {
            examState: examObject?.state ?? {},
            startTab: loadCurrentTabById(examId),
            onChangeTab: (oldTab, newTab) => {
                if (oldTab !== newTab) {
                    setFooterControls(null);
                }
                if (oldTab === Tab.INFO) {
                    sendInfo(examObject["info"]);
                }
                if (oldTab === Tab.RUBRIC) {
                    // sendRubric(examId, rubric, rubricUpdates.current); This is where the edited updates are sent to the server
                    saveRubric(examId, { rubric: rubric });
                }
                setShowPDF(false);
                setCurrentTabById(examId, newTab);
            },
        }
    );

    const [footerControls, setFooterControls] = useState(null);
    const [deleteSelected, setDeleteSelected] = useState([]);

    // Fetch exam object from API on load
    useEffect(() => {
        if (queriedServer) return;
        callApi("GET", GoodPointApi.Exam(examId))?.then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    const exam = body["data"];
                    if (!examObject || compareLastModified(exam, examObject)) {
                        setExamObject(exam);
                    } else {
                        setExamObject(examObject);
                        sendInfo(examObject["info"]);
                    }
                    setQueriedServer(true);
                });
            } else {
                console.error(
                    "Failed to fetch exam: " +
                    response.status +
                    " " +
                    response.statusText
                );
            }
        });
    }, [callApi, examId, examObject, queriedServer, sendInfo]);

    if (!examObject || !tabsState) {
        return <LoadingExamSkeleton />;
    }

    const TabComponent = TabComponentMap[currentTab];

    return (
        <div className="centered">
            <div className="container exam-details">
                <ExamContext.Provider
                    value={{
                        examObject: examObject,
                        updateExamObject: updateExamObjectWithLastModified,
                        examState: examObject["state"],
                        updateExamState: updateExamState,
                        footerControls: footerControls,
                        setFooterControls: setFooterControls,
                        deleteSelected: deleteSelected,
                        setDeleteSelected: setDeleteSelected,
                        rubric,
                        setRubric,
                        editMode,
                        setEditMode,
                        questionFeedback,
                        setQuestionFeedback,
                        studentFeedback,
                        setStudentFeedback,
                        classFeedback,
                        setClassFeedback,
                        selectedStudent,
                        setSelectedStudent,
                        students,
                        setStudents,
                        showPDF,
                        setShowPDF,
                        rightPDF,
                        setRightPDF,
                        file,
                        setFile,
                        currentTab,
                        rubricUrl,
                        setRubricUrl
                    }}
                >
                    <BackLink prevPage="AI Grader" href="/goodpoint" />
                    <EditableExamName />
                    <ExamTabs
                        tabsState={tabsState}
                        changeTab={changeTab}
                        currentTab={currentTab}
                    />
                    <Divider
                        lineColour="rgba(0, 0, 0, 0.1)"
                        shadowColour="rgba(0, 0, 0, 0.3)"
                    />
                    <div className="exam-content">
                        <TabComponent />
                    </div>
                    {currentTab && (
                        <FooterControlBar
                            changeTab={changeTab}
                            currentTab={currentTab}
                            nextTab={nextTab}
                            futureTab={futureTab}
                            footerControls={footerControls}
                        />
                    )}
                </ExamContext.Provider>
            </div>
        </div>
    );
}
