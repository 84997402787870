import { ExamStructure } from "src/pages/goodpoint/exam/components/structure/ExamStructure";
import "./AnswersStructure.css"
import AnswerItem from "src/pages/goodpoint/exam/tabs/answers/structure/AnswerItem";
import { useContext } from "react";
import LoadingTab from "src/components/ui/LoadingTab";
import { useHasRole } from "src/api/useGetRole";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";


export default function AnswersStructure({ selectedStudent, structureMap }) {
    const { answerPdfUrl } = useContext(StudentsContext);
    const studentConfig = selectedStudent["config"];
    const { student_name: studentName } = studentConfig;

    // const handleNameChange = (event) => {
    //     const newName = event.target.value;
    //     setStudents(prevState => {
    //         const updatedStudents = { ...prevState };
    //         updatedStudents[selectedStudent.student_id].name = newName;
    //         setAllStudentAnswers(examObject.id, updatedStudents);
    //         return updatedStudents;
    //     })
    //     const updatedStudentResults = getGradingAllStudentsResults(examObject.id);
    //     if (updatedStudentResults && updatedStudentResults.hasOwnProperty(selectedStudent.student_id)) {
    //         updatedStudentResults[selectedStudent.student_id].name = newName;
    //         saveGradingAllStudentsResults(examObject.id, updatedStudentResults);
    //     }
    //     setSelectedStudent((ss) => { return {...ss, name: newName}});
    // }

    // const endpoint = GoodPointApi.Answers.File(examObject.id, selectedStudent.student_id)

    // const fetcher = url => axios.get(url).then(res => res.data);
    // const { data, isLoading, error } = useSWR(endpoint, fetcher, {
    //     revalidateOnFocus: false,
    //     revalidateOnReconnect: false,
    //     refreshInterval: 172000
    // });

    const readOnly = useHasRole("student");

    function AnswersHeader({ structureMap }) {
        const numQuestions = Object.keys(structureMap).length;
        return (
            <div className="grid grid-cols-3 gap-10 align-center">
                {
                    !readOnly &&
                        <div className="flex-row align-center gap-2">
                            <b>Name: </b>
                            <input
                                className={
                                    `border-2 border-grey-500 rounded-lg px-2 focus:outline-black ` + 
                                    `hover:border-2 hover:border-[var(--zanista-orange-dark)]`
                                }
                                defaultValue={studentName}
                                // onBlur={handleNameChange}
                                disabled={readOnly}
                            />
                        </div>
                }
                <div><b>ID:</b> {selectedStudent["id"]}</div>
                <div className=" justify-self-end text-right">
                    <p>{numQuestions} Question{(numQuestions > 1) ? "s" : ""} Answered</p>
                </div>
            </div>
        )
    }

    if (!structureMap) {
        return <LoadingTab />
    }

    return (
        <div className="flex-col gap-mid">
            <ExamStructure
                structureMap={structureMap}
                HeaderComponent={AnswersHeader}
                ItemComponent={AnswerItem}
                listDisplay={[0, 0, 1, 1, 1, 0]}
                fileUrl={answerPdfUrl}
            />
        </div>
    )
}