import { loadFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";


export function getExamObjects() {
    return loadFromLocalStorage(StorageKeys.ASSESSMENT_OBJECTS, {});
}

export function setExamObjects(examObjects) {
    return saveToLocalStorage(StorageKeys.ASSESSMENT_OBJECTS, examObjects);
}

export function getExamObjectById(examId) {
    return getExamObjects()[examId];
}

export function saveExamObject(exam) {
    const newExamObjects = getExamObjects();
    newExamObjects[exam.id] = exam;
    return setExamObjects(newExamObjects);
}