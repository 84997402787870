import { useState, useLayoutEffect, useCallback } from "react";
import { StepState } from "src/api/goodpoint/Const";
import { Tab, TabState } from "src/api/goodpoint/Const";

export function useTabsState({ examState, startTab, onChangeTab }) {
    const { rubric: rubricState, answers: answersState, grading: gradingState } = examState;
    const [tabsState, setTabsState] = useState(undefined);
    const [currentTab, setCurrentTab] = useState(() => { return startTab ?? Tab.INFO });
    const [nextTab, setNextTab] = useState(undefined);
    const [futureTab, setFutureTab] = useState(undefined);

    const changeTab = useCallback((tab) => {
        if (tab === currentTab) return;
        const oldTab = currentTab;
        var newTabs = { ...tabsState }
        if (currentTab === nextTab) {
            newTabs[currentTab] = TabState.NEXT;
        } else {
            newTabs[currentTab] = TabState.COMPLETE;
        }
        newTabs[tab] = TabState.CURRENT;
        setCurrentTab(tab);
        setTabsState(newTabs);
        onChangeTab && onChangeTab(oldTab, tab);
    }, [currentTab, nextTab, tabsState, onChangeTab]);

    useLayoutEffect(() => {
        const newTabs = {
            [Tab.INFO]: TabState.COMPLETE,
            [Tab.RUBRIC]: rubricState === StepState.COMPLETED ? TabState.COMPLETE : TabState.INCOMPLETE,
            [Tab.ANSWERS]: answersState === StepState.COMPLETED ? TabState.COMPLETE : TabState.INCOMPLETE,
            [Tab.GRADING]: gradingState === StepState.COMPLETED ? TabState.COMPLETE : TabState.INCOMPLETE
        }

        var newNextTab, newFutureTab;

        if (rubricState > StepState.NOT_AVAILABLE && rubricState < StepState.COMPLETED) {
            newTabs[Tab.RUBRIC] = TabState.NEXT;
        }
        if (answersState > StepState.NOT_AVAILABLE && answersState < StepState.COMPLETED) {
            newTabs[Tab.ANSWERS] = TabState.NEXT;
        }
        if (gradingState > StepState.NOT_AVAILABLE && gradingState < StepState.COMPLETED) {
            newTabs[Tab.GRADING] = TabState.NEXT;
        }

        if (gradingState > StepState.NOT_AVAILABLE) {
            [newNextTab, newFutureTab] = (gradingState === StepState.COMPLETED)
                ? [null, null]
                : [Tab.GRADING, null];
        } else if (answersState > StepState.NOT_AVAILABLE) {
            [newNextTab, newFutureTab] = (answersState === StepState.COMPLETED)
                ? [Tab.GRADING, null]
                : [Tab.ANSWERS, Tab.GRADING];
        } else if (rubricState > StepState.NOT_AVAILABLE) {
            [newNextTab, newFutureTab] = (rubricState === StepState.COMPLETED)
                ? [Tab.ANSWERS, Tab.GRADING]
                : [Tab.RUBRIC, Tab.ANSWERS];
        } else {
            // This should never happen
            [newNextTab, newFutureTab] = [Tab.RUBRIC, Tab.ANSWERS];
        }

        if (newNextTab != null) {
            newTabs[newNextTab] = TabState.NEXT;
        }

        setNextTab(newNextTab);
        setFutureTab(newFutureTab);
        setTabsState(newTabs);
    }, [rubricState, answersState, gradingState]);

    useLayoutEffect(() => {
        switch (nextTab) {
            case Tab.RUBRIC: setFutureTab(Tab.ANSWERS); return;
            case Tab.ANSWERS: setFutureTab(Tab.GRADING); return;
            case Tab.GRADING: setFutureTab(null); return;
            default: return;
        }
    }, [nextTab])

    useLayoutEffect(() => {
        if (tabsState == null || tabsState[currentTab] === TabState.CURRENT) return;
        setTabsState({ ...tabsState, [currentTab]: TabState.CURRENT });
    }, [currentTab, tabsState])

    return {
        tabsState: tabsState,
        currentTab: currentTab,
        nextTab: nextTab,
        futureTab: futureTab,
        changeTab: changeTab
    }
}