import { useContext } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import ProcessingWaitPage from "src/pages/goodpoint/exam/components/ProcessingWaitPage";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


export default function GradingProcessing() {
    const { examObject } = useContext(ExamContext);
    return <ProcessingWaitPage
        logsEndpoint={GoodPointApi.Grading.Logs(examObject.id)}
        stateName={"grading"}
        text="Grading exam..."
    />
}