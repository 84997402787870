import { useState, useContext, useEffect } from "react";
import { ExamItem } from "src/pages/goodpoint/dashboard/ExamItem";
import Divider from "src/components/layout/Divider";
import { Check, TrashSolid } from "iconoir-react";
import dayjs from "dayjs";
import DeleteExamButton from "src/pages/goodpoint/dashboard/DeleteExamButton";
import { ExamsListContext } from "src/pages/goodpoint/dashboard/ExamsListContext";

import "./ExamMenu.css";
import { IconButton, Tooltip } from "@mui/material";
import { useHasRole } from "src/api/useGetRole";

function ExamMenuHeader({ title, editMode, editFunction }) {
    const readOnly = useHasRole("student");

    return (
        <div className="flex-row justify-content-space-between">
            <h2 className="">{title}</h2>
            {
                editFunction &&
                <Tooltip title="Delete exam" placement="top">
                    {!readOnly &&
                        <IconButton onClick={editFunction}>
                            {!editMode ? <TrashSolid /> : <Check />}
                        </IconButton>
                    }
                </Tooltip>
            }
        </div>
    )
}

export default function ExamMenu({ title, filter }) {
    const { exams } = useContext(ExamsListContext);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        // Stop editing after we delete an exam
        setEditMode(false);
    }, [exams]);

    const items = Object.values(exams)?.filter(filter) ?? [];
    if (items.length === 0) {
        return null
    }

    return (
        <div className='exam-menu'>
            <ExamMenuHeader title={title} editMode={editMode} editFunction={items.length > 0 && (() => setEditMode(!editMode))} />
            <Divider />
            <div className="flex-col gap-large">
                {
                    items.sort((a, b) => dayjs(a.date).isBefore(b.date) ? -1 : 1).map((item, index) => (
                        <div className="flex-row">
                            <ExamItem
                                key={`${title} ${item.id} ${index}`}
                                item={item}
                                clickable={!editMode}
                            />
                            {
                                editMode && <DeleteExamButton examItem={item} />
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}