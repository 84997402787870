import { useCallback, useEffect, useMemo, useState } from "react";
import useCallApi from "src/api/useCallApi";

export default function useMemoFetch({
    apiEndpoint,
    requestData = null,
    processResponse,
    finallyFunc = null,
    cacheGet = null,
    cacheSave = null
}) {
    const callApi = useCallApi();

    const [fetchedEndpoint, setFetchedEndpoint] = useState(null);
    const [fetchedValue, setFetchedValue] = useState(null);

    const value = useMemo(() => {
        if (fetchedValue) {
            return fetchedValue;
        }
        return cacheGet && cacheGet();
    }, [fetchedValue, cacheGet]);

    const fetchValue = useCallback((endpoint) => {
        return callApi("GET", endpoint, requestData)
            ?.then(response => processResponse(response))
            .then(result => {
                cacheSave && cacheSave(result);
                setFetchedValue(result);
                setFetchedEndpoint(endpoint);
            })
            .finally((result) => finallyFunc && finallyFunc(result));
    }, [callApi, requestData, processResponse, cacheSave, finallyFunc]);

    useEffect(() => {
        if (!fetchedEndpoint) return;
        if (fetchedEndpoint !== apiEndpoint) {
            setFetchedValue(null);
        }
    }, [fetchedValue, fetchedEndpoint, apiEndpoint]);

    const getValue = useCallback(() => {
        if (value) {
            return value;
        } else {
            fetchValue(apiEndpoint);
        }
    }, [value, fetchValue, apiEndpoint]);

    return getValue;
}