import { Collapse, Expand, OpenNewWindow } from 'iconoir-react';
import React, { useMemo } from 'react';
import { useCollapse } from 'react-collapsed';
import { useParams, useOutletContext } from 'react-router-dom';
import BackLink from 'src/components/nav/BackLink';
import IconButton from 'src/components/ui/IconButton';
import PdfViewer from 'src/components/ui/PdfViewer';


function ItemHeader({ itemData }) {
    const title = itemData.title;
    const category = itemData.category;
    return (
        <div className="w-full text-center flex-row align-center justify-between">
            <h2 className="font-bold pb-0">{title}</h2>
            <h2 className="text-zanista-orange pr-3">{category}</h2>
        </div>
    );
}

export function ItemContent({ itemData }) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
        collapsedHeight: 647,
        hasDisabledAnimation: true
    });

    const pdfViewer = useMemo(() => {
        return (
            <PdfViewer
                file={itemData.URL}
                fileName={itemData.title}
                showToolbar={false}
                onClose={() => { }}
                height="600px"
                post={itemData}
            />
        )
    }, [itemData])

    return (
        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-8">
                {pdfViewer}
                <div className="lg:hidden px-2">
                    <IconButton
                        Icon={OpenNewWindow}
                        text={"Open file"}
                        bgColour={"var(--yellow-light)"}
                        paddingSize="2px 5px"
                        borderSize="var(--border-thin)"
                        roundedSize="var(--rounded-xsmall)"
                        onClick={() => {
                            window.open(itemData.URL);
                        }}
                    />
                </div>

            </div>
            <div className="col-span-12 lg:col-span-4">
                <div  {...getCollapseProps()} className="p-4 bg-white rounded-tl-3xl rounded-br-3xl">
                    <div className="flex-row justify-between w-full">
                        <p className="py-2 font-bold">
                            {new Date(itemData.date).toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric"
                            })}
                        </p>
                        <IconButton
                            Icon={isExpanded ? Collapse : Expand}
                            text={isExpanded ? "Collapse" : "Expand"}
                            bgColour={isExpanded ? "var(--zanista-orange-mid)" : "var(--zanista-yellow-light)"}
                            paddingSize="2px 5px"
                            borderSize="var(--border-thin)"
                            {...getToggleProps()}
                        />
                    </div>

                    <p className="py-2 break-words whitespace-pre-wrap">{itemData.description}</p>
                    <div className="py-2 flex flex-wrap gap-2 text-start">
                        {itemData.tags.map((tag, index) => (
                            <span key={index} className="bg-secondary text-sm px-2 py-1 rounded-md font-bold">
                                {tag}
                            </span>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

function BlogPost() {
    const { postId } = useParams();
    const { posts } = useOutletContext();
    const post = posts.find((p) => p.id === postId);
    if (!post) {
        return <div>Post not found</div>;
    }
    return (
        <div className="flex-col gap-5">
            <BackLink prevPage={"PaperPal"} href={"/paperpal"} />
            <ItemHeader itemData={post} />
            <ItemContent itemData={post} />
        </div>
    );
};

export default BlogPost;
