import { useState } from "react"
import Select from "react-select";
import { ItemContent } from "./BlogPost";
import { useOutletContext } from "react-router-dom";
import { StructureItem } from "src/pages/goodpoint/exam/components/structure/StructureItem";
import { ExamStructure } from "src/pages/goodpoint/exam/components/structure/ExamStructure";


const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const monthOptions = [
    { value: "", label: "All" },
    { value: "1", label: 'January' },
    { value: "2", label: 'February' },
    { value: "3", label: 'March' },
    { value: "4", label: 'April' },
    { value: "5", label: 'May' },
    { value: "6", label: 'June' },
    { value: "7", label: 'July' },
    { value: "8", label: 'August' },
    { value: "9", label: 'September' },
    { value: "10", label: 'October' },
    { value: "11", label: 'November' },
    { value: "12", label: 'December' }
];


const yearOptions = [
    { value: "", label: "All" },
    ...Array.from({ length: 5 }, (v, i) => ({
        value: (currentYear - i).toString(),
        label: (currentYear - i).toString()
    }))
];

const postCategoryOptions = [
    { value: "", label: "All" },
    { value: "finance", label: "Finance" },
    { value: "ai", label: "AI" }
]

function ItemHeader({ itemData }) {
    return (
        <div className="w-full">
            <div className="flex-row flex justify-between">
                <b>
                    {itemData.title}
                </b>
            </div>
        </div>
    );
}

export function Header() {
    return (
        <div></div>
    );
}


export function ItemComponent(props) {
    return (
        <StructureItem
            HeaderComponent={ItemHeader}
            ContentComponent={ItemContent}
            {...props}
        />
    );
}

export default function BlogPostsList() {
    const [postCategory, setPostCategory] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const { posts } = useOutletContext();


    const handleMonthChange = (selectedOption) => {
        if (selectedOption) {
            setMonth(selectedOption);
        }
    };

    const handleYearChange = (selectedOption) => {
        if (selectedOption) {
            setYear(selectedOption);
        }
    };

    const handlePostCategoryChange = (selectedOption) => {
        if (selectedOption) {
            setPostCategory(selectedOption);
        }
    }

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.innerText.toLowerCase()); // Store the search term in lowercase
    };

    const Toolbar = () => {
        return (
            <div className="grid grid-cols-12 gap-4 w-full">
                <Select
                    className="basic-single shadow-lg col-span-12 lg:col-span-2 w-full"
                    classNamePrefix="select"
                    value={postCategory}
                    isSearchable={true}
                    name="category"
                    options={postCategoryOptions}
                    onChange={handlePostCategoryChange}
                    placeholder="Category"
                />

                <span
                    className="border-2 border-gray-200 bg-white rounded-mdx px-4 py-2 h-10 col-span-12 lg:col-span-6 w-full"
                    contentEditable
                    onBlur={handleSearchChange} // Handle typing in the search box
                >{searchTerm}
                </span>
                <Select
                    className="basic-single shadow-lg col-span-6 lg:col-span-2 w-full"
                    classNamePrefix="select"
                    value={month}
                    isSearchable={true}
                    name="month"
                    options={monthOptions}
                    onChange={handleMonthChange}
                    placeholder="Month"
                />

                <Select
                    className="basic-single shadow-lg col-span-6 lg:col-span-2 w-full"
                    classNamePrefix="select"
                    value={year}
                    isSearchable={true}
                    name="year"
                    options={yearOptions}
                    onChange={handleYearChange}
                    placeholder="Year"
                />
            </div>
        )
    }

    const filteredData = Object.values(posts).filter(post => {
        const regex = /^(\d{4})-(\d{2})-\d{2}$/;
        const match = post.date.match(regex);
        const post_year = match[1];
        const post_month = match[2];
        const matchesCategory = (postCategory && postCategory.value) 
            ? post.category.toLowerCase() === postCategory.value 
            : true;
        console.log(post.date);
        const matchesMonth = month && month.value ? parseInt(post_month) === parseInt(month.value) : true;
        const matchesYear = year && year.value ? parseInt(post_year) === parseInt(year.value) : true;

        const matchesSearchTerm = searchTerm
            ? post.description.toLowerCase().includes(searchTerm) ||
            post.tags.some(tag => tag.toLowerCase().includes(searchTerm))
            : true;

        return matchesCategory && matchesMonth && matchesYear && matchesSearchTerm;
    });

    return (
        <div className="space-y-4">
            <h2 className="text-center font-bold text-2xl">PaperPal</h2>
            <Toolbar />
            {filteredData.length > 0 ? (
                <ExamStructure
                    structureMap={filteredData}
                    HeaderComponent={Header}
                    ItemComponent={ItemComponent}
                    listDisplay={[0, 0, 0, 0, 0, 0]}
                    disableEditToolbar={true}
                />) : (
                <div className="text-center p-4 text-xl">Loading...</div>
            )}
        </div>
    )

}