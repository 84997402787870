export function makeCards(structure) {
    return Object.keys(structure)
        .reduce((acc, key) => {
            acc.push({
                tag: structure[key].tag, // Copy the tag attribute
                id: Number(structure[key].question_number), // Use the item's index from the structure and convert to a number if necessary
            });
            return acc;
        }, [])
        .sort((a, b) => a.id - b.id); // Sort the array by id (numerically)
}

//update the rubric (structure) based on the ordering in cards
export function reorderStructureByTags(newCards, structure) {
    const tagToIndexMap = newCards.reduce((map, card, index) => {
        map[card.tag] = index;
        return map;
    }, {});

    // Convert the structure object into an array, process it, then convert it back to an object
    let reorderedStructure = Object.entries(structure).map(([key, item]) => {
        // Assuming each 'item' is an object that includes a 'tag'
        return {
            ...item,
            question_number: tagToIndexMap[item.tag], // Update the index based on the tag
        };
    });

    // Sort the array based on the new index
    reorderedStructure.sort((a, b) => a.question_number - b.question_number);

    // Convert the array back to an object with the original keys
    let resultStructure = {};
    reorderedStructure.forEach((item, idx) => {
        resultStructure[item.tag] = item;
    });

    return resultStructure;
}

// function adjustMarksTopDown(currentNode, mark) {
//     let childrenMarks = Object.values(currentNode["sub_items"]).map(
//         (q) => q.mark
//     );
//     const newChildrenMarks = adjustListToSum(childrenMarks, mark);
//     const questionKeys = Object.keys(currentNode["sub_items"]);

//     questionKeys.forEach((questionKey, index) => {
//         currentNode["sub_items"][questionKey] = {
//             ...currentNode["sub_items"][questionKey],
//             mark: newChildrenMarks[index],
//         };

//         // If there are further nested sub_items, recurse
//         if (
//             Object.keys(currentNode["sub_items"][questionKey].sub_items || {})
//                 .length > 0
//         ) {
//             adjustMarksTopDown(
//                 currentNode["sub_items"][questionKey],
//                 newChildrenMarks[index]
//             );
//         }
//     });
// }

// function adjustStepMarksTopDown(currentNode, marks) {
//     let childrenMarks = currentNode["steps"].map((q) => parseFloat(q.marks));
//     const newChildrenMarks = adjustListToSum(childrenMarks, marks);

//     for (let i = 0; i < currentNode.steps.length; i++) {
//         currentNode.steps[i].marks = newChildrenMarks[i];
//     }
// }

export function adjustMarksBottomUp(rubric) {
    function calculateMarks(question) {
        let totalMarks = 0;
        if (question.sub_items && question.sub_items.length > 0) {
            for (const subQuestion in question.sub_items) {
                totalMarks += calculateMarks(subQuestion); // Recursively calculate marks for sub-questions
            }
            question.marks = totalMarks;
        } else {
            totalMarks = question.marks;
        }
        return totalMarks;
    }

    for (const question in rubric) {
        calculateMarks(question);
    }

    return rubric;
}

//modes - content, delete, marks
export function updateNestedStructure(
    obj,
    hierarchy,
    values = {},
    mode = "content",
    step_index = 0
) {
    return;
    // let current = obj;

    // for (let i = 0; i < hierarchy.length; i++) {
    //     const key = hierarchy[i];

    //     if (
    //         !current[key] ||
    //         (i < keys.length - 1 && !current[key]["sub_items"])
    //     ) {
    //         // console.error("The expected structure does not exist.");
    //         return;
    //     }

    //     // If not the last key, dive into 'sub_items', otherwise prepare to update
    //     if (i < keys.length - 1) {
    //         if (!current[key]["sub_items"]) {
    //             console.error(`Sub-questions for key '${key}' not found.`);
    //             return;
    //         }
    //         current = current[key]["sub_items"];
    //     } else {
    //         if (mode === "delete") {
    //             let question = current[key];
    //             delete current[key];
    //             return question;
    //         } else if (mode === "content") {
    //             current[key] = { ...current[key], ...values };
    //         } else if (mode === "mark") {
    //             current[key] = { ...current[key], ...values };
    //             if (
    //                 current[key]["sub_items"] &&
    //                 Object.keys(current[key]["sub_items"]).length > 0
    //             ) {
    //                 adjustMarksTopDown(current[key], values.mark);
    //             } else {
    //                 adjustStepMarksTopDown(current[key], values.mark);
    //             }
    //         } else if (mode === "marks") { //so fucking annoying that some of it is marks and some of it is mark
    //             current[key] = { ...current[key], ...values };
    //         } else if (mode === "steps") {
    //             current[key]["steps"][step_index] = {
    //                 ...current[key]["steps"][step_index],
    //                 ...values,
    //             };
    //             const steps = current[key]["steps"]

    //             let totalMark = 0;
    //             current[key]["steps"].forEach((item) => {
    //                 totalMark += parseFloat(item.marks); // Ensure it's parsed as a float
    //             });

    //             current[key]["mark"] = totalMark;
    //             return steps;
    //         } else if (mode === "question_feedback") {
    //             current[key]["question_feedback"] = values;
    //         }
    //     }
    // }
}

//top down adjusting marks
export function adjustListToSum(lst, targetSum) {
    function roundToHalf(value) {
        return Math.round(value * 2) / 2;
    }

    const originalSum = lst.reduce((acc, val) => acc + val, 0);
    const multiplier = originalSum !== 0 ? targetSum / originalSum : 1;

    let adjustedLst = lst.map((num) => roundToHalf(num * multiplier));
    adjustedLst.reverse();

    let diff = parseFloat(
        (targetSum - adjustedLst.reduce((acc, val) => acc + val, 0)).toFixed(1)
    );
    let countedAdj = 0;

    while (Math.abs(diff) > 0.01 && countedAdj <= 100) {
        countedAdj += 1;
        for (let i = 0; i < adjustedLst.length; i++) {
            if (diff > 0) {
                adjustedLst[i] += 0.5;
                diff = parseFloat((diff - 0.5).toFixed(1));
                if (Math.abs(diff) < 0.01) break;
            } else if (diff < 0) {
                adjustedLst[i] -= 0.5;
                diff = parseFloat((diff + 0.5).toFixed(1));
                if (Math.abs(diff) < 0.01) break;
            }
        }
    }
    adjustedLst.reverse();
    return adjustedLst;
}


export function isRubric(structure = {}, heading = "") {
    if (structure) {
        return Object.keys(structure).some(
            (key) => "question_text" in structure[key]
        );
    }
    if (heading) {
        return heading !== "Answer" && heading !== "Feedback";
    }
}

export function isStudentPerformance(structure) {
    if (structure) {
        return Object.keys(structure).some(
            (key) => "feedback" in structure[key]
        );
    }
}

export function tokenizeText(text) {
    if (!text) {
        return {};
    }
    // Regex to match LaTeX between $...$ or $$...$$
    const regex = /(\$\$[\s\S]*?\$\$|\$.*?\$)/g;
    const tokens = text.split(regex);

    // Create tokens with the filter condition applied
    let filteredTokens = tokens
        .map((token, index) => {
            if (token.trim() === "") {
                return null; // Return null for empty tokens
            }
            if (token.startsWith("$$") && token.endsWith("$$")) {
                return { type: "latex", content: token, id: index };
            }
            if (token.startsWith("$") && token.endsWith("$")) {
                return { type: "latex", content: token, id: index };
            }
            return { type: "text", content: token, id: index };
        })
        .filter((token) => token !== null); // Filter out null tokens

    if (filteredTokens[filteredTokens.length - 1].type === "latex") {
        filteredTokens.push({
            id: filteredTokens.length,
            content: "",
            type: "text",
        });
    }
    // Update the IDs of the remaining tokens to be consecutive
    filteredTokens = filteredTokens.map((token, index) => ({
        ...token,
        id: index,
    }));

    return filteredTokens;
};

export function unTokenizeText(tokens) {
    if (!Array.isArray(tokens)) {
        return "";
    }
    const content = tokens.map((token) => token.content).join("");
    return content;
}

export function addKeysToSubquestions(data, parentKeys = []) {
    const result = {};

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const currentKeys = [...parentKeys, key];

            const valueWithKeys = { ...data[key], keys: currentKeys };

            if (valueWithKeys.sub_items) {
                valueWithKeys.sub_items = addKeysToSubquestions(
                    valueWithKeys.sub_items,
                    currentKeys
                );
            }

            result[key] = valueWithKeys;
        }
    }

    return result;
}

export const findNestedQuestionText = (itemData, rubric) => {
    if (!itemData || !rubric) {
        return;
    }
    const keys = itemData.keys;
    let current = rubric;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (!current[key]) {
            console.error(`Key '${key}' not found in the rubric.`);
            return null; // Return null if key doesn't exist
        }

        // If not the last key, dive into 'sub_items'
        if (i < keys.length - 1) {
            current = current[key]["sub_items"];

            // Ensure that sub_items exist
            if (!current) {
                console.error(`Sub-questions for key '${key}' not found.`);
                return null; // Return null if sub_items don't exist
            }
        } else {
            // Return question_text if it's the last key
            return current[key].question_text || null;
        }
    }
};