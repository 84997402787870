import "./Products.css"
import pipelineImg from 'src/assets/home/dissertation-pipeline.jpg';
//import ReactPlayer from 'react-player/youtube'
import { ContactPopup, Subject } from 'src/pages/home/components/ContactPopup';
import FeatureItem from 'src/pages/home/components/FeatureItem';
import ProductHeroSection from 'src/pages/home/components/ProductHeroSection';
import heroImg from 'src/assets/home/dissertation-hero.jpg';
import { NavArrowRight } from "iconoir-react";
import ScrollAnimation from "react-animate-on-scroll";

const demoPlaceholder = "I would like to set up a short meeting to find out more about Paperpal. Please reach out to me by email."

function DissertationCopilotProduct() {
    return (
        <div className="">
            <ProductHeroSection
                title="PaperPal"
                subtitle="Automated Academic Insights"
                image={heroImg} square
                CTASection={CTASection}
            />
            <FeaturesSection />
            {
                //<HowItWorksSection />
            }
            <div className="pt-9">
                <TryNowSection />
            </div>
        </div>
    );
}

const CTASection = () => {
    const bigger = true;
    return (
        <div className="flex-row justify-start gap-4">
            <ContactPopup subject={Subject.DEMO}>
                <button className={`clickable ${bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'} leading-snug bg-white hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`}>
                    Request a demo
                    <NavArrowRight className="inline-block" />
                </button>
            </ContactPopup>
        </div>
    )
}

function FeaturesSection() {
    return (

        <div className="flex-col justify-start items-start pb-4">
            <ScrollAnimation
                animateIn='fadeIn'
                animateOut='fadeOut'
            >
                <div className="flex-col gap-6 pb-6">
                    <FeatureItem title="Advanced Curation" text="Utilizing AI, we scan thousands of research papers across the internet within a specified subject and timeframe, selectively compiling top-tier publications from renowned journals and authors at leading institutions." />
                    <FeatureItem title="Intelligent Summarization" text="We employ LLMs to concisely summarize key findings, offering an insightful and fully automated newsletter containing the latest academic breakthroughs." />
                </div>
            </ScrollAnimation>

            <ScrollAnimation
                animateIn='fadeIn'
                animateOut='fadeOut'
            >
                <div>
                    <img src={pipelineImg} alt="Exam Master Pipeline" className="w-full" />
                </div>
            </ScrollAnimation >
        </div>


    );
}

// function HowItWorksSection() {
//     return (
//         <div className="flex-col justify-start items-start pb-4">
//             <div className="mx-auto w-full max-w-[800px] aspect-video rounded">
//                 <ReactPlayer 
//                     url="https://www.youtube.com/watch?v=TODO"
//                     width={"100%"}
//                     height={"100%"}
//                     controls
//                 />
//             </div>
//         </div>
//     );
// }

// function TryNowSection() {
//     const bigger=true;
//     return (
//         <div className="flex-col justify-start items-start py-12">
//             <div>
//                 <h2 className="py-2 font-bold text-2xl kanit-regular">
//                     Try AI Dissertation CoPilot Now
//                 </h2>
//                 <span className="text-gray-600 text-sm">
//                     Request a demo to see how our AI Dissertation CoPilot works.
//                 </span>
//             </div>
//             <div className="flex-row justify-content-center py-4 gap-4">
//                 <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
//                     <button className={`clickable ${bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'} leading-snug bg-white hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`}>
//                         Request a demo
//                         <NavArrowRight className="inline-block" />
//                     </button>
//                 </ContactPopup>
//             </div>
//         </div>
//     )
// }

function TryNowSection() {
    const bigger = true;
    return (
        <div className={`relative min-h-screen flex overflow-clip px-5 sm:px-10 bg-primary-gradient rounded-3xl`}>
            <div className="max-w-screen-xl mx-auto flex flex-col justify-center items-center text-center ">
                <ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'>
                    <h1 className="afacad-flux-normal text-5xl">Try PaperPal Now</h1>
                    <span className="text-gray-600 text-sm afacad-flux-normal">
                        Request a demo to see how our PaperPal works.
                    </span>
                </ScrollAnimation>
                <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                    <button className={`mt-5 clickable ${bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'} leading-snug bg-secondary hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`}>
                        Request a demo
                        <NavArrowRight className="inline-block" />
                    </button>
                </ContactPopup>
            </div>
        </div>
    );
}

export default DissertationCopilotProduct;