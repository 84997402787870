import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";
import "./ReportContainer.css";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import useResetScroll from "src/hooks/useResetScroll";
import PPPChart from "src/pages/newswitch/components/PPPChart";
import {NewsLists} from "src/pages/newswitch/components/NewsLists";

const Loading = () => {

    const LoadingTextItem = ({ lines }) => {
        lines = lines || 4;
        return (
            <div className="loading-item">
                <div className="loading-header"></div>
                {
                    Array.from({length: lines - 1}, (_, index) => (
                        <div key={index} className="loading-text-line"></div>
                    ))
                }
                <div className="loading-text-line short"></div>
            </div>
        );
    };

    const LoadingTableItem = ({rows, cols}) => {
        rows = rows || 5;
        cols = cols || 5;
        return (
            <div className="loading-item">
                <div className="loading-header"></div>
                <table className="loading-table">
                    <thead>
                        <tr>
                            {
                                Array.from({length: cols}, (_, index) => (
                                        <td key={"0 " + index} className="loading-table-item-header"></td>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Array.from({length: rows}, (_, index) => (
                                <tr key={index}>
                                    {
                                        Array.from({length: cols}, (_, colIndex) => (
                                                <td key={index + " " + colIndex} className="loading-table-item"></td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="loading-placeholder">
            <LoadingTextItem lines={2} />
            <LoadingTextItem lines={5} />
            <LoadingTableItem rows={4} />
            {
                Array.from({length: 1}, (_, index) => (
                    <>
                        <LoadingTextItem key={index} lines={2} />
                        <LoadingTableItem rows={10} cols={15} />
                    </>
                ))
            }
        </div>
    );
};

function Report({ reportHTML, snippet = false, descriptionFolded = true, clarityMask = "True" }) {

    const [cleanedHTML, setCleanedHTML] = useState(undefined);

    useEffect(() => {
        // Load Plotly script
        const plotlyScript = document.createElement('script');
        plotlyScript.src = 'https://cdn.plot.ly/plotly-latest.min.js';
        plotlyScript.async = true;
        plotlyScript.id = 'plotly-script';
        document.body.appendChild(plotlyScript);

        // Cleanup script on component unmount
        return () => {
            document.body.removeChild(plotlyScript);
        };
    }, []);

    // Clean up the report HTML
    useEffect(() => {
        if (!reportHTML) return;
        const parser = new DOMParser();
        const doc = parser.parseFromString(reportHTML, 'text/html');
        const body = doc.body;

        // Remove the body styling generated in the newswitch report
        const styleTags = body.querySelectorAll('style');
        // Find the style tag that contains the 'title-page' class
        styleTags.forEach((styleTag) => {
            if (styleTag.textContent.includes('.title-page')) {
                const titlePageStyle = styleTag.textContent.replace(/body\s*{[^}]*}/, '');
                styleTag.textContent = titlePageStyle;
                return;
            }
        });

        // Make all news links open in a new tab
        const newsLinks = body.querySelectorAll('.section ul a');
        newsLinks.forEach(link => {
            if (!(link instanceof HTMLAnchorElement)) return;
            if (!link.href.startsWith("#") && !link.href.startsWith(window.location.href)) {
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer');
                // Replace the link text last word with a world emoji
                const text = link.textContent;
                const words = text.split(" ");
                words.pop();
                link.textContent = words.join(" ") + " 🌐";
            }
            // Append the classname of the most closely related parent ul element with 'news-item'
            const newsItem = link.closest('li');
            if (!newsItem) return;
            newsItem.classList.add('news-item');
        });

        // Add the class 'ppp' to all elements with id starting with 'PPP'
        const pppElements = body.querySelectorAll('[id^="PPP"]');
        pppElements.forEach(element => {
            element.classList.add('ppp');
        });

        // Add description classname
        const intro = body.querySelector('.intro');
        if (intro) {
            const descriptionSection = intro.nextElementSibling.querySelector('.section');
            if (descriptionSection) {
                descriptionSection.classList.add('description');
                if (descriptionFolded) {
                    descriptionSection.classList.add('folded');
                }
            }
        }

        setCleanedHTML(body.innerHTML);
    }, [reportHTML, descriptionFolded]);

    
    useEffect(() => {
        if (!cleanedHTML) return;
        // Execute Plotly scripts if present
        const plotlyScriptTags = document.querySelectorAll('script[type="text/javascript"]');
        plotlyScriptTags.forEach((scriptTag) => {
            // Create a new script element and set its content
            const newScriptTag = document.createElement('script');
            newScriptTag.type = 'text/javascript';
            newScriptTag.textContent = scriptTag.textContent;
            document.body.appendChild(newScriptTag);
            document.body.removeChild(newScriptTag);
        });

        // Add custom tooltip popup js logic
        document.querySelectorAll('.plotly-graph-div').forEach((plotElement) => {
            //if (!(plotElement instanceof Plotly)) return;
            plotElement.on('plotly_click', function(event) {
                var point = event.points[0];
                var url = point.customdata;
                if (url) {
                    window.open(url, '_blank', 'noreferrer, noopener');
                }
            });

            // Custom tooltip for legend hover
            const legendItems = plotElement.querySelectorAll('.legendtoggle');
            const tooltip = plotElement.parentNode.parentNode.querySelector('.custom-tooltip');

            legendItems.forEach(function(item) {
                item.addEventListener('mouseover', function(event) {
                    tooltip.style.display = 'block';
                    tooltip.innerHTML = plotElement.getAttribute('data-summary'); // Use data-summary attribute for tooltip content
                });
                item.addEventListener('mousemove', function(event) {
                    tooltip.style.left = (event.pageX + 10) + 'px';
                    tooltip.style.top = (event.pageY + 10) + 'px';
                });
                item.addEventListener('mouseout', function() {
                    tooltip.style.display = 'none';
                });
            });
        });
        
        // Rotate the x axis text 45 degrees for all plotly plots
        const xticks = document.querySelectorAll('.js-plotly-plot .xtick');
        xticks.forEach(xtick => {
            const prevTransform = xtick.firstChild.getAttribute('transform');
            if (prevTransform) {
                // Replace the first value of rotate(num1, num2, num3) by 45
                const newTransform = prevTransform.replace(/rotate\(([^,]+),/, 'rotate(25,');
                xtick.firstChild.setAttribute('transform', newTransform);
                return;
            }
        });


        // Add js to fold/unfold the description section
        const descriptionSection = document.querySelector('.description');
        if (descriptionSection && !descriptionSection.querySelector('.fold-button')) {
            const foldButton = document.createElement('button');
            foldButton.textContent = descriptionFolded ? 'Expand' : 'Hide';
            foldButton.classList.add('fold-button');
            foldButton.onclick = () => {
                descriptionSection.classList.toggle('folded');
                foldButton.textContent = descriptionSection.classList.contains('folded') ? 'Expand' : 'Hide';
            };
            // Add the button to the left of the description section
            const title = descriptionSection.querySelector('h2');
            if (title) {
                // Remove title
                title.remove();
                // Add a div element to hold the title and fold button
                const titleDiv = document.createElement('div');
                titleDiv.classList.add('description-title');
                titleDiv.appendChild(title);
                titleDiv.appendChild(foldButton);
                descriptionSection.insertBefore(titleDiv, descriptionSection.firstChild);
            }
        }

        if (snippet) {
            const universeTable = document.getElementById('client-universe').querySelector('table');
            if (universeTable) {
                const newRow = universeTable.insertRow();
                const newCell = newRow.insertCell();
                newCell.colSpan = 9;
                // make it quite tall and add paywall text
                newCell.innerHTML = '<div style="height: 100px; display: flex; justify-content: center; align-items: center;"><p style="font-size: 1em;"><a href="/dashboard">Sign in</a> or <a href="/dashboard">create a free account</a> to view the full report</p></div>';
            }
            const content = document.querySelector('.content');
            const textElement = document.createElement('div');
            textElement.innerHTML = '<div class="section sign-up-card"><p style="font-size: 1em;"><a href="/dashboard">Sign in</a> or <a href="/dashboard">create a free account</a> to view the full report</p></div>';
            content.appendChild(textElement);
        }

    }, [cleanedHTML, descriptionFolded, snippet]);

    useResetScroll();

    return (
        <div className={"report-container " + (snippet && "report-snippet")}>
            <script src="https://cdn.plot.ly/plotly-latest.min.js"></script>
            {
                cleanedHTML === undefined ? <Loading /> : <div dangerouslySetInnerHTML={{__html: cleanedHTML}} data-clarity-mask={clarityMask} />
            }
        </div>
    );
}

function DatabaseReport({ runID, descriptionFolded = true, clarityMask = "True" }) {
    const callAPI = useNewsWitchApi();

    const [report, setReport] = useState(undefined);
    const [news, setNews] = useState(undefined);
    const [prices, setPrices] = useState(undefined);
    // TODO: This currently gets all the runs and then filters based on the id - a new endpoint should be created to get a single run
    useEffect(() => {
        if (!runID) return;
        callAPI("GET", "/runs")?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const runs = Object.values(body["runs"])
                    // Find the run with the given ID
                    const run = runs.find(run => run["id"] === runID);
                    setReport(run);
                });
            } else {
                console.error(response);
            }
        });
    }, [callAPI, runID]);

    useEffect(() => {
        if (!runID) return;
        callAPI("GET", "/news?" + new URLSearchParams({
            run_id: runID,
        }).toString())?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const news = body["news"];
                    setNews(news);
                    setPrices(body["prices"]);
                });
            } else {
                console.error(response);
                setNews({});
                setPrices({});
            }
        });
    }, [callAPI, runID]);

    useResetScroll();

    const intensities = ["Immediate", "Short-Term", "Medium-Term", "Long-Term"];
    return (
        <div className={"report-container"}>
            {
                report === undefined ? <Loading /> : 
                <div className="content" data-clarity-mask={clarityMask}>
                    <div className="header flex-row justify-between">
                        <p>Date: {report ? new Date(report["start_date"] * 1000).toDateString() : ""}</p>
                        <p>Generated at {report ? new Date(report["start_date"] * 1000).toTimeString().slice(0, 5) : ""}</p>
                    </div>
                    <div className="section intro">
                        <p><b className="text-xs">By viewing and/or using this report you are consenting to the Terms and Conditions of Zanista AI.</b></p>
                        <p>Content generated by GPT-4o</p>
                        <p>At Zanista AI, we provide a wide range of services including automated exam paper grading system (<Link to="/products/goodpoint">GoodPoint</Link>) and financial newsletter (<Link to="/products/newswitch">NewsWitch</Link>). Visit zanista.ai for more information.</p>
                        <p>Zanista AI Data Privacy and Confidentiality: NO input or output data is accessible to other users, OpenAI, or any third-party services. Data is NOT used to train or enhance any models. Verify information before use. Do not distribute without permission.</p>
                    </div>
                    <div id="client-universe" className="section">
                        <h2>Client Universe - Net Sentiment Score</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Ticker</th>
                                    <th>Name</th>
                                    <th>Number of important news</th>
                                    {
                                        intensities.map(intensity => (
                                            <th>Net Sentiment: {intensity}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            { news ?
                                Object.entries(news).sort(([t1, l1], [t2, l2]) => l2.length - l1.length).map(([ticker, newsList]) => {
                                    const importantNews = newsList.length - newsList.filter(item => !item["sentiment"]["direction"] || item["sentiment"]["direction"] === "Irrelevant").length;
                                    const sentimentMap = {};
                                    intensities.forEach(intensity => {
                                        const newsList = news[ticker].filter(item => item["sentiment"]["intensity"] === intensity);
                                        const positiveNews = newsList.filter(item => item["sentiment"]["direction"] === "Positive").length;
                                        const stronglyPositiveNews = newsList.filter(item => item["sentiment"]["direction"] === "Strongly Positive").length;
                                        const negativeNews = newsList.filter(item => item["sentiment"]["direction"] === "Negative").length;
                                        const stronglyNegativeNews = newsList.filter(item => item["sentiment"]["direction"] === "Strongly Negative").length;
                                        sentimentMap[intensity] = {
                                            "net_sentiment":  positiveNews + (3 * stronglyPositiveNews) - negativeNews - (3 *stronglyNegativeNews),
                                            "total_news": newsList.length * 3,
                                        };
                                    });

                                    return (
                                        <tr key={ticker}>
                                            <td><a href={`#${ticker}`}>{ticker}</a></td>
                                            <td>{ticker}</td>
                                            <td>{importantNews}</td>
                                            {
                                                intensities.map(intensity => (
                                                    <td style={{backgroundColor: sentimentMap[intensity]["net_sentiment"] > 0 ? "lightgreen" : sentimentMap[intensity]["total_news"] > 0 ? "lightcoral" : "transparent"}}>
                                                        {sentimentMap[intensity]["net_sentiment"]} / {sentimentMap[intensity]["total_news"]}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                )})
                                :
                                <tr>
                                    <td colSpan={9}>Loading...</td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    { news ?
                        Object.entries(news).sort(([t1, l1], [t2, l2]) => l2.length - l1.length).map(([ticker, newsList]) => {
                            return (
                                <>
                                <div id={ticker} className="section">
                                    <NewsLists ticker={ticker} newsList={newsList} />
                                </div>  
                                <div id={`PPP_${ticker}`} className="section">
                                    <h2>PPP for {ticker}</h2>
                                    <div>
                                        <PPPChart news={newsList} prices={prices} ticker={ticker}/>
                                    </div>
                                </div>
                                </>
                        )})
                        : 
                        <div className="section">
                            <h2>Loading...</h2>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export {Report, DatabaseReport};
