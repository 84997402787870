
import ScrollAnimation from 'react-animate-on-scroll';

function Feature({ icon, title, description, gray = false }) {
    return (
        <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
        >
            <div className={`p-4 flex-col justify-start items-start text-start ${gray ? 'bg-primary rounded-tl-3xl rounded-br-3xl' : ''} transform transition-transform duration-200 hover:scale-105`}>
                <div className=" text-accent">
                    {icon}
                </div>

                <h3 className="pt-2 pb-1 text-base sm:text-lg font-bold kanit-regular  afacad-flux-normal">
                    {title}
                </h3>


                <p className="text-gray-600 text-xs sm:text-base w-[90%]  afacad-flux-normal ">
                    {description}
                </p>
            </div>
        </ScrollAnimation>

    )
}

export default Feature;