import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useCallApi from 'src/api/useCallApi';
import { protectedResources } from "src/auth/AuthConfig";

export const PreCheckoutForm = () => {
    const callApi = useCallApi();

    const [products, setProducts] = useState([]);

    useEffect(() => {
        callApi("GET", `${protectedResources.apiPayments.endpoint}/goodpoint/prices`)
            ?.then(
                (response) => {
                    if (response.status === 200) {
                        response.json().then((body) => {
                            setProducts(body.price_data);
                        });
                    } else {
                        console.error(
                            "Failed to get pricing products: " +
                            response.status +
                            " " +
                            response.statusText
                        );
                    }
                }
            );
    }, [callApi])


    return (
        <div id="pre-checkout" className="container mx-auto flex-col gap-2">
            <h2 className='my-4'>
                Choose a credit package
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 mt-4 gap-12 justify-content-center">
                {
                    products.map((product, index) => {
                        return <CreditProductItem key={index} product={product} />
                    })
                }
            </div>
        </div>
    );
};

const CreditProductItem = ({ product }) => {
    return (
        <div className="flex-col gap-3 p-3 rounded outline outline-1 shadow-md">
            <h3 className="text-start font-bold text-2xl">
                {product.title}
            </h3>
            <div className="min-h-[80px] flex-col gap-2">
                <p className="text-gray-700 text-sm">
                    {product.description}
                </p>
                <p className="text-gray-700 text-sm">
                    An exam with 10 students consumes around 500 credits (dependent on exam length)
                </p>
            </div>
            <Link to="payment" state={{ product_key: product.product_key }} className="mx-auto min-w-[100%] btn btn-primary bg-orange-300 outline outline-1 hover:bg-orange-400 w-fit p-1 px-2 rounded text-center">
                Select
            </Link>
        </div>
    );
}