import "./RubricTab.css";
import { useContext, useEffect, useState } from "react";
import Title from "src/components/content/Title";
import RubricCompleted from "src/pages/goodpoint/exam/tabs/rubric/RubricCompleted";
import { StepState } from "src/api/goodpoint/Const";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import RubricProcessing from "src/pages/goodpoint/exam/tabs/rubric/RubricProcessing";
import RubricReady from "src/pages/goodpoint/exam/tabs/rubric/RubricReady";
import RubricAvailable from "src/pages/goodpoint/exam/tabs/rubric/RubricAvailable";


export default function RubricTab() {
    const { examState } = useContext(ExamContext);
    const [subtitle, setSubtitle] = useState(null);
    const [rubricContent, setRubricContent] = useState(null);

    useEffect(() => {
        switch (examState.rubric) {
            case StepState.AVAILABLE:
                setSubtitle("Upload the rubric to get started");
                setRubricContent(<RubricAvailable />);
                break;
            case StepState.READY:
                setSubtitle("Configure the rubric processing");
                setRubricContent(<RubricReady />);
                break;
            case StepState.PROCESSING:
                setSubtitle("Rubric is being processed...");
                setRubricContent(<RubricProcessing />);
                break;
            case StepState.COMPLETED:
                setSubtitle("Overview of the exam rubric");
                setRubricContent(<RubricCompleted />);
                break;
            default:
                setRubricContent(null);
                break;
        }
    }, [examState.rubric]);

    return (
        <div className="rubric-tab">
            <Title Size="h2" title="Rubric">
                <p>{subtitle}</p>
            </Title>
            {rubricContent}
        </div>
    );
}
