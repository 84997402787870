import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import BackLink from '../nav/BackLink';

export default function LoadingExamSkeleton() {
    return <div className="centered">
        <div className="container">
            <BackLink prevPage="Back to AI Grader" href="/goodpoint" />

            <Stack className="mt-1 px-4">
                <Skeleton variant="text" sx={{ fontSize: '4rem' }} />

                <div className="grid grid-cols-4 gap-4 -mt-4">
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                </div>

                <div className="h-8"></div>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

                <div className="h-1"></div>
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </Stack>
        </div>
    </div>
}