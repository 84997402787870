import { useState } from "react";
import { TrashSolid } from "iconoir-react";
import useDeleteExam from "src/pages/goodpoint/dashboard/useDeleteExam";
import AreYouSureModal from "src/components/ui/AreYouSureModal";
import "./DeleteExamButton.css";


export default function DeleteExamButton({ examItem }) {
    const { deleteExam } = useDeleteExam();
    const popupOpenHook = useState(false);
    const [, setPopupOpen] = popupOpenHook;

    return (
        <div>
            <TrashSolid
                onClick={() => setPopupOpen(true)}
                className="h-full p-4 w-14 text-red-500 transition-all duration-200 hover:p-3 cursor-pointer"
            />
            <AreYouSureModal
                popupOpenHook={popupOpenHook}
                onClosePopup={() => setPopupOpen(false)}
                title={"Delete exam"}
                content={`Are you sure you want to delete ${examItem != null ? examItem.name : ""}?`}
                onConfirm={
                    () => deleteExam(examItem.id, () => setPopupOpen(false))
                }
                danger={true}
                confirmContent={"Delete"}
            />
        </div>
    )
}