import { Collapse, Expand } from "iconoir-react";
import { useContext, useEffect, useState } from "react";
import { useCollapse } from "react-collapsed";
import { useFetchGeneralFeedback } from "src/api/goodpoint/useFetchFeedback";
import IconButton from "src/components/ui/IconButton";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import RichMathEditor from "src/components/ui/RichMathEditor";
import { EditPencil } from "iconoir-react";
import { IconButton as MuiIconButton } from "@mui/material";
import { saveGradingGeneralFeedback } from "src/storage/GradingStorage";
import { AiGenerated } from "src/pages/goodpoint/exam/components/structure/StructureSection";
import { tokenizeText } from "src/components/ui/Helpers";
import RenderMath from "src/components/ui/RenderMath";
import LoadingText from "src/components/ui/LoadingText";
import { useHasRole } from "src/api/useGetRole";



export default function ClassGeneralFeedback() {
    const { examObject, classFeedback, setClassFeedback } =
        useContext(ExamContext);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const fetchGeneralFeedback = useFetchGeneralFeedback(examObject.id);
    const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
        useCollapse({
            collapsedHeight: 120,
            hasDisabledAnimation: true,
        });

    useEffect(() => {
        if (classFeedback) return;
        setIsLoading(true);
        const result = fetchGeneralFeedback();
        if (result) {
            setClassFeedback(tokenizeText(result));
            setIsLoading(false);
        }
    }, [classFeedback, fetchGeneralFeedback, setClassFeedback]);

    function saveContent({ newContent }) {
        setClassFeedback(tokenizeText(newContent));
        saveGradingGeneralFeedback(examObject.id, newContent)

        //todo: add api here
    }

    const readOnly = useHasRole("student");

    return (
        <div className="class-feedback">
            <div className="feedback-header flex-row justify-content-space-between align-center">
                <div className="flex-row section-heading-left gap-small">
                    <b>Class Feedback</b>
                    <AiGenerated />
                </div>
                <div className="section-heading-controls flex flex-row gap-4">
                    {!isEdit && !readOnly && (
                        <MuiIconButton
                            onClick={() => {
                                setIsEdit(true);
                                setExpanded(true);
                            }}
                        >
                            <EditPencil className="cursor-pointer hover:text-blue-700" />
                        </MuiIconButton>
                    )}
                    <IconButton
                        Icon={isExpanded ? Collapse : Expand}
                        text={isExpanded ? "Collapse" : "Expand"}
                        bgColour={isExpanded ? "var(--zanista-orange-mid)" : "var(--zanista-yellow-light)"}
                        paddingSize="2px 5px"
                        borderSize="var(--border-thin)"
                        {...getToggleProps()}
                    />
                </div>
            </div>
            <div {...getCollapseProps()}>
                {isLoading && <LoadingText />}

                {!isLoading && classFeedback && !isEdit &&
                    <RenderMath tokens={classFeedback} />
                }

                {classFeedback && isEdit && (
                    <RichMathEditor
                        initialTokens={classFeedback}
                        saveContent={saveContent}
                        closeEditor={() => setIsEdit(false)}
                    />
                )}
            </div>
        </div>
    );
}
