import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useMemoFetch from "src/api/useMemoFetch";
import {
    getRubricById,
    saveRubric,
} from "src/storage/RubricStorage";

export default function useFetchRubricQuestions(id) {
    const cacheGet = useCallback(() => {
        const rubricData = getRubricById(id);
        if (rubricData) {
            return rubricData; // If data is still valid, use it
        }
        // If data is expired or not available, return undefined to trigger fetch
        return undefined;
    }, [id]);

    const cacheSave = useCallback( (data) => {
        saveRubric(id, { rubric: data.rubric, rubricUrl: data.rubricUrl, } );
    }, [id] );

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Rubric.All(id),
        processResponse: (response) =>
            response.json().then((body) => ({
                rubric: body["data"]["questions"]
            })),
        cacheGet,
        cacheSave,
    });
}


