import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function LoadingTab() {
    return (<>

        <Stack className="">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

            <div className="h-1"></div>
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} />

        </Stack>
    </>
    );
}