import { useCallback, useContext, useState } from "react";
import { InfoCircleSolid, TrashSolid, LineSpace, Xmark, EyeSolid } from "iconoir-react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { EditMode } from "src/pages/goodpoint/exam/ExamContext";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import AreYouSureModal from "./AreYouSureModal";
import { ReactComponent as PdfIcon } from 'src/components/ui/PdfIcon.svg';
import { VisibilityContext } from "src/pages/goodpoint/exam/components/structure/Visibility";
import { Check } from "iconoir-react";
import { useHasRole } from "src/api/useGetRole";
import { adjustMarksBottomUp, makeCards, updateNestedStructure } from "src/components/ui/Helpers";
import SearchBar from "src/components/content/SearchBar";

//don't ask me why isSelectionMode is separate from here, it's because i just refactored somebody else'code
function ShowHideButton({ isSelectionMode, setIsSelectionMode }) {
    const { clear, hiddenCount } = useContext(VisibilityContext);

    const handleClick = () => {
        setIsSelectionMode(mode => !mode);
    };

    return (
        <div className="flex-row align-center gap-mid">

            <Tooltip title="Select visible" placement="top">
                <IconButton onClick={handleClick}>
                    <EyeSolid />
                </IconButton>
            </Tooltip>
            {hiddenCount > 0 && !isSelectionMode && (
                <div
                    className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
                    onClick={() => clear()}
                >
                    <p>{hiddenCount} total hidden (Clear)</p>
                    <Xmark strokeWidth={1.0} />
                </div>
            )}
        </div>
    );
}

export default function EditToolbar({
    setStructure = (_s) => { },
    setCards = (_s) => { },
    saveStructure = (_id, _s) => { },
    enhanced = false,
    isSelectionMode,
    setIsSelectionMode,
    clearSearchBar,
    onChangeSearchText,
    clearSearchTerms,
    // TODO: Don't show edit buttons for students
    listDisplay = [0, 0, 0, 0, 0, 0] // Controls which buttons are shown
}) {
    const { examObject, deleteSelected, setDeleteSelected, editMode, setEditMode } =
        useContext(ExamContext);
    const { setShowPDF } =
        useContext(ExamContext);

    const discardPopupOpenHook = useState(false);
    const [, setDiscardPopupOpen] = discardPopupOpenHook;

    // Don't show editing buttons for students
    // const readOnly = useHasRole("student");
    // if (readOnly) {
    //   listDisplay = [0, 0, 0, 1, 1, 1];
    // }

    function SafeUseHasRole(role) {
        try {
            return useHasRole(role);
        } catch (error) {
            console.error("Error in useHasRole:", error);
            return true;
        }
    }

    // Inside your component
    const readOnly = SafeUseHasRole("student");

    //a student 
    if (readOnly && !listDisplay) {
        listDisplay = [0, 0, 0, 1, 1, 1];
    }

    const deleteQuestion = useCallback(() => {
        let newStructure;
        setStructure((prevStructure) => {
            newStructure = { ...prevStructure };

            for (let keys_string of deleteSelected) {
                let keys = keys_string.split(","); // Convert the comma-separated string into an array
                updateNestedStructure(
                    newStructure,
                    keys,
                    {},
                    "delete"
                ); // Apply deletion
            }
            adjustMarksBottomUp(newStructure);
            return newStructure;
        });
        if (examObject && examObject.id) {
            saveStructure(examObject.id, { rubric: newStructure });
        }
        setCards(makeCards(newStructure));
        setDeleteSelected([]);
        setEditMode(EditMode.DEFAULT);
    }, [deleteSelected, examObject, saveStructure, setCards, setDeleteSelected, setEditMode, setStructure]);

    return (
        <div>
            <div className="flex-row mb-2 justify-between">
                {editMode === EditMode.DEFAULT && !isSelectionMode && (
                    <>
                        <div className="flex-row gap-2">

                            <>
                                {listDisplay[0] === 1 && (
                                    <Tooltip title="Change order" placement="top">
                                        <IconButton onClick={() => setEditMode(EditMode.REORDER)}>
                                            <LineSpace />
                                        </IconButton>
                                    </Tooltip>

                                )}

                                {listDisplay[1] === 1 && (
                                    <Tooltip title="Delete" placement="top">
                                        <IconButton onClick={() => setEditMode(EditMode.DELETE)}>
                                            <TrashSolid />
                                        </IconButton>
                                    </Tooltip>
                                )}

                            </>

                            {listDisplay[2] === 1 && (
                                <ShowHideButton
                                    isSelectionMode={isSelectionMode}
                                    setIsSelectionMode={setIsSelectionMode}
                                />
                            )}
                        </div>

                        {listDisplay[3] === 1 && (
                            <SearchBar
                                clearRef={clearSearchBar}
                                onChangeSearchText={onChangeSearchText}
                                onClear={clearSearchTerms}
                                className="w-3/5 -ml-6"
                            />
                        )}



                        <div className="flex-row gap-2">
                            {listDisplay[4] === 1 && (
                                <Tooltip title="Show PDF" placement="top">
                                    <IconButton onClick={() => setShowPDF((prevShowPDF) => !prevShowPDF)}>
                                        <PdfIcon />
                                    </IconButton>
                                </Tooltip>
                            )}


                            {listDisplay[5] === 1 && (
                                <Tooltip title={!readOnly ? "Click on marks to edit" : ""} placement="right">
                                    <span>
                                        <IconButton disabled={readOnly}>
                                            {
                                                !readOnly ? <InfoCircleSolid className="text-gray-500" /> : <InfoCircleSolid className="text-transparent" />
                                            }
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}


                        </div>



                    </>
                )}

                {editMode === EditMode.REORDER && !isSelectionMode && (
                    <>
                        <div className="flex items-center gap-2">
                            <Tooltip title="Done" placement="top">
                                <span>
                                    <IconButton onClick={() => setEditMode(EditMode.DEFAULT)}>
                                        <Check className="text-black" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <p className="">Drag questions to change order</p>
                        </div>
                    </>
                )}

                {isSelectionMode && (
                    <>
                        <div className="flex items-center gap-2">
                            <Tooltip title="Done" placement="top">
                                <span>
                                    <IconButton onClick={() => { setEditMode(EditMode.DEFAULT); setIsSelectionMode(false) }}>
                                        <Check className="text-black" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <p className="">Toggle visible questions</p>
                        </div>
                    </>

                )}

                {editMode === EditMode.DELETE && !isSelectionMode && (
                    <>
                        <div className="flex items-center gap-2">
                            <Tooltip title="Cancel" placement="top">
                                <span>
                                    <IconButton
                                        onClick={() => {
                                            setEditMode(EditMode.DEFAULT);
                                            setDeleteSelected([]);
                                        }}
                                    >
                                        <Xmark className="text-black" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <p>Select questions to delete</p>
                            {deleteSelected.length > 0 && (
                                <>
                                    <IconButton
                                        onClick={() => {
                                            setDiscardPopupOpen(true);
                                        }}
                                    >
                                        <TrashSolid className="text-red-500" />
                                    </IconButton>
                                </>
                            )}
                        </div>
                        <AreYouSureModal
                            popupOpenHook={discardPopupOpenHook}
                            onClosePopup={() => setDiscardPopupOpen(false)}
                            title={"Delete questions"}
                            content={"Are you sure you want to delete questions?"}
                            onConfirm={deleteQuestion}
                            danger={true}
                            confirmContent={"Delete"}
                            cancelContent={"Cancel"}
                        />
                    </>
                )}
            </div>

        </div >
    );
}
