import { useContext } from "react";
import "./StudentsMenu.css";
import Select from "react-select";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";


export const customStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'var(--zanista-yellow-light)', // Same background as your original example
        borderRadius: '8px', // Border radius as per your original
        boxShadow: '0 0 0 1px var(--zanista-orange-mid)', // Optional: subtle shadow matching the border color
        ':hover': {
            borderColor: 'var(--zanista-yellow-light)', // Yellow border on hover
        },
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected ? 'var(--zanista-orange-mid)' : isFocused ? 'var(--zanista-yellow-light)' : undefined, // Orange for selected and yellow for hover
        color: isSelected ? 'white' : 'black', // White text when selected, black otherwise
        cursor: 'pointer',
        ':active': {
            ...styles[':active'],
            backgroundColor: isSelected ? 'var(--zanista-orange-mid)' : 'var(--zanista-yellow-light)', // Same active state as hover
        },
        transition: 'background-color 0.15s ease-in-out', // Smooth transition for background color
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#ccc',
    }),
    singleValue: (styles) => ({
        ...styles,
        color: 'black', // Style for the selected value (default to black text)
    }),
};


export function StudentsSelect() {
    const { selectedStudent, setSelectedStudent, students } = useContext(StudentsContext);

    const studentOptions = Object.entries(students ?? {}).map(
        ([_studentId, studentData]) => {
            const studentId = studentData["id"];
            const questionsAnswered = Object.keys(studentData["answered_questions"]).length;
            const { student_name: studentName } = studentData["config"];
            return {
                value: _studentId,
                label: (
                    <div className="flex-row w-full justify-between">
                        <p><b>{studentName || studentId}</b></p>
                        <p>{studentName && studentId}</p>
                        <p className="questions-answered justify-self-right">
                            {questionsAnswered} question{questionsAnswered > 1 ? "s" : ""}{" "}
                            answered
                        </p>
                    </div>
                )
            };
        }
    );

    const handleStudentChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedStudent(students[selectedOption.value]);
        }
    };

    return (
        <>
            <Select
                className="basic-single"
                classNamePrefix="select"
                value={studentOptions.find(
                    (option) => option.value === selectedStudent?.id
                )}
                styles={customStyles} // Apply the custom styles
                isClearable={false} // Not clearable
                isSearchable={true} // Searchable
                name="student"
                options={studentOptions}
                onChange={handleStudentChange}
                placeholder="Select a student"
            />
        </>
    );
}


export function StudentButton({ studentData }) {
    const { selectedStudent, setSelectedStudent } = useContext(StudentsContext);
    const isSelected = selectedStudent?.student_id === studentData.student_id;
    const questionsAnswered = Object.keys(studentData["answered_questions"]).length;
    const studentName = studentData["student_name"];
    const studentId = studentData["student_id"];

    return (
        <button
            className={
                `student-button fill-width clickable rounded-small padding-small border-thin outline-thin ` +
                `${isSelected
                    ? "bg-orange-mid outline-black"
                    : "bg-yellow-light outline-transparent"
                }`
            }
            onClick={() => setSelectedStudent(studentData)}
        >
            <div className={`student-button-grid fill-width`}>
                <p className="student-name justify-self-left">
                    <b>{studentName ?? studentName}</b>
                </p>
                {
                    studentName && (
                        <p className="student-id">{studentId}</p>
                    )
                }
                <p className="questions-answered justify-self-right">
                    {questionsAnswered} question{questionsAnswered > 1 ? "s" : ""}{" "}
                    answered
                </p>
            </div>
        </button>
    );
}