import { useContext } from "react";
import Title from "src/components/content/Title";
import Divider from "src/components/layout/Divider";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import ClassStats from "src/pages/goodpoint/exam/tabs/grading/class-performance/ClassStats";
import DownloadOverallFeedback from "src/pages/goodpoint/exam/tabs/grading/class-performance/question-feedback/DownloadOverallFeedback";
import QuestionFeedback from "src/pages/goodpoint/exam/tabs/grading/class-performance/question-feedback/QuestionFeedback";


export default function ClassPerformance({ examResults }) {
    const { examObject } = useContext(ExamContext);
    return (
        <div className="results-content fill-width fill-height flex-col gap-large">
            <ClassStats examResults={examResults} />
            <div className="column grading">
                <Title Size="b" title="Question Feedback" />
                <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
                <p className="font-size-small">
                    Feedback for each question is aggregated from the performance of all the students in the class.
                    To view a single student's feedback, click on the "Student Performance" button above.
                </p>
                <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
                <QuestionFeedback />
                <div style={{ marginTop: "20px" }}>
                    <DownloadOverallFeedback examId={examObject.id} />
                </div>
            </div>
        </div>
    )
}