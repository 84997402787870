import { Link } from "react-router-dom";
import { ContactPopup } from "src/pages/home/components/ContactPopup";

function Pricing() {
    return (
        <div className=''>
            <HeaderSection />
            <PricingCards />
        </div>
    );
}

function HeaderSection() {
    return (
        <div className="flex-col justify-start items-start pb-6 px-2">
            <h2 className="afacad-flux-normal pt-6 font-bold text-3xl kanit-regular">
                Pricing
            </h2>
            <p className="afacad-flux-normal font-bold text-gray-700 text-sm sm:text-lg">
                We provide multiple pricing options for all our products
            </p>
        </div>
    );
}

function PricingCards() {
    return (
        <div className="flex-col justify-start items-start pb-4">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 w-full">
                <PricingCard title="GoodPoint" to="/products/goodpoint" />
                <PricingCard title="NewsWitch" to="/products/newswitch" />
                <PricingCard title="AI Exam Master" to="/products/exam-master" />
                <PricingCard title="PaperPal" to="/products/paperpal" />
            </div>
        </div>
    );
}

function PricingCard({ title, to }) {
    return (
        <div className="flex-col w-full gap-y-4 pb-6 rounded shadow-lg p-4 rounded-tl-3xl rounded-br-3xl bg-white transform transition-transform duration-200 hover:scale-105">
            <div>
                <Link to={to} className="afacad-flux-normal font-bold text-xl sm:text-2xl hover:text-accent hover:text-underline">{title}</Link>
            </div>
            <div>
                <p className="afacad-flux-normal text-xs sm:text-sm text-gray-600">
                    Please&nbsp;
                    <ContactPopup placeholder={"I would like to discuss pricing options regarding " + title + ". Please reach out to me with more information by email."}>
                        <Link className="afacad-flux-normal text-xs sm:text-sm text-gray-600 font-bold hover:underline hover:text-accent">contact us</Link>
                    </ContactPopup>
                    &nbsp;for pricing details
                </p>
            </div>
        </div>
    );
}

export default Pricing;
