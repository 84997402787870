import Divider from "src/components/layout/Divider";

import "./StructureSection.css";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { NavArrowDown, NavArrowUp, EditPencil } from "iconoir-react";
import { useState, useEffect } from "react";
import RichMathEditor from "src/components/ui/RichMathEditor";
import { IconButton, Tooltip } from "@mui/material";
import { tokenizeText } from "src/components/ui/Helpers";
import "//unpkg.com/mathlive";
import RenderMath from "src/components/ui/RenderMath";
import { useHasRole } from "src/api/useGetRole";


export function SectionHeading({ heading, style = {} }) {
    return (
        <p className={`font-italic user-select-none font-size-large`} style={style}>
            {heading}
        </p>
    );
}

export function AiGenerated() {
    return (
        <p
            className={`ai-generated fg-orange-dark font-size-medium user-select-none`}
        >
            (Generated with AI)
        </p>
    );
}

export default function StructureSection({
    heading = "",
    setContent = (_) => {},
    headingControls = null,
    aiGenerated = false,
    content = "",
    hide = false,
    children = undefined,
}) {
    const { isExpanded, toggle, expand, collapse } = useExpandCollapse(true);

    //collapse the question initially
    useEffect(() => {
        if (hide) {
            collapse();
        }
    }, [collapse, hide])

    const [isEdit, setIsEdit] = useState(false);
    const readOnly = useHasRole("student");

    function saveContent({ newContent }) {
        setContent(newContent);
    }

    const tokens = tokenizeText(content);

    return (
        <div className="structure-section flex-col gap-small overflow-auto">
            <div className="flex-row justify-between gap-small">
                <div
                    className="flex-row cursor-pointer fill-width align-center justify-content-space-between"
                    onClick={toggle}
                >
                    <div className="flex-row justify-between gap-mid align-baseline">
                        <SectionHeading heading={heading} />
                        {aiGenerated && <AiGenerated />}
                    </div>
                </div>
                {/* {heading === "Question" && (
          <Tooltip title="Show PDF" placement="top">
            <IconButton
              onClick={() => setShowPDF((prevShowPDF) => !prevShowPDF)}>
              <PdfIcon />
            </IconButton>
          </Tooltip>
        )} */}

                {/* edit pencil */}
                {!isEdit && !readOnly && heading !== "Step-by-Step" && heading !== "Statistics" && !hide && (
                    <Tooltip title="Edit content" placement="top">
                        <IconButton
                            onClick={() => {
                                setIsEdit(true);
                                expand();
                            }}
                        >
                            <EditPencil className="cursor-pointer hover:text-blue-700" />
                        </IconButton>
                    </Tooltip>
                )}

                {isExpanded && (
                    <div className="section-heading-controls">{headingControls}</div>
                )}

                <div className="cursor-pointer align-center" onClick={toggle}>
                    {isExpanded ? <NavArrowDown /> : <NavArrowUp />}
                </div>
            </div>

            {
                isExpanded &&
                !isEdit &&
                (heading === "Step-by-Step" || heading === "Statistics" ? (
                    children
                ) : (
                    <div>
                        <RenderMath tokens={tokens} />
                    </div>
                ))
            }

            {
                isExpanded &&
                isEdit &&
                heading !== "Step-by-Step" &&
                heading !== "Statistics" && (
                    <div>
                        <RichMathEditor
                            // initialContent={content}
                            initialTokens={tokens}
                            saveContent={saveContent}
                            closeEditor={() => setIsEdit(false)}
                        />
                    </div>
                )
            }

            <Divider
                lineColour="rgba(0, 0, 0, 0.3)"
                shadowColour="rgba(0, 0, 0, 0.20)"
            />
        </div >
    );
}
