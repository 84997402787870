import Plot from 'react-plotly.js';

function TimingBar({ timings }) {

    if (timings === undefined || timings.sections === undefined) {
        return <p>No Data</p>
    }

    const sections = Object.keys(timings.sections);
    sections.sort((a, b) => timings.sections[a].index - timings.sections[b].index);
    const values = sections.map(section => timings.sections[section].duration);
    const humanValues = sections.map(section => timings.sections[section].duration_human);
    const maxValue = Math.max(...values);
    // If the values are very small, scale them up to be visible
    const scaledValues = values.map(value => {
        if (value * 4 < maxValue) {
            return maxValue / 7;
        }
        return value;
    })

    // Use Plotly to create a sideways bar chart of the timings
    return (
        <Plot
            data={sections.map((section, index) => ({
                y: [''],
                x: [scaledValues[index]],
                name: section,
                type: 'bar',
                orientation: 'h',
                text: [humanValues[index] === "0m" ? "1m" : humanValues[index]],
                textposition: 'inside',
                textfont: { color: 'white' },
                textangle: 0,
            }))}
            layout={{
            barmode: 'stack',
            xaxis: { showticklabels: false, title: '', zeroline: false },
            yaxis: { title: '' },
            height: 50,
            margin: { l: 10, r: 10, t: 0, b: 0 },
            showlegend: false,
            }}
            config={{
            displayModeBar: false
            }}
      />
    )
}

export default TimingBar;