import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useMemoFetch from "src/api/useMemoFetch";
import { getGradingAllStudentsResults, getGradingGeneralResults, saveGradingAllStudentsResults, saveGradingGeneralResults } from "src/storage/GradingStorage";

export function useFetchGeneralResults(id) {
    const cacheGet = useCallback(() => getGradingGeneralResults(id), [id]);
    const cacheSave = useCallback((results) => saveGradingGeneralResults(id, results), [id]);

    const processResponse = useCallback((response) => {
        return response.json().then(body => body["results"]);
    }, []);

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Grading.Results(id),
        processResponse, cacheGet, cacheSave
    });
}

export function useFetchAllStudentResults(id) {
    const cacheGet = useCallback(() => getGradingAllStudentsResults(id), [id]);
    const cacheSave = useCallback((results) => saveGradingAllStudentsResults(id, results), [id]);

    const processResponse = useCallback((response) => {
        return response.json().then(body => body["students"]);
    }, []);

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Grading.StudentsResults(id),
        processResponse, cacheGet, cacheSave
    });
}