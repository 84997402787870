import HomeHeader from 'src/pages/home/components/HomeHeader';
import HomeFooter from 'src/pages/home/components/HomeFooter';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import CookieConsentBanner from 'src/components/ui/CookieConsent';
import { MsalProvider } from '@azure/msal-react';
import useResetScroll from 'src/hooks/useResetScroll';

function HomeRoot({ msal }) {

    // Add/Remove class to body to change padding on home page only
    useEffect(() => {
        // Add the class when the component mounts
        document.body.classList.add('home-page-active');
        // Remove the class when the component unmounts
        return () => {
            document.body.classList.remove('home-page-active');
        };
    }, []);

    useResetScroll();

    return (
        <MsalProvider instance={msal}>
            <div className='home-page'>
                <HomeHeader />
                <div className='container mx-auto mb-8 min-h-[calc(100vh-225px)]'>
                    <Outlet />
                </div>
                <HomeFooter />
                <CookieConsentBanner />
            </div>
        </MsalProvider>
    );
}

export default HomeRoot;
