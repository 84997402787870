import { useContext, useEffect, useState } from "react";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { StudentsSelect } from "src/pages/goodpoint/exam/components/StudentsMenu";
import useFetchStudentAnswers from "src/api/goodpoint/useFetchStudentAnswers";
import DownloadButton from "src/components/ui/DownloadButton";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import AnswersStructure from "src/pages/goodpoint/exam/tabs/answers/structure/AnswersStructure";
import Divider from "src/components/layout/Divider";
import LoadingTab from "src/components/ui/LoadingTab";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";
import useCallApi from "src/api/useCallApi";
import slugify from "slugify";


function DownloadAllAnswers({ ...props }) {
    const { examObject } = useContext(ExamContext);
    const examId = examObject.id;
    const examName = examObject.name;
    const filename = `${slugify(examName, { lower: true, strict: true, replacement: "_" })}_all_student_answers.zip`;
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.Answers.DownloadAll(examId)}
            filename={filename}
            className="footer-control"
            text={`Download All Student Answers`}
            {...props}
        />
    )
}


function DownloadStudentAnswers({ ...props }) {
    const { examObject } = useContext(ExamContext);
    const { selectedStudent } = useContext(StudentsContext);

    const examId = examObject.id;
    const examName = examObject.name;
    const studentId = selectedStudent?.id;
    const studentName = selectedStudent?.config?.student_name;
    var filename = `${slugify(examName, { lower: true, strict: true, replacement: "_" })}_student_answers`; 
    if (studentName) {
        filename += `_${slugify(studentName, { lower: true, strict: true, replacement: "_" })}.pdf`;
    }

    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.Answers.Download(examId, "enhanced", studentId)}
            filename={filename}
            className="download-student-answers float-right"
            text={`Download Answer`}
            {...props}
        />
    );
}

function StudentAnswers() {
    const { selectedStudent } = useContext(StudentsContext);

    if (!selectedStudent) {
        return <LoadingTab />;
    }

    var answers = Object.values(selectedStudent["answered_questions"]).sort(
        (answer1, answer2) => {
            return answer1.question_number - answer2.question_number;
        }
    );

    return (
        <div className="student-answers resize-y space-y-4">
            <div className="answers-container">
                {answers != null ? (
                    <AnswersStructure
                        selectedStudent={selectedStudent}
                        structureMap={answers}
                    />
                ) : (
                    <LoadingTab />
                )}
            </div>
            {selectedStudent && (
                <DownloadStudentAnswers />
            )}
        </div>
    );
}

export default function AnswersCompleted() {
    const { examObject, footerControls, setFooterControls } = useContext(ExamContext);
    const callApi = useCallApi();
    const [students, setStudents] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [answerPdfUrl, setAnswerPdfUrl] = useState(null);
    
    const fetchStudentAnswers = useFetchStudentAnswers(examObject.id);

    useEffect(() => {
        if (footerControls) return;
        setFooterControls([
            <DownloadAllAnswers examId={examObject.id} />
        ])
    }, [examObject.id, footerControls, setFooterControls]);

    useEffect(() => {
        if (students) return;

        let answers = fetchStudentAnswers();
        if (answers) {
            setSelectedStudent(answers[Object.keys(answers)[0]]);
            setStudents(answers);
        }
    }, [fetchStudentAnswers, setSelectedStudent, setStudents, students]);

    useEffect(() => {
        if (!selectedStudent) return;
        const sourceFile = selectedStudent["config"]["source_file"];
        setAnswerPdfUrl(GoodPointApi.Answers.Original.View(examObject.id, sourceFile));
    }, [callApi, examObject.id, answerPdfUrl, selectedStudent]);

    return (
        <div className="answers-content flex-col gap-mid">
            <StudentsContext.Provider 
                value={{students, setStudents, selectedStudent, setSelectedStudent, answerPdfUrl}}
            >
                <StudentsSelect />
                <Divider
                    lineColour="rgba(0, 0, 0, 0.1)"
                    shadowColour="rgba(0, 0, 0, 0.3)"
                />
                <StudentAnswers />
            </StudentsContext.Provider>
        </div>
    );
}
