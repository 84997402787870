import { useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

export default function useFetchShare() {
    const callApi = useNewsWitchApi();

    const fetchSentiment = useCallback((ticker, startDate, endDate, id = undefined) => {
        const startDateStr = startDate.toISOString().split('T')[0];
        const endDateStr = endDate.toISOString().split('T')[0];

        return callApi("GET", `/sentiment?ticker=${ticker}&start_date=${startDateStr}&end_date=${endDateStr}`)?.then(response => {
            if (response.status === 200) {
                return response.json().then(body => {
                    // @ts-ignore
                    const sentimentResult = body["sentiment"];
                    const r =  Array.from(
                        { length: Math.ceil((endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)) },
                        (_, i) => new Date(startDate.getTime() + i * (24 * 60 * 60 * 1000)).toISOString().split('T')[0]
                    ).map(date => {
                        const s = sentimentResult.find(s => s["date"] === date);
                        if (s) {
                            return {
                                date: date,
                                has_news: true,
                                sentiment: s
                            };
                        } else {
                            return { 
                                date: date,
                                has_news: false,
                                sentiment: {}
                            };
                        }
                    });

                    return id !== undefined ? {
                        sentiment: r,
                        id: id
                    } : r;
                });
            }
            return id !== undefined ? {
                sentiment: [],
                id: id
            } : [];
        });
    }, [callApi]);

    return fetchSentiment;
}
