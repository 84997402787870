import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Mousewheel, Autoplay } from 'swiper/modules';
import answers from 'src/assets/home/goodpoint-screenshots/answers.png';
import questionFeedback from 'src/assets/home/goodpoint-screenshots/question-feedback.png'
import classGraph from 'src/assets/home/goodpoint-screenshots/class-graph.png'
import dashboard from 'src/assets/home/goodpoint-screenshots/dashboard.png'
import editor from 'src/assets/home/goodpoint-screenshots/editor.png'
import info from 'src/assets/home/goodpoint-screenshots/info.png'
import rubric from 'src/assets/home/goodpoint-screenshots/rubric.png'
import studentFeedback from 'src/assets/home/goodpoint-screenshots/student-feedback.png'
import studentGraph from 'src/assets/home/goodpoint-screenshots/student-graph.png'

const imgs = [dashboard, info, rubric, editor, answers, questionFeedback, classGraph, studentFeedback, studentGraph]

export default function Carousel() {
    return (
        <div className="w-full relative">
            <Swiper
                direction="vertical"
                mousewheel={true}
                pagination={{ clickable: true }}
                modules={[Mousewheel, Pagination, Autoplay]}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                className="w-[720px] h-[500px] justify-center items-center flex transform transition-transform duration-200 hover:scale-105"
            >
                {imgs.map((img, index) => (
                    <SwiperSlide key={index}>
                        <div className="bg-white rounded-2xl overflow-hidden">
                            <img
                                src={img}
                                alt={img}
                                className="rounded  h-[400px] object-contain shadow-lg"
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
