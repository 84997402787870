export default function Button({ children, onClick, className = "" }) {
    return (
        <button
            className={`clickable border-thin rounded-small py-1 text-sm float-end ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
