import { useEffect, useState, useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

function TickerSearchBar({ onSelect, maxNameLength = 20 }) {
    const callAPI = useNewsWitchApi();

    const [query, setQuery] = useState("");
    const [tickers, setTickers] = useState();
    const [filteredTickers, setFilteredTickers] = useState();
    const [isFocused, setIsFocused] = useState(false);

    // Fetch the tickers from the API
    useEffect(() => {
        callAPI("GET", "/tickers")?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    setTickers([...new Set(Object.values(body["tickers"]))]);
                    setFilteredTickers(JSON.parse(JSON.stringify(Object.values(body["tickers"]))));
                });
            } else {
                console.error(response);
            }
        });
    }, [callAPI]);

    // Filter tickers based on the query
    const filterTickers = useCallback((value) => {
        if (value === "") {
            setFilteredTickers(tickers);
            return;
        }
        // Filter by ticker
        // First get all tickers/names starting with the query
        const closestMatches = tickers.filter(ticker => ticker["ticker"].toLowerCase().startsWith(value.toLowerCase()));
        const nameClosestMatches = tickers.filter(ticker => ticker["name"].toLowerCase().startsWith(value.toLowerCase()));
        // Then get the tickers/names with the query anywhere in the ticker
        const nameAnywhereMatches = tickers.filter(ticker => ticker["name"].toLowerCase().includes(value.toLowerCase()));
        const anywhereMatches = tickers.filter(ticker => ticker["ticker"].toLowerCase().includes(value.toLowerCase()));
        // Combine the lists and remove duplicates
        const matches = [...new Set([ // Note the order for best recommendations
            ...closestMatches, 
            ...nameClosestMatches, 
            ...nameAnywhereMatches, 
            ...anywhereMatches
        ])];
        setFilteredTickers(matches);
    }, [tickers]);

    // Filter the tickers whenever the query changes
    useEffect(() => {
        if (tickers === undefined) return;
        filterTickers(query);
    }, [query, filterTickers, tickers]);

    // Input handling
    const handleChange = (e) => {
        const value = e.target.value;
        setQuery(value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        // Add a small delay to ensure onClick events can still fire
        setTimeout(() => setIsFocused(false), 100);
    };

    return (
        <div className="relative">
            <input type="text" id="tickerSearchInput" className="w-full min-h-11 p-2 py-0 rounded outline outline-1 focus:rounded-b-none" 
                placeholder="Search for a ticker"
                value={query}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && filteredTickers && filteredTickers.length > 0) {
                        onSelect(filteredTickers[0]);
                        setQuery("");
                        setIsFocused(false);
                        document.activeElement.blur();
                    }
                }}
            />
            <ul id="tickerDropDown" className={`absolute left-0 right-0 bg-white ${(isFocused && (filteredTickers?.length > 0 || tickers === undefined)) ? 'border border-gray-500' : ''} rounded rounded-t-none mt-0 max-h-48 overflow-y-auto z-10`}>
            {
                    isFocused && filteredTickers?.map(ticker => {
                        return (
                            <li key={ticker["ticker"]+ticker["name"]} className="p-2 hover:bg-[color:--orange-mid] cursor-pointer flex-row justify-between items-center"
                                onClick={() => {onSelect(ticker); setQuery(""); setIsFocused(false);}}
                            >
                                <p>
                                    {ticker["ticker"]}
                                </p>
                                {
                                    // Check if the name will fit on one line, else use ellipsis
                                    ticker["name"].length > maxNameLength ?
                                    <p className='text-xs'>
                                        {ticker["name"].substring(0, maxNameLength) + "..."}
                                    </p> :
                                    <p className='text-xs'>
                                        {ticker["name"]}
                                    </p>
                                }
                            </li>
                            
                        );
                    })
                }
                {
                    isFocused && tickers === undefined &&
                        (
                            <li className="p-2 hover:bg-gray-200 cursor-pointer flex-row justify-between items-center">
                                <p>
                                    Loading tickers...
                                </p>
                            </li>
                        )
                }
            </ul>
        </div>
    );
}

export default TickerSearchBar;