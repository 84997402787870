import { Download } from "iconoir-react";

export default function IconButton({
    onClick,
    clickable = true,
    enabled = true,

    Icon = Download,
    iconSize = 20,
    iconLeft = true,
    iconColour = null,

    text = null,
    fontSize = "medium",
    fontWeight = "normal",

    borderColour = null,
    borderSize = "var(--border-mid)",
    roundedSize = "var(--rounded-small)",

    gapSize = "var(--gap-small)",
    justifyContent = "center",
    paddingSize: padding = "var(--padding-mid)",

    fgColour = "black",
    bgColour = "var(--zanista-orange-mid)",

    className = null,
    style = null,
    ...props
}) {
    if (!enabled) {
        [fgColour, bgColour, iconColour, borderColour] = ["gray", "var(--zanista-grey-light)", "gray", "transparent"];
    }

    iconColour = iconColour ?? fgColour;
    borderColour = borderColour ?? fgColour;

    const iconElem = <Icon width={iconSize} height={iconSize} color={iconColour} />;
    const textElem = <p
        className="margin-none"
        style={{ color: fgColour, fontSize, fontWeight }}
    >
        {text}
    </p>;

    return (
        <button
            className={`icon-button text-nowrap display-inline-flex align-center ${className} ${enabled && clickable && "clickable"} `}
            onClick={onClick}
            disabled={!enabled || !clickable}
            style={{
                border: `${borderSize} solid`,
                borderColor: borderColour,
                borderRadius: roundedSize,
                gap: gapSize,
                backgroundColor: bgColour,
                justifyContent: justifyContent,
                padding: padding,
                ...style
            }}
            {...props}
        >
            {iconLeft ? iconElem : textElem}
            {iconLeft ? textElem : iconElem}
        </button>
    )
}