import { Skeleton, Stack } from "@mui/material";
import { ArrowRight, DocMagnifyingGlass, Download, FastArrowDown, FastArrowRight, NavArrowDown, NavArrowUp, TrashSolid, Upload } from "iconoir-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { RotatingSquare, TailSpin } from "react-loader-spinner";
import { StepState } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { useBlobDownload } from "src/api/useBlobDownload";
import useCallApi from "src/api/useCallApi";
import { useFileUpload } from "src/api/useFileUpload";
import Title from "src/components/content/Title";
import Divider from "src/components/layout/Divider";
import NewTabLink from "src/components/nav/NewTabLink";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


function DownloadOriginalButton({ examId, filename }) {
    const { download, isDownloading } = useBlobDownload(
        GoodPointApi.Answers.Original.Download(examId, filename), 
        filename
    );
    return (
        <button
            className="
                clickable font-size-medium bg-yellow-light border-thin flex-row align-center 
                gap-small rounded-xsmall px-2 py-0 text-lg
            "
            onClick={() => download()}
        >
            {
                isDownloading
                    ? <TailSpin strokeWidth={3.0} color="black" width={20} height={20} wrapperClass="px-0" />
                    : <Download width={20}/>
            }
            <p>Download</p>
        </button>
    );
}


function DeleteUploadButton({ filename, numDeleting, setNumDeleting }) {
    const { examObject } = useContext(ExamContext);
    const callApi = useCallApi();
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteUpload = useCallback((f_name) => {
        setIsDeleting(true);
        setNumDeleting((n) => n + 1);
        callApi("DELETE", GoodPointApi.Answers.Upload.Delete(examObject.id, f_name))
            ?.then((response) => {
                if (response.status === 200) {
                    setNumDeleting((n) => n - 1);
                }
            });
    }, [callApi, examObject.id, setNumDeleting]);

    useEffect(() => {
        if (numDeleting === 0) {
            setIsDeleting(false);
        }
    }, [numDeleting]);

    return (
        isDeleting
            ? <TailSpin strokeWidth={3.0} color="red" width={20} height={20} wrapperClass="padding-small" />
            : <TrashSolid
                onClick={() => deleteUpload(filename)}
                className="
                    h-full p-1 w-7 text-red-500 transition-all duration-200 hover:p-0 cursor-pointer
                "
            />
    )
}


function MiniUploadButton({ examId, setNeedReload, setLoadingComponents }) {
    const { isUploading, upload } = useFileUpload(GoodPointApi.Answers.Upload(examId), "answers");
    const { setFile } = useContext(ExamContext);
    const fileRef = useRef(null);

    const onButtonClick = () => {
        if (isUploading) return;
        fileRef.current.click();
    }

    const onChange = (event) => {
        const { files } = event.target;
        const nextFile = files && files[0];

        if (nextFile) {
            setFile(nextFile);
        }
        setLoadingComponents((lc) => { return {...lc, uploadTable: true }});
        upload(fileRef.current.files, () => {setNeedReload(true);});
    }

    return (
        <button 
            className={
                `font-size-medium bg-orange-mid border-thin flex-row align-center ` +
                `gap-small rounded-xsmall p-0 text-lg ` +
                `${isUploading ? "disabled" : "clickable"}`
            }
            onClick={onButtonClick}
        >
            <div className='p-2'>
                {
                    isUploading 
                        ? <RotatingSquare color="black" height={30} width={30} />
                        : <Upload color="black" height={30} width={30} />
                }
            </div>
            <input type='file' ref={fileRef} style={{ display: 'none' }} onChange={onChange} multiple={true} />
        </button>
    )
}


function UploadTable({ setLoadingComponents }) {
    const { examObject, examState, updateExamState } = useContext(ExamContext);

    const callApi = useCallApi();
    const [uploadFiles, setUploadFiles] = useState(null);
    const [needReload, setNeedReload] = useState(true);

    const [numDeleting, setNumDeleting] = useState(0);

    useEffect(() => {
        if (needReload || numDeleting > 0 || !uploadFiles) {
            setLoadingComponents((lc) => { return {...lc, "uploadTable": true} });
        } else {
            setLoadingComponents((lc) => { return {...lc, "uploadTable": false} });
        }
    }, [needReload, numDeleting, setLoadingComponents, uploadFiles]);

    useEffect(() => {
        if (!needReload || numDeleting > 0) return;
        setUploadFiles(null);
        callApi("GET", GoodPointApi.Answers.Upload(examObject.id))
            ?.then(
                (response) => {
                    if (response.status === 200) {
                        response.json().then((body) => {
                            const data = body["data"];
                            const uploadedFiles = data["files"];
                            setUploadFiles(uploadedFiles);
                            const newState = data["state"];
                            if (newState !== examState.answers) {
                                updateExamState({ answers: newState });
                            }
                        })
                        setNeedReload(false);
                    }
                }
            )
    }, [callApi, examObject.id, examState.answers, needReload, numDeleting, updateExamState]);

    useEffect(() => {
        if (numDeleting > 0 && !needReload) {
            setNeedReload(true);
        }
    }, [needReload, numDeleting]);

    return (
        uploadFiles
            ? <table className="table-auto border-separate border-spacing-2 w-full">
                <tbody>
                    <tr>
                        <td colSpan={5}><Title Size="h2" title="Upload" margin="0"/></td>
                    </tr>
                    {
                        uploadFiles.map((f_name, i) =>
                            <tr key={i}>
                                <td>{i+1}.</td>
                                <td className="w-full"><p>{f_name}</p></td>
                                <td>
                                    <NewTabLink
                                        className="
                                            clickable font-size-medium bg-yellow-light border-thin flex-row align-center 
                                            gap-small rounded-xsmall px-2 py-0 text-lg
                                        "
                                        to={GoodPointApi.Answers.Original.View(examObject.id, f_name)}
                                    >
                                        <DocMagnifyingGlass width={20} /><p>View</p>
                                    </NewTabLink>
                                </td>
                                <td>
                                    <DownloadOriginalButton examId={examObject.id} filename={f_name}/>
                                </td>
                                <td className="px-2">
                                    <DeleteUploadButton
                                        filename={f_name}
                                        numDeleting={numDeleting}
                                        setNumDeleting={setNumDeleting}
                                    />
                                </td>
                            </tr>
                        )
                    }
                    <tr>
                        <td colSpan={5} className="align-right text-right">
                            <div className="flex-row gap-mid align-center justify-end mt-2">
                                <p>Upload more (.pdf)</p>
                                <MiniUploadButton 
                                    examId={examObject.id} 
                                    setNeedReload={setNeedReload}
                                    setLoadingComponents={setLoadingComponents}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            : <TableSkeleton/>
    )
}


function ConfigTable({ setLoadingComponents }) {
    const [configOpen, setConfigOpen] = useState(false);
    const { examObject, examState } = useContext(ExamContext);

    const callApi = useCallApi();
    const [config, setConfig] = useState(null);
    const [needReload, setNeedReload] = useState(true);
    const [advancedOpened, setAdvancedOpened] = useState(false);

    useEffect(() => {
        if (!needReload) return;
        setConfig(null);
        callApi("GET", GoodPointApi.Answers.Config(examObject.id))
            ?.then(
                (response) => {
                    if (response.status === 200) {
                        response.json().then((body) => {
                            const data = body["data"];
                            const config = data["config"];
                            setConfig(config);
                        })
                        setNeedReload(false);
                        setLoadingComponents((lc) => { return {...lc, configTable: false }});
                    }
                }
            )
    }, [callApi, examObject.id, needReload, setLoadingComponents]);

    const updateConfig = useCallback((updates) => {
        setConfig((c) => {
            const newConfig = {...c, ...updates};
            callApi("POST", GoodPointApi.Answers.Config(examObject.id), { body: {"config": newConfig} });
            return newConfig;
        });
    }, [callApi, examObject.id]);

    return (
        !(config && examState.answers >= StepState.READY)
            ? <TableSkeleton />
            : (
            <table className="table-auto border-separate border-spacing-2 w-full">
            <tbody>
                <tr className="font-normal p-0 m-0">
                    <td colSpan={2} className="w-full cursor-pointer p-0 m-0" onClick={() => setConfigOpen((c) => !c)}>
                        <div className="flex-row w-full align-center justify-between m-0 p-0 gap-0">
                            <h2 className="m-0 p-0">Options</h2>
                            {
                                configOpen
                                    ? <NavArrowDown/>
                                    : <NavArrowUp/>
                            }
                        </div>
                    </td>
                </tr>
                {
                    !configOpen 
                        ? null
                        : <>
                        <tr>
                            <td className="w-1/3"><p>Submission Type</p></td>
                            <td><select
                                className={
                                    `w-full px-[10px] py-[14px] rounded-[4px] border border-zanista-grey-mid ` +
                                    `transition hover:border-black`
                                }
                                defaultValue={config["submission_type"]}
                                onBlur={(e) => updateConfig({"submission_type": e.target.value})}
                            >
                                <option>Handwritten</option>
                                <option>Typed</option>
                            </select></td>
                        </tr>
                        <tr>
                            <td colSpan={5}>
                                <div 
                                    className=
                                        "mt-2 mb-2 flex-row gap-small text-zanistaOrange align-center cursor-pointer"
                                    onClick={() => setAdvancedOpened((a) => !a)}
                                >
                                    {
                                        advancedOpened
                                            ? <FastArrowDown color="var(--zanista-orange-dark)" width={"1em"} />
                                            : <FastArrowRight color="var(--zanista-orange-dark)" width={"1em"} />
                                    }
                                    <b className="user-select-none">Advanced</b>
                                    <div className="w-full ml-[5px] right-0 border-t border-zanistaOrangeMid"></div>
                                </div>
                            </td>
                        </tr>
                        {
                            advancedOpened
                                ? <>
                                    <tr>
                                        <td>OCR Method</td>
                                        <td><select
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                        >
                                            <option value="azure-vision">Azure Vision</option>
                                        </select></td>
                                    </tr>
                                    <tr>
                                        <td>GPT Model</td>
                                        <td><select
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                            defaultValue={config["gpt_model"]}
                                            onBlur={(e) => updateConfig({"gpt_model": e.target.value})}
                                        >
                                            <option value="o1-preview">OpenAI: o1 (Preview)</option>
                                            <option value="o1-mini">OpenAI: o1 Mini</option>
                                            <option value="gpt-4o">OpenAI: GPT-4o</option>
                                            <option value="gpt-4-turbo">OpenAI: GPT-4 Turbo</option>
                                            <option value="gpt-4-turbo-preview-azure">
                                                Azure: GPT-4 Turbo (Preview)
                                            </option>
                                            <option value="gpt-4-turbo-preview">OpenAI: GPT-4 Turbo (Preview)</option>
                                            <option value="gpt-4-1106-preview">OpenAI: GPT-4 1106 (Preview)</option>
                                            <option value="gpt-4-32k">OpenAI: GPT-4 32k</option>
                                            <option value="gpt-4-8k">OpenAI: GPT-4 8k</option>
                                        </select></td>
                                    </tr>
                                    <tr>
                                        <td>Vision Model</td>
                                        <td><select
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` +
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                            defaultValue={config["vision_model"]}
                                            onBlur={(e) => updateConfig({"vision_model": e.target.value})}
                                        >
                                            <option value="gpt-4o">OpenAI: GPT-4o</option>
                                            <option value="gpt-4-turbo-preview-azure-vision">
                                                Azure: GPT-4 Turbo Vision (Preview)
                                            </option>
                                            <option value="gpt-4-vision-preview">OpenAI: GPT-4 Vision (Preview)</option>
                                        </select></td>
                                    </tr>
                                </>
                                : null
                        }
                    </>
                }
            </tbody>
            </table>
        )
    )
}


function TableSkeleton() {
    return (
        <Stack className="mt-1 px-4 w-full">
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />

            <div className="grid grid-cols-3 gap-4 -mt-4">
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </div>
            <div className="grid grid-cols-3 gap-4 -mt-4">
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </div>
        </Stack>
    )
}


function ProcessButton() {
    const { examObject, updateExamState } = useContext(ExamContext);
    const callApi = useCallApi();
    const [starting, setStarting] = useState(false);

    const onButtonClick = useCallback(() => {
        setStarting(true);
        callApi("POST", GoodPointApi.Answers.Process(examObject.id))
            ?.then((response) => {
                if (response.status === 200) {
                    response.json().then((body) => {
                        const data = body["data"];
                        const newState = data["state"];
                        updateExamState({ answers: newState.answers });
                    })
                }
            });
    }, [callApi, examObject.id, updateExamState]);

    return (
        <button 
            className={
                `flex gap-mid rounded-small border-mid ${starting ? "bg-zanista-red" : "bg-zanista-orange"} ` +
                ` transition-all duration-300 ease-out ` +
                ` ${starting ? "disabled" : "hover:bg-zanista-red clickable"}`
            }
            onClick={onButtonClick}
            disabled={starting}
        >
            <b>Begin Processing</b>
            {
                starting
                    ? <TailSpin strokeWidth={3.0} color="black" width={20} height={20} wrapperClass="padding-small" />
                    : <ArrowRight width={20} strokeWidth={2.0}/>
            }
        </button>
    )
}


export default function AnswersReady() {
    const [loadingComponents, setLoadingComponents] = useState({
        uploadTable: true,
        configTable: true
    });
    return (
        <div className="centered">
            <div className="margin-x-large gap-mid flex-col align-center w-1/2">
                <UploadTable setLoadingComponents={setLoadingComponents} />
                <Divider/>
                <ConfigTable setLoadingComponents={setLoadingComponents} />
                <Divider/>
                <div className="flex-row mt-4 align-center w-full justify-between px-2">
                    {
                        Object.values(loadingComponents).every(v => v === false)
                            ? <>
                                <p>Credits cost: <b>[PLACEHOLDER]</b></p>
                                <ProcessButton />
                            </>
                            : <Stack className="mt-1 px-4 w-full">
                                <div className="grid grid-cols-2 gap-4 -mt-4">
                                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                                </div>
                            </Stack>
                    }
                </div>
            </div>
        </div>
    );
}