import { useContext } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import FileUploader from "src/components/ui/FileUploader";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


export default function AnswersAvailable() {
    const { examObject, updateExamState } = useContext(ExamContext);
    return (
        <FileUploader
            title="Upload Student Answers"
            text=".pdf"
            uploadEndpoint={GoodPointApi.Answers.Upload(examObject.id)}
            onUpload={(data) => {
                const newState = data["state"];
                updateExamState({ answers: newState });
            }}
            formDataKey="answers"
        />
    )
}