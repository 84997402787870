import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCallApi from 'src/api/useCallApi';
import { protectedResources } from "src/auth/AuthConfig";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// Your test public API key starts with pk_test

// TODO: this should be chnged based on dev env variable
const stripePublicKey = "pk_live_51PEZMQLGT2o2v8B8wN9vLZdgXlxAAU25mcwtaOLgn7Wyvf4ewqBhFBKFovfox9IR4Hdn7erUZTAYjdiT1VoaFOXg00f4rRa2pF" // "pk_test_51PEZMQLGT2o2v8B8kDSWAjlFaQbYpfxZmL466l3oddq0YeFscjfHmTqc9VK7kHF1vmj4e9NoAPdwvlJdj19ornOy00rOU6JCc1"
const stripePromise = loadStripe(stripePublicKey);

export const CheckoutForm = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    if (state === null || state.product_key === undefined) {
        navigate("/checkout");
    }
    const product_key = state?.product_key;

    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    });

    const [clientSecret, setClientSecret] = useState(null);
    useEffect(() => {
        if (!product_key) return;
        callApi("POST", `${protectedResources.apiPayments.endpoint}/create-checkout-session`, { body: { product_key: product_key } })?.then(
            (response) => {
                if (response.status === 200) {
                    response.json().then((body) => {
                        setClientSecret(body.clientSecret);
                    });
                } else {
                    console.error(
                        "Failed to create checkout session: " +
                        response.status +
                        " " +
                        response.statusText
                    );
                    if (response.status === 400) {
                        // Redirect back to pre-checkout page (invlid amount)
                        navigate("/checkout", { state: { error: "Invalid amount" } });
                    } else {
                        // Redirect back to pre-checkout page (unknown error)
                        navigate("/checkout", { state: { error: "An error occurred" } });
                    }
                }
            }
        );
    }, [callApi, product_key, navigate]);

    const options = { clientSecret: clientSecret };

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
};