import React, { useState } from "react"
import { Grid } from "react-loader-spinner";


const EmbeddedPdf = React.memo(({ pdfUrl, ...props }) => {
    const [isLoading, setIsLoading] = useState(true);
    return <>
        { 
            isLoading &&
                <div className="absolute w-full h-1/2 flex-row items-center justify-center inset-0 z-10">
                    <Grid width="200px" height="200px" radius="8" color="var(--zanista-orange)"/>
                </div>
        }
        <iframe
            src={`${pdfUrl}#zoom=FitW`}
            title={pdfUrl}
            onLoad={() => setIsLoading(false)}
            {...props}
        ></iframe>
    </>
});

export default EmbeddedPdf;
