import { useMemo, useState, useContext, useEffect, useCallback } from "react";
import SolutionSteps from "src/pages/goodpoint/exam/tabs/rubric/structure/SolutionSteps";
import { saveRubric } from "src/storage/RubricStorage";
import { Tooltip } from "@mui/material";
import {updateNestedStructure, adjustMarksBottomUp} from "src/components/ui/Helpers";
import { useHasRole } from "src/api/useGetRole";
import { ExamStructureContext } from "src/pages/goodpoint/exam/components/structure/ExamStructureContext";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import StructureSection from "src/pages/goodpoint/exam/components/structure/StructureSection";
import { StructureItem } from "src/pages/goodpoint/exam/components/structure/StructureItem";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";


function RubricItemHeader({ itemData, nestingLevel }) {
    const { examObject } = useContext(ExamContext);
    const { setRubric } = useContext(RubricContext);

    const numParts = useMemo(() => {
        const subQuestions = itemData.sub_questions;
        return (subQuestions ?? []).length;
    }, [itemData.sub_questions]);

    const handleMarkChange = useCallback((event) => {
        const newMark = parseFloat(event.target.value) || 0;
        const newValues = {
            mark: newMark,
        };
        const keys = itemData.hierarchy;  // Assuming itemData.keys is an array like ['Question 1', 'a', 'i']

        setRubric((r) => {
            updateNestedStructure(r, keys, newValues, "mark");  // top down
            adjustMarksBottomUp(r);  // bottom up
            saveRubric(examObject.id, { rubric: r });
            return r;
        });
    }, [examObject.id, itemData.hierarchy, setRubric]);

    const readOnly = useHasRole("student");

    return (
        <div className="w-full">
            <div className="flex-row flex justify-between">
                <p className="">
                    <b>
                        {nestingLevel > 0 ? "Part " : ""}
                        {itemData.name}
                    </b>
                </p>

                {numParts > 0 && (
                    <p className="">
                        {numParts} Parts
                    </p>
                )}

                <Tooltip
                    title={
                        readOnly ? "" :
                            itemData.hierarchy.length === 0
                                ? "Adjusts all related subquestion marks."
                                : (itemData.sub_items || []).length === 0
                                    ? "Affects parent question's total marks."
                                    : "Impacts both subquestions and parent marks."
                    }
                    placement="right"
                >
                    <div className="justify-self-right">
                        <input
                            type="number"
                            key={`${itemData.name}_${itemData.mark}`} //forces rerender
                            onClick={(event) => event.stopPropagation()} //stops the item from expanding on click
                            onBlur={handleMarkChange}
                            defaultValue={itemData.marks}
                            className={
                                `w-12 bg-transparent focus:bg-white focus:outline-accent text-right ` + 
                                `${readOnly 
                                    ? "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none " + 
                                      "[&::-webkit-inner-spin-button]:appearance-none" 
                                    : ""}`
                                }
                            disabled={readOnly}
                        /> {/* Editing marks is disabled for students */}
                        Marks
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}

export function RubricItemContent({ itemData }) {
    const { itemCommonProps } = useContext(ExamStructureContext);
    const enhanced = itemCommonProps.enhanced;

    const [questionText, setQuestionText] = useState(itemData["question_content"]);
    const [description, setDescription] = useState(itemData["description"]);
    const [result, setResult] = useState(itemData["result"]);
    const [solutionText, setSolutionText] = useState(itemData["solution_content"]);
    const { examObject } = useContext(ExamContext);
    const { rubric, setRubric } = useContext(RubricContext);

    // for changing content
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!rubric || !itemData) return;

        const hierarchy = itemData["hierarchy"];  // Assuming question.hierarchy is an array like ['Question 1', 'a']
        const newValues = {
            question_text: questionText,
            description: description,
            result: result,
            solution_text: solutionText,
        };

        setRubric((r) => {
            updateNestedStructure(r, hierarchy, newValues, "content");
            saveRubric(examObject.id, { rubric: r });
            return r;
        });
    }, [questionText, description, result, solutionText]);
    /* eslint-enable react-hooks/exhaustive-deps */


    return (
        <div className="rubric-item-content">
            {questionText && (
                <StructureSection
                    heading={"Question Content"}
                    content={questionText}
                    setContent={setQuestionText}
                />
            )}
            {enhanced && description && (
                <StructureSection
                    heading={"Description"}
                    content={description}
                    setContent={setDescription}
                    aiGenerated
                />
            )}
            {enhanced && result && (
                <StructureSection
                    heading={"Result"}
                    content={result}
                    setContent={setResult}
                    aiGenerated
                />
            )}
            {solutionText && (
                <StructureSection
                    heading={"Solution Content"}
                    content={solutionText}
                    setContent={setSolutionText}
                />
            )}
            {enhanced && itemData.steps && (
                <SolutionSteps steps={itemData.steps} hierarchy={itemData.hierarchy} />
            )}
        </div>
    );
}

export function RubricItem(props) {
    const {
        editMode,
        setEditMode,
    } = useContext(ExamContext);

    return (
        <StructureItem
            HeaderComponent={RubricItemHeader}
            ContentComponent={RubricItemContent}
            editMode={editMode}
            setEditMode={setEditMode}
            {...props}
        />
    );
}

export function EnhancedRubricItem({ itemData, nestingLevel = 0 }) {
    return (
        <RubricItem
            itemData={itemData}
            enhanced={true}
            nestingLevel={nestingLevel}
        />
    );
}
