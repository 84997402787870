import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { clarity } from "react-microsoft-clarity";

export default function CookieConsentBanner() {

    if (getCookieConsentValue() === "true") {
        if (clarity.hasStarted()) {
            clarity.consent();
        }
    }

    return (
        <CookieConsent
            location="bottom"
            buttonText="Consent"
            style={{ background: "#ff89229c", color: "#000" }}
            buttonStyle={{ color: "#000", fontSize: "13px", borderRadius: "5px", backgroundColor: "rgb(253, 186, 116)", outline: "solid 1px #000" }}
            expires={150}
            onAccept={() => {
                console.log("User accepted cookies");
                if (clarity.hasStarted()) {
                    clarity.consent();
                }
            }}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    )
}
