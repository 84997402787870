import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useMemoFetch from "src/api/useMemoFetch";
import { getAllStudentAnswers, getStudentAnswerById, saveStudentAnswerById, setAllStudentAnswers } from "src/storage/StudentAnswerStorage";


export default function useFetchStudentAnswers(id, student_id = null) {
    const cacheGet = useCallback(
        () => {
            if (student_id) {
                return getStudentAnswerById(id, student_id);
            } else {
                return getAllStudentAnswers(id);
            }
        },
        [id, student_id]
    );

    const cacheSave = useCallback(
        (answers) => {
            if (student_id) {
                saveStudentAnswerById(id, student_id, answers);
            } else {
                setAllStudentAnswers(id, answers);
            }
        },
        [id, student_id]
    );

    const processResponse = useCallback(
        (response) => {
            return response.json().then((body) => {
                const data = body["data"];
                if (student_id) {
                    // TODO: implement
                    return data;
                } else {
                    return data["student_answers"];
                }
            });
        },
        [student_id]
    )

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Answers.All(id, student_id),
        processResponse, cacheGet, cacheSave
    })
}