import { Skeleton, Stack } from "@mui/material";
import { ArrowRight, FastArrowDown, FastArrowRight, NavArrowDown, NavArrowUp } from "iconoir-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { StepState } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import Divider from "src/components/layout/Divider";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import GradeBoundaries from "src/pages/goodpoint/exam/tabs/grading/GradeBoundaries";


const _generosity_map = {
    0: "Strictly adhere to the rubric",
    1: "Partial marks awarded for minor variations",
    2: "Partial marks awarded for closely aligning grading",
    3: "Lenient, rewarding the presence of key concepts",
    4: "Generous, allowing general alignment with rubric",
    5: "Highly generous, allowing large deviations",
}


function ConfigTable({ setLoadingComponents }) {
    const [configOpen, setConfigOpen] = useState(true);
    const { examObject, examState } = useContext(ExamContext);

    const callApi = useCallApi();
    const [config, setConfig] = useState(null);
    const [needReload, setNeedReload] = useState(true);
    const [advancedOpened, setAdvancedOpened] = useState(false);

    useEffect(() => {
        if (!needReload) return;
        setConfig(null);
        callApi("GET", GoodPointApi.Grading.Config(examObject.id))
            ?.then(
                (response) => {
                    if (response.status === 200) {
                        response.json().then((body) => {
                            const data = body["data"];
                            const config = data["config"];
                            setConfig(config);
                        })
                        setNeedReload(false);
                        setLoadingComponents((lc) => { return {...lc, configTable: false }});
                    }
                }
            )
    }, [callApi, examObject.id, needReload, setLoadingComponents]);

    const updateConfig = useCallback((updates) => {
        setConfig((c) => {
            const newConfig = {...c, ...updates};
            callApi("POST", GoodPointApi.Grading.Config(examObject.id), { body: {"config": newConfig} });
            return newConfig;
        });
    }, [callApi, examObject.id]);

    function handleTokenLengthInput(e) {
        const inputvalue = e.target.value;
        if (/^\d*$/.test(inputvalue)) {
            updateConfig({"feedback_token_length": inputvalue});
            e.target.value = inputvalue;
        } else {
            e.target.value = config["feedback_token_length"];
        }
    }

    return (
        !(config && examState.grading >= StepState.READY)
            ? <TableSkeleton />
            : (
            <table className="table-auto border-separate border-spacing-2 w-full">
            <tbody>
                <tr className="font-normal p-0 m-0">
                    <td colSpan={2} className="w-full cursor-pointer p-0 m-0" onClick={() => setConfigOpen((c) => !c)}>
                        <div className="flex-row w-full align-center justify-between m-0 p-0 gap-0">
                            <h2 className="m-0 p-0">Options</h2>
                            {
                                configOpen
                                    ? <NavArrowDown/>
                                    : <NavArrowUp/>
                            }
                        </div>
                    </td>
                </tr>
                {
                    !configOpen 
                        ? null
                        : <>
                        <tr>
                            <td className="whitespace-nowrap">Marking Generosity</td>
                            <td><select 
                                className={
                                    `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                    `border-zanista-grey-mid transition hover:border-black`
                                }
                                defaultValue={config["generosity"]}
                                onBlur={(e) => updateConfig({"generosity": e.target.value})}
                            >
                                {
                                    Object
                                        .entries(_generosity_map)
                                        .map(([v, text], i) => <option key={i} value={v}>{v}: {text}</option>)
                                }
                            </select></td>
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap">
                                Grade Boundaries
                            </td>
                            <td style={{"maxWidth": "100px"}}>
                                <GradeBoundaries
                                    gradeBoundaries={config["grade_boundaries"]}
                                    setGradeBoundaries={(gb) => updateConfig({ "grade_boundaries": gb })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5}>
                                <div 
                                    className=
                                        "mt-2 mb-2 flex-row gap-small text-zanistaOrange align-center cursor-pointer"
                                    onClick={() => setAdvancedOpened((a) => !a)}
                                >
                                    {
                                        advancedOpened
                                            ? <FastArrowDown color="var(--zanista-orange-dark)" width={"1em"} />
                                            : <FastArrowRight color="var(--zanista-orange-dark)" width={"1em"} />
                                    }
                                    <b className="user-select-none">Advanced</b>
                                    <div className="w-full ml-[5px] right-0 border-t border-zanistaOrangeMid"></div>
                                </div>
                            </td>
                        </tr>
                        {
                            advancedOpened
                                ? <>
                                    <tr>
                                        <td>GPT Model</td>
                                        <td><select
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                            defaultValue={config["gpt_model"]}
                                            onBlur={(e) => updateConfig({"gpt_model": e.target.value})}
                                        >
                                            <option value="o1-preview">OpenAI: o1 (Preview)</option>
                                            <option value="o1-mini">OpenAI: o1 Mini</option>
                                            <option value="gpt-4o">OpenAI: GPT-4o</option>
                                            <option value="gpt-4-turbo">OpenAI: GPT-4 Turbo</option>
                                            <option value="gpt-4-turbo-preview-azure">
                                                Azure: GPT-4 Turbo (Preview)
                                            </option>
                                            <option value="gpt-4-turbo-preview">OpenAI: GPT-4 Turbo (Preview)</option>
                                            <option value="gpt-4-1106-preview">OpenAI: GPT-4 1106 (Preview)</option>
                                            <option value="gpt-4-32k">OpenAI: GPT-4 32k</option>
                                            <option value="gpt-4-8k">OpenAI: GPT-4 8k</option>
                                        </select></td>
                                    </tr>
                                    <tr>
                                        <td>Feedback token length</td>
                                        <td><input
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                            type="number"
                                            onInput={handleTokenLengthInput}
                                            onBlur={(e) => {
                                                const v = Math.max(1, parseInt(e.target.value || "1"))
                                                e.target.value = `${v}`;
                                                updateConfig({"feedback_token_length": v});
                                            }}
                                            value={config["feedback_token_length"]}
                                        ></input></td>
                                    </tr>
                                </>
                                : null
                        }
                    </>
                }
            </tbody>
            </table>
        )
    )
}


function TableSkeleton() {
    return (
        <Stack className="mt-1 px-4 w-full">
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />

            <div className="grid grid-cols-3 gap-4 -mt-4">
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </div>
            <div className="grid grid-cols-3 gap-4 -mt-4">
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </div>
        </Stack>
    )
}


function ProcessButton() {
    const { examObject, updateExamState } = useContext(ExamContext);
    const callApi = useCallApi();
    const [starting, setStarting] = useState(false);

    const onButtonClick = useCallback(() => {
        setStarting(true);
        callApi("POST", GoodPointApi.Grading.Process(examObject.id))
            ?.then((response) => {
                if (response.status === 200) {
                    response.json().then((body) => {
                        const data = body["data"];
                        const newState = data["state"];
                        updateExamState({ grading: newState.grading });
                    })
                }
            });
    }, [callApi, examObject.id, updateExamState]);

    return (
        <button 
            className={
                `flex gap-mid rounded-small border-mid ${starting ? "bg-zanista-red" : "bg-zanista-orange"} ` +
                ` transition-all duration-300 ease-out ` +
                ` ${starting ? "disabled" : "hover:bg-zanista-red clickable"}`
            }
            onClick={onButtonClick}
            disabled={starting}
        >
            <b>Begin Grading</b>
            {
                starting
                    ? <TailSpin strokeWidth={3.0} color="black" width={20} height={20} wrapperClass="padding-small" />
                    : <ArrowRight width={20} strokeWidth={2.0}/>
            }
        </button>
    )
}


export default function GradingReady() {
    const [loadingComponents, setLoadingComponents] = useState({
        configTable: true
    });
    return (
        <div className="centered">
            <div className="margin-x-large gap-mid flex-col align-center w-3/4">
                <ConfigTable setLoadingComponents={setLoadingComponents} />
                <Divider/>
                <div className="flex-row mt-4 align-center w-full justify-between px-2">
                    {
                        Object.values(loadingComponents).every(v => v === false)
                            ? <>
                                <p>Credits cost: <b>[PLACEHOLDER]</b></p>
                                <ProcessButton />
                            </>
                            : <Stack className="mt-1 px-4 w-full">
                                <div className="grid grid-cols-2 gap-4 -mt-4">
                                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                                </div>
                            </Stack>
                    }
                </div>
            </div>
        </div>
    );
}