import { useCallback } from "react";
import useAccessToken from "src/auth/useAccessToken";
import useOptions from "../auth/useOptions";
import { protectedResources } from "src/auth/AuthConfig";

export default function useCallApi(config = null) {
    const msalRequest = config?.msalRequest ?? { scopes: protectedResources.apiGoodPoint.scopes.read }
    const base_endpoint = config?.base_endpoint ? config.base_endpoint : "";

    const accessToken = useAccessToken(msalRequest);
    const getOptions = useOptions(accessToken);

    const callApi = useCallback((method = null, endpoint = null, data = null) => {
        if (!accessToken) return undefined;
        if (!method || !endpoint) return null;
        const full_endpoint = base_endpoint + endpoint;
        const headers = data?.headers ?? [];
        const body = data?.body ?? null;
        const options = getOptions(method, headers, body)
        return fetch(full_endpoint, options);
    }, [accessToken, getOptions, base_endpoint]);

    return callApi;
}