import { getExamObjects, setExamObjects } from "src/storage/ExamObject";
import { loadFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";
import { compareLastModified } from "src/util/Time";

// Returns an array containing all examObjects in localStorage
export function loadExamDescs() {
    return loadFromLocalStorage(StorageKeys.ASSESSMENTS, {});
}

export function setExamDescs(examDescs) {
    saveToLocalStorage(StorageKeys.ASSESSMENTS, examDescs);
}

export function loadExamsCount() {
    return Object.keys(loadExamDescs()).length;
}

// Returns undefined if there is no examObject with id
export function loadExamDesc(examObject) {
    return loadExamDescById(examObject.id);
}

export function loadExamDescById(id) {
    return loadExamDescs()[id];
}

export function setExamDesc(examDesc) {
    var items = loadExamDescs();
    items[examDesc.id] = examDesc;
    setExamDescs(items);
}

export function deleteLocalExam(id) {
    var newExamDescs = loadExamDescs();
    var newExamObjects = getExamObjects();
    delete newExamDescs[id];
    delete newExamObjects[id];
    setExamDescs(newExamDescs);
    setExamObjects(newExamObjects);
    return newExamDescs;
}

export function updateLocalExams(examsUpdate) {
    var newExams = {};
    var oldExams = loadExamDescs();
    // Delete localStorage exams which are no longer present server-side
    for (const [id, examDesc] of Object.entries(oldExams)) {
        if (id in examsUpdate) {
            if (compareLastModified(examDesc, examsUpdate[id])) {
                newExams[id] = examDesc;
            }
        }
    }
    // Add new exams that were created server-side
    for (const [id, updateDesc] of Object.entries(examsUpdate)) {
        if (!(id in newExams)) {
            newExams[id] = updateDesc;
        }
    }
    setExamDescs(newExams);
    return newExams;
}