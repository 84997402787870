import './Footer.css';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className="centered">
            <footer>
                <div className="dash-footer-container">
                    <nav>
                        <ul>
                            <li>
                                <div className='row'>
                                    <Link to="https://zanista.ai">Company</Link>
                                    <Link className='middle' to="https://zanista.ai/home3">Services</Link>
                                </div>
                            </li>
                            <li>
                                <div className='row-sectioned'>
                                    <Link to="https://zanista.ai">© Zanista AI</Link>
                                    <Link className='middle' to="https://zanista.ai/legal?tab=terms">Terms of Use</Link>
                                    <Link to="https://zanista.ai/legal?tab=privacy">Privacy Policy</Link>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </footer>
        </div>
    );
}
