import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useMemoFetch from "src/api/useMemoFetch";
import { getGradingGeneralFeedback, getGradingOverallFeedbackbyId, getGradingQuestionsFeedbackById, saveGradingGeneralFeedback, saveGradingOverallFeedbackById, saveGradingQuestionsFeedbackById, getOverallQuestionFeedback, saveOverallQuestionFeedback } from "src/storage/GradingStorage";


export function useFetchGeneralFeedback(id) {
    const cacheGet = useCallback(() => getGradingGeneralFeedback(id), [id]);
    const cacheSave = useCallback((feedback) => saveGradingGeneralFeedback(id, feedback), [id]);

    const processResponse = useCallback((response) => {
        return response.json().then(body => body["general_feedback"]);
    }, []);

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Grading.Feedback(id),
        processResponse, cacheGet, cacheSave
    });
}

export function useFetchOverallQuestionFeedback(id) {
    const cacheGet = useCallback(() => getOverallQuestionFeedback(id), [id]);
    const cacheSave = useCallback((feedback) => saveOverallQuestionFeedback(id, feedback), [id]);

    const processResponse = useCallback((response) => {
        return response.json().then(body => body["all_questions_feedback"]);
    }, []);

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Grading.Feedback.All(id),
        processResponse, cacheGet, cacheSave
    })
}

export function useFetchStudentFeedback(id, student_id) {
    const cacheGet = useCallback(() => getGradingOverallFeedbackbyId(id, student_id), [id, student_id]);
    const cacheSave = useCallback((feedback) => saveGradingOverallFeedbackById(id, student_id, feedback), [id, student_id]);

    const processResponse = useCallback((response) => {
        return response.json().then(body => body["overall_feedback"]);
    }, []);

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Grading.Feedback(id, student_id),
        processResponse, cacheGet, cacheSave
    });
}

export function useFetchStudentQuestionFeedback(id, student_id) {
    const cacheGet = useCallback(() =>
        getGradingQuestionsFeedbackById(id, student_id),
        [id, student_id]);
    const cacheSave = useCallback((feedback) =>
        saveGradingQuestionsFeedbackById(id, student_id, feedback),
        [id, student_id]);

    const processResponse = useCallback((response) => {
        return response.json().then(body => {
            return body["feedback_for_all_questions"]
        });
    }, []);

    return useMemoFetch({
        apiEndpoint: GoodPointApi.Grading.Feedback.All(id, student_id),
        processResponse, cacheGet, cacheSave
    });
}