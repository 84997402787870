import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import { useEffect, useState } from 'react';
import { Edit, Play, Prohibition, Trash } from 'iconoir-react';
import Popup from 'reactjs-popup';
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'
import cronstrue from 'cronstrue';
import useNewsWitchApi from 'src/api/newswitch/useNewsWitchApi';
import { useMsal } from '@azure/msal-react';
import useTimezoneOffset from 'src/util/useTimezoneOffset';
import { ComponentGuard } from 'src/api/RouteGuard';
import TimingBar from 'src/pages/newswitch/components/TimingBar';

function JobsPage() {
    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="NewsWitch" href=".." />
                <Title title="Scheduled Jobs"/>
                <JobsTable />
            </div>
        </div>
    );
}

function JobsTable({ onJobStatusChange = (job) => {}, publicMode = undefined }) {
    const callAPI = useNewsWitchApi();
    const tzOffset = useTimezoneOffset();
    const msal = useMsal();

    const keys = ["name", "schedule", "id", "last_job"]

    const [jobs, setJobs] = useState(undefined);
    const [originalJobs, setOriginalJobs] = useState();
    const [edited, setEdited] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false);
    const [timings, setTimings] = useState(undefined);

    // Fetch the jobs
    useEffect(() => {
        const endpoint = publicMode ? "/public/jobs" : "/jobs";
        callAPI("GET", endpoint)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    const jobs = body["jobs"].map(job => {
                        return {
                            ...job,
                        };
                    });
                    setJobs(jobs);
                    // Ensure we use a deep copy of inputs
                    setOriginalJobs(JSON.parse(JSON.stringify(jobs)));
                });
            } else {
                console.error(response);
            }
        });
    }, [callAPI, publicMode]);

    // Set edited flag if jobs have changed since fetch
    useEffect(() => {
        if (jobs === undefined || originalJobs === undefined) return;
        if (jobs.length !== originalJobs.length) {
            setEdited(true);
            return;
        }
        setEdited(new Set(jobs) === new Set(originalJobs))
    }, [originalJobs, jobs]);

    // Get the status of the last run for each job
    useEffect(() => {
        if (originalJobs === undefined) return;
        const endpoint = publicMode ? "/public/job" : "/job";
        originalJobs.forEach(job => {
            callAPI("GET", `${endpoint}/${job["id"]}`)?.then(response => {
                if (response.status !== 200) {
                    console.error(response);
                } else {
                    response.json().then(body => {
                        const latest_job = body["job"]["latest_job"];
                        job["last_job"] = latest_job;
                        setJobs(prev => prev.map(i => i["id"] === job["id"] ? job : i));
                        // If the job is currently running, set an interval to check the status every 30 seconds
                        if (latest_job["active"]) {
                            const interval = setInterval(() => {
                                callAPI("GET", `${endpoint}/${job["id"]}`).then(response => {
                                    if (response.status !== 200) {
                                        console.error(response);
                                    } else {
                                        response.json().then(body => {
                                            const latest_job = body["job"]["latest_job"];
                                            job["last_job"] = latest_job;
                                            setJobs(prev => prev.map(i => i["id"] === job["id"] ? job : i));
                                            // If the job status is no longer running stop repeating
                                            if (!latest_job['active']) {
                                                clearInterval(interval);
                                                onJobStatusChange(job)
                                            }
                                        });
                                    }
                                });
                            }, 30000);
                        }
                    });
                }
            });
        });
    }, [callAPI, originalJobs, publicMode, onJobStatusChange]);

    // Delete a job
    useEffect(() => {
        if (!savingChanges || jobs === undefined) return;
        setSavingChanges(false);
        const removedJobs = originalJobs.filter(originalJob => !jobs.some(job => job['id'] === originalJob['id']));
        const endpoint = publicMode ? "/public/job" : "/job";
        removedJobs.forEach(job => {
            callAPI("POST", `${endpoint}/delete?job_id=${job["id"]}`)?.then(response => {
                if (response.status !== 200) {
                    console.error(response);
                    // Add it back if it failed
                    setJobs(jobs.concat(job));
                }
                setOriginalJobs(JSON.parse(JSON.stringify(jobs)));
            });
        });
    }, [savingChanges, callAPI, jobs, originalJobs, publicMode]);

    useEffect(() => {
        if (originalJobs === undefined) return;
        // only run if currentAccount is an admin
        const currentAccount = msal.instance.getActiveAccount()
        if (!currentAccount) return;
        const claimRole = typeof currentAccount.idTokenClaims['extension_Role'] === 'string' ? currentAccount.idTokenClaims['extension_Role'] : "";
        if (!claimRole.includes("admin")) return;
        originalJobs.forEach(job => {
            const job_id = job['id']
            const endpoint = `/job/${job_id}/latest`;
            //const endpoint = `/job/unknown/latest`;
            callAPI("GET", endpoint)?.then(response => {
                if (response.status !== 200) {
                    console.error(response);
                } else {
                    response.json().then(body => {
                        const timing = body["timing"];
                        setTimings(prev => {
                            return {
                                ...prev,
                                [job_id]: timing,
                            }
                        });
                    });
                }
            });
        });
    }, [originalJobs, callAPI, msal]);
    
    return (
        <div>
            <div className="flex-row gap-x-2 justify-end items-center">
                {
                    edited &&
                    <div className="cursor-pointer outline outline-1 rounded my-2 p-1 px-2 text-xs w-fit bg-orange-100 hover:bg-orange-300"
                        onClick={() => setSavingChanges(true)}
                    >
                        Save Changes
                    </div>
                }
                {
                    (edited || jobs?.filter(i => i['id'] !== "").length !== jobs?.length) &&
                    <div className="cursor-pointer outline outline-1 rounded my-2 p-1 px-2 text-xs w-fit bg-orange-100 hover:bg-orange-300"
                        onClick={() => setJobs(JSON.parse(JSON.stringify(originalJobs)))}
                    >
                        Reset
                    </div>
                }
                <Popup trigger={
                    <div className="cursor-pointer outline outline-1 rounded my-2 p-1 px-2 text-xs w-fit bg-orange-300 hover:bg-orange-400">
                        Create new Job
                    </div>
                } modal nested >
                    {close => (
                        <NewJobForm close={close} setJobs={(job) => {setJobs(prev => prev.concat(job)); setOriginalJobs(prev => prev.concat(job))}} publicMode={publicMode} />
                    )}
                </Popup>
            </div>
            <div className='p-2 rounded outline outline-1'>
                {
                    jobs === undefined ? <div>Loading jobs...</div> :
                        jobs.length === 0 ? <div>No jobs found</div> : ( 
                        <div>
                            <div className='flex-row'>
                            <div className='w-full grid grid-cols-4'>
                                {
                                    keys.map(val => {
                                        return <p className='text-xs'>{val.toUpperCase()}</p>
                                    })
                                }
                            </div>
                                <div className='w-4 h-4 mx-2' />
                            </div>
                            {
                                jobs.map(job => {
                                    let statusLines = [""];
                                    let active = false;
                                    if (job['last_job'] !== undefined) {
                                        console.log(job)
                                        active = job['last_job']['active'];
                                        const suceeded = job['last_job']['succeeded'];
                                        const failed = job['last_job']['failed'];
                                        const hasRun = active || suceeded || failed;
                                        statusLines[0] = active ? "Running" : (suceeded ? "Succeeded" : (failed ? "Failed" : "Not Run"));
                                        if (hasRun) {
                                            // convert timestamp to date string
                                            const date = new Date(job['last_job']['start_time'] * 1000);
                                            statusLines.push("Started: " + date.toLocaleString());
                                        }
                                        if (suceeded) {
                                            const start = new Date(job['last_job']['start_time'] * 1000);
                                            const end = new Date(job['last_job']['completion_time'] * 1000);
                                            statusLines.push("Finished: " + end.toLocaleString());
                                            // @ts-ignore
                                            const duration = end - start;
                                            const hours = Math.floor(duration / 3600000);
                                            const minutes = Math.floor((duration % 3600000) / 60000);
                                            const seconds = Math.floor((duration % 60000) / 1000);
                                            statusLines.push(`Duration: ${hours}h ${minutes}m ${seconds}s`);
                                        }
                                    }
                                    return (
                                        <div className='flex-row items-start min-h-24 h-fit'>
                                            <div key={job['id']} className='border-t pt-2 my-2 w-full grid grid-cols-4'>
                                                    <p className='text-xs'>
                                                        {job['name']}
                                                    </p>
                                                    <p className='text-xs'>
                                                        {
                                                            cronstrue.toString(job['spec']['schedule'], { verbose: true, tzOffset: tzOffset })
                                                        }
                                                    </p>
                                                    <p className='text-xs'>
                                                        {job['id']}
                                                    </p>
                                                    <div className='relative group'>
                                                        <div>
                                                        {
                                                            statusLines.map(line => {
                                                                return (
                                                                    <p className='text-xs'>
                                                                        {line}
                                                                    </p>
                                                                );
                                                            })
                                                        }
                                                        </div>
                                                        {
                                                            timings && timings[job['id']] &&
                                                            <div className='absolute top-0 right-full hidden lg:group-hover:block bg-white outline outline-1 rounded mx-2 p-1'>
                                                                <TimingBar timings={timings && timings[job['id']]} />
                                                            </div>
                                                        }
                                                    </div>
                                            </div>
                                            { job['listener'].startsWith("u") || job['owner'] === msal.instance.getActiveAccount()?.idTokenClaims['extension_UserID'] ?
                                                <div className='flex-col mb-2 mt-4 gap-1'>
                                                    <Popup trigger={
                                                        <div className='cursor-pointer rounded bg-gray-400 outline outline-1 w-4 h-4 mx-2'>
                                                            <Edit color='white' height="20" width="16" className='m-auto'/>
                                                        </div>
                                                    } modal nested >
                                                        {
                                                        // @ts-ignore
                                                        close => (
                                                            <NewJobForm 
                                                                close={close} 
                                                                publicMode={publicMode}
                                                                _job={job}
                                                                setJobs={(job) => {
                                                                    setJobs(prev => prev.map(i => i['id'] === job['id'] ? job : i)); 
                                                                    setOriginalJobs(prev => prev.map(i => i['id'] === job['id'] ? job : i)); 
                                                                }}
                                                            />
                                                        )}
                                                    </Popup>
                                                    <div className='cursor-pointer rounded bg-red-500 outline outline-1 w-4 h-4 m-2'
                                                        onClick={() => {
                                                            const newJobs = jobs.filter(i => i !== job);
                                                            setJobs(newJobs);
                                                        }}
                                                    >
                                                        <Trash color='white' height="20" width="16" className='m-auto'/>
                                                    </div>
                                                    { job['last_job'] !== undefined && (active ?
                                                        <div className='cursor-pointer rounded bg-red-500 outline outline-1 w-4 h-4 mx-2'
                                                            onClick={() => {alert("Cancel job is not available")}}
                                                        >
                                                            <Prohibition color='white' height="20" width="16" className='m-auto'/>
                                                        </div>
                                                     :
                                                        <div className='cursor-pointer rounded bg-orange-400 outline outline-1 w-4 h-4 mx-2'
                                                            onClick={() => {alert("Manual job trigger is not available")}}
                                                        >
                                                            <Play color='white' height="20" width="16" className='m-auto'/>
                                                        </div>
                                                     )
                                                    }
                                                </div>
                                            :
                                                <div className='flex-col mb-2 mt-4 gap-1'>
                                                    <div className='w-4 h-4 mx-2' />
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const shiftSchedule = (schedule, tzOffset) => {
    const parts = schedule.split(' ');
    const offset = 24 - tzOffset; // Removes negative values
    
    let hourPart = parts[1];
    if (hourPart.includes('-')) {
        // Range case: e.g., "0-3"
        let [start, end] = hourPart.split('-').map(Number);
        start = (start + offset) % 24;
        end = (end + offset) % 24;
        hourPart = `${start}-${end}`;
    } else if (hourPart.includes('/')) {
        // Step case: e.g., "*/3"
        let [base, step] = hourPart.split('/');
        base = base === '*' ? '*' : (parseInt(base) + offset) % 24;
        hourPart = `${base}/${step}`;
    } else if (hourPart === '*') {
        // Wildcard case: "*"
        hourPart = '*';
    } else {
        // Single hour case: e.g., "2"
        let hour = (parseInt(hourPart) + offset) % 24;
        hourPart = hour.toString();
    }

    parts[1] = hourPart;
    
    return parts.join(' ');
}

function NewJobForm({ close, setJobs, publicMode = undefined, _job = undefined }) {
    const callAPI = useNewsWitchApi()
    const tzOffset = useTimezoneOffset();
    const updatingJob = _job !== undefined;

    const [job, setJob] = useState(
        updatingJob ? 
        {
            ..._job,
            "spec": {
                "schedule": shiftSchedule(_job["spec"]["schedule"], -tzOffset)
            }
        } : 
        {
            "name": "Daily Job",
            "spec": {
                "schedule": "0 10 * * *"
            },
            "emails": "",
            "ccs": "",
            "test_mode": false,
        }
    );
    const [savingChanges, setSavingChanges] = useState(false);
    const [waitingForResponse, setWaitingForResponse] = useState(false);

    // Create/Update the job
    useEffect(() => {
        if (!savingChanges || waitingForResponse || job === undefined) return;

        const validateJob = (job) => {
            if (job === undefined) return false;
            if (job["name"] === "") return false;
            if (job["spec"]["schedule"] === "") return false;
            // remove all whitespace
            if (job.emails === undefined) job.emails = "";
            job.emails = job.emails.replace(/\s/g, '');
            if (job.emails !== "" && !job.emails.includes('@')) return false;
            if (job.ccs === undefined) job.ccs = "";
            job.ccs = job?.ccs.replace(/\s/g, '');
            if (job.ccs !== "" && !job.ccs.includes('@')) return false;
            return true;
        }

        if (!validateJob(job)) {
            console.error("Invalid job", job);
            setSavingChanges(false);
            return;
        }

        setSavingChanges(false);
        setWaitingForResponse(true);
        const endpoint = publicMode ? "/public/job" : "/job";
        const params = new URLSearchParams({
            "name": job["name"],
            "schedule": shiftSchedule(job["spec"]["schedule"], tzOffset),
        });
        // Conditionally add public params
        if (job.emails !== "" && publicMode) {
            params.append("emails", job.emails.replace(/\s/g, ''));
        }
        if (job.ccs !== "" && publicMode) {
            params.append("ccs", job.ccs.replace(/\s/g, ''));
        }
        if (publicMode && !updatingJob) {
            params.append("test_mode", job.test_mode.toString());
        }

        if (updatingJob) {
            params.append("job_id", job["id"]);
            callAPI("POST", `${endpoint}/update?` + params.toString())?.then(response => {
                if (response.status !== 200) {
                    console.error(response);
                } else {
                    response.json().then(body => {
                        const newJob = body["job"];
                        setJob(newJob);
                        setJobs(newJob);
                    });
                }
                close();
                setWaitingForResponse(false);
            });
        } else {
            callAPI("POST", `${endpoint}/create?` + params.toString())?.then(response => {
                if (response.status !== 200) {
                    console.error(response);
                } else {
                    response.json().then(body => {
                        const newJob = body["job"];
                        setJob(newJob);
                        setJobs(newJob);
                    });
                }
                close();
                setWaitingForResponse(false);
            });
        }
        
    }, [savingChanges, callAPI, job, close, waitingForResponse, setJobs, publicMode, tzOffset, updatingJob]);

    return (
        <div className='flex-col p-4 bg-white rounded gap-4 max-w-2/3'>
            <label className='text-xs p-1'>Name</label>
            <input type="text" placeholder="Name" value={job?.name} className='outline outline-1 rounded p-1'
                onChange={(e) => setJob({...job, "name": e.target.value})}
            />
            <label className='text-xs p-1'>Schedule</label>
            <Cron 
                value={job["spec"]["schedule"]} 
                setValue={(value) => setJob({...job, "spec": {"schedule": value}})}
                leadingZero={true}
                mode="single"
                periodicityOnDoubleClick={false}
                //allowedPeriods={['year', 'month', 'day', 'hour']}
                allowedPeriods={['day', 'month']}
                clockFormat='12-hour-clock'
                clearButton={false}
                allowedDropdowns={['period', 'months', 'month-days', 'hours', 'minutes']}
                dropdownsConfig={{
                    'period': {
                        disabled: false,
                    },
                    'months': {
                    },
                    'month-days': {
                    },
                    'week-days': {
                        disabled: true,
                    },
                    'hours': {
                        leadingZero: false,

                    },
                    'minutes': {
                      leadingZero: true,
                      filterOption: ({ value }) => Number(value) % 15 === 0,
                    },
                  }}
            />
            {
                publicMode && !updatingJob &&
                <ComponentGuard roles={["admin"]}>
                    <label className='text-xs p-1'>Email Recipients (comma separated)</label>
                </ComponentGuard>
            }
            {
                publicMode && !updatingJob &&
                <ComponentGuard roles={["admin"]}>
                    <input type="text" placeholder="team@zanista.ai" value={job?.emails} className='outline outline-1 rounded p-1 w-full'
                        onChange={(e) => setJob({...job, "emails": e.target.value})}
                    />
                </ComponentGuard>
            }
            {
                publicMode && !updatingJob &&
                <ComponentGuard roles={["admin"]}>
                    <label className='text-xs p-1'>Email CCs (comma separated)</label>
                </ComponentGuard>
            }
            {
                publicMode && !updatingJob &&
                <ComponentGuard roles={["admin"]}>
                    <input type="text" placeholder="" value={job?.ccs} className='outline outline-1 rounded p-1 w-full'
                        onChange={(e) => setJob({...job, "ccs": e.target.value})}
                    />
                </ComponentGuard>
            }
            {
                publicMode && !updatingJob &&
                <ComponentGuard roles={["admin"]}>
                    <label className='text-xs p-1'>Test Mode</label>
                    <input type="checkbox" checked={job?.test_mode} className='outline outline-1 rounded p-1 mx-2'
                        onChange={(e) => setJob({...job, "test_mode": e.target.checked})}
                    />
                </ComponentGuard>
            }
            <div className='flex-row gap-x-2 justify-end'>
                <div className="cursor-pointer outline outline-1 rounded my-2 p-1 px-2 text-xs w-fit hover:bg-orange-300"
                    onClick={close}
                >
                    Cancel
                </div>
                <div className="cursor-pointer outline outline-1 rounded my-2 p-1 px-2 text-xs w-fit bg-orange-300 hover:bg-orange-400"
                    onClick={() => setSavingChanges(true)}
                >
                    {updatingJob ? "Update" : "Create"} Job
                </div>
            </div>
        </div>
    )
}

export { JobsPage, JobsTable, NewJobForm };
