import { useMsal } from "@azure/msal-react";

const { REACT_APP_IS_DEV } = process.env;

function useGetRole() {
    const { instance } = useMsal();
    if (REACT_APP_IS_DEV) {
        return "admin";
    }
    const currentAccount = instance.getActiveAccount();

    const claimRole = 
        typeof currentAccount.idTokenClaims['extension_Role'] === 'string' 
            ? currentAccount.idTokenClaims['extension_Role'] 
            : "";
    return claimRole;
}

function useHasRole(role) {
    const claimRole = useGetRole();
    return claimRole === role;
}

export { useGetRole, useHasRole };
