import StructureSection from "src/pages/goodpoint/exam/components/structure/StructureSection";
import { calculatePercentage } from "src/util/StringUtil";
import "./StudentFeedbackItem.css";
import { StructureItem } from "src/pages/goodpoint/exam/components/structure/StructureItem";
import { useContext, useState, useEffect } from "react";
import { ExamStructureContext } from "src/pages/goodpoint/exam/components/structure/ExamStructureContext";
import { Tooltip } from "@mui/material";
import { StudentsContext } from "../../../answers/StudentsContext";
import { updateNestedStructure } from "src/components/ui/Helpers";
import { saveGradingQuestionsFeedbackById, saveGradingAllStudentsResults } from "src/storage/GradingStorage";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { getRubricById } from "src/storage/RubricStorage";
import { findNestedQuestionText } from "src/components/ui/Helpers";
import { useHasRole } from "src/api/useGetRole";

function StudentFeedbackItemHeader({ itemData, nestingLevel }) {
    const [warningOpen, setWarningOpen] = useState(false);
    const { selectedStudent, questionFeedback, students } = useContext(StudentsContext);
    const { examObject } = useContext(ExamContext);
    const [thisPercentage, setThisPercentage] = useState(
        calculatePercentage(itemData.marks, itemData.max_marks)
    );

    //this is actually to keep track of the previous mark to update the difference but i'm saur lazy to change it 
    const [marks, setMarks] = useState(parseFloat(itemData.marks));
    useEffect(() => {
        setMarks(parseFloat(itemData.marks));
    }, [itemData.marks])

    const handleMarkChange = (event) => {
        const newMark = parseFloat(event.target.value) || 0;

        if (newMark < 0 || newMark > itemData.max_marks) {
            setWarningOpen(true);
        } else {
            // to update the total stats on the top of the page
            //don't ask me why this causes subquestions to not be able to change their marks girl i don't know
            // const updatedStudent = { ...selectedStudent };
            // updatedStudent.total_marks += newMark - marks;
            // setSelectedStudent(updatedStudent);

            //TO update the thign on the screen
            setMarks(newMark);
            setThisPercentage(calculatePercentage(newMark, itemData.max_marks));

            //to save to cache
            if (students) {
                const studentDictionary = students.reduce((acc, student) => {
                    acc[student.student_id] = student;
                    return acc;
                }, {});
                studentDictionary[selectedStudent.student_id].total_marks += newMark - marks;
                saveGradingAllStudentsResults(examObject.id, studentDictionary)
            }
            const newValues = {
                marks: newMark,
            };
            const updatedFeedback = { ...questionFeedback };
            updateNestedStructure(updatedFeedback, itemData.keys, newValues, "marks"); //note: does not adjust bottom up or top down marks      
            saveGradingQuestionsFeedbackById(examObject.id, selectedStudent.student_id, updatedFeedback);
        }
    };

    const readOnly = useHasRole('student');

    return (
        <div className="student-feedback-item-header fill-width">
            <p className="student-feedback-item-question-tag justify-self-left">
                <b>
                    {nestingLevel > 0 ? "Part " : ""}
                    {itemData.tag}
                </b>
            </p>
            <p
                className="student-feedback-item-flagged justify-self-right"
                style={{ color: itemData.flagged ? "black" : "transparent" }}
            >
                (NEEDS REVIEW)
            </p>
            <div className="flex flex-row">
                <input
                    type="number"
                    key={`${itemData.tag}_${marks}`}
                    onBlur={handleMarkChange}
                    onClick={(event) => event.stopPropagation()}
                    defaultValue={marks}
                    className={`w-12 bg-transparent focus:bg-white focus:outline-accent text-right ${readOnly ? "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" : ""}`}
                    disabled={readOnly}
                />
                <Tooltip
                    open={warningOpen}
                    onOpen={() => setWarningOpen(true)}
                    onClose={() => setWarningOpen(false)}
                    title="Invalid marks"
                >
                    <div></div>
                </Tooltip>
                <p className="student-feedback-item-marks">
                    /{itemData.max_marks} Marks
                </p>
            </div>

            <p
                key={`${itemData.tag}_${itemData.marks}`} //forces rerender
                className="student-feedback-item-percentage"
            >
                {thisPercentage}%
            </p>
        </div>
    );
}

function StudentFeedbackItemContent({ itemData }) {
    const [feedback, setFeedback] = useState(itemData.feedback);
    const { selectedStudent, questionFeedback } = useContext(StudentsContext);
    const { examObject } = useContext(ExamContext);
    let questionText = null;

    //get question text
    const rubricData = getRubricById(examObject.id);
    if (rubricData && rubricData.rubric) {
        const rubric = rubricData.rubric;
        questionText = findNestedQuestionText(itemData, rubric);
    }

    //to make sure it updates on the screen whenevr the seleced student change
    useEffect(() => {
        setFeedback(itemData.feedback);
    }, [selectedStudent, itemData.feedback])
    //don't ask me why it's like that i have no other choice 

    useEffect(() => {
        const newValues = {
            feedback: feedback,
        };
        const updatedFeedback = { ...questionFeedback }
        updateNestedStructure(updatedFeedback, itemData.keys, newValues, "content");
        saveGradingQuestionsFeedbackById(examObject.id, selectedStudent.student_id, updatedFeedback);
        //i am fucking sick of eslint giving me wrong info and causing infinite loops and bugs!! kys
        // eslint-disable-next-line
    }, [feedback])

    return (
        <div className="feedback-content">
            {questionText && (
                <StructureSection
                    heading={"Question"}
                    content={questionText}
                    children={undefined}
                    hide
                />
            )}
            {itemData.feedback && (
                <StructureSection
                    content={feedback}
                    setContent={setFeedback}
                    heading={"Feedback"}
                    aiGenerated
                >
                </StructureSection>
            )}
        </div>
    );
}

export default function StudentFeedbackItem({ itemData, ...props }) {
    const { isFilterOnlyFlagged, isFilterOnlyMistakes } =
        useContext(ExamStructureContext).itemCommonProps;

    if (isFilterOnlyMistakes && !(itemData.marks < itemData.max_marks)) {
        return null;
    }
    if (isFilterOnlyFlagged && !itemData.flagged) {
        return null;
    }

    return (
        <StructureItem
            itemData={itemData}
            HeaderComponent={StudentFeedbackItemHeader}
            ContentComponent={StudentFeedbackItemContent}
            {...props}
        />
    );
}
