import "./GradingCompleted.css"
import { Group, MultiplePages } from "iconoir-react";
import { GradingType } from "src/api/goodpoint/Const";
import { useState } from "react";
import ClassPerformance from "src/pages/goodpoint/exam/tabs/grading/class-performance/ClassPerformance";
import StudentPerformance from "src/pages/goodpoint/exam/tabs/grading/student-performance/StudentPerformance";


function TypeSelectButton({ gradingType, Icon, text, selectedGradingType, setSelectedGradingType }) {
    return (
        <button
            className={
                `grading-type-button flex-row justify-content-center gap-small clickable border-thin rounded-small outline-thin ` +
                `${(selectedGradingType === gradingType) ? "bg-orange-mid font-weight-bold" : "bg-yellow-light outline-transparent"}`
            }
            onClick={() => setSelectedGradingType(gradingType)}
        >
            <Icon strokeWidth={1.5} />
            {
                (selectedGradingType === GradingType.CLASS) ? <b>{text}</b> : <p>{text}</p>
            }
        </button>
    )
}

export default function GradingCompleted() {
    const [gradingType, setGradingType] = useState(GradingType.CLASS);

    // const fetchGeneralResults = useFetchGeneralResults(examObject.id);

    // const examResults = useMemo(() => {
    //     const result = fetchGeneralResults();
    //     return result;
    // }, [fetchGeneralResults]);

    return (
        <div className="grading-content">
            <div className='grading-type-select gap-large fill-width'>
                <TypeSelectButton
                    gradingType={GradingType.CLASS} Icon={MultiplePages} text={"Class Performance"}
                    selectedGradingType={gradingType} setSelectedGradingType={setGradingType}
                />
                <TypeSelectButton
                    gradingType={GradingType.STUDENTS} Icon={Group} text={"Student Performance"}
                    selectedGradingType={gradingType} setSelectedGradingType={setGradingType}
                />
            </div>
            {
                gradingType === GradingType.CLASS
                    ? <ClassPerformance examResults={null} />
                    : <StudentPerformance examResults={null} />
            }
        </div>
    )
}