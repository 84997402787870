import { useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

export default function useFetchNews(newsOnly = false) {
    const callApi = useNewsWitchApi();

    const fetchNews = useCallback((ticker, startDate, endDate, relevantOnly = false, id = undefined) => {
        return callApi("GET", "/news?" + new URLSearchParams({
            ticker: ticker,
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            relevant_only: relevantOnly.toString().toLowerCase()
        }).toString())?.then(response => {
            if (response.status === 200) {
                return response.json().then(body => {
                    if (body["news"][ticker] === undefined) {
                        return id ? {
                            news: [],
                            id: id
                        } : [];
                    }

                    const newsResult = body["news"][ticker].map(n => ({
                        ...n,
                        parsing_allowed: n["parsing_allowed"] !== undefined && n["parsing_allowed"] !== null ? n["parsing_allowed"] : !((n["body"] === undefined || n["body"] === null) && n["sentiment"]["direction"] !== "Irrelevant"),
                        non_news: n["non_news"] !== undefined && n["non_news"] !== null ? n["non_news"] : false,
                        rank: n["rank"] !== undefined && n["rank"] !== null ? n["rank"] : -1
                    })).sort((a, b) => {
                        // Note: This sorting is reversed as the duplicate detection is done in reverse
                        if (a.published_date !== b.published_date) return a.published_date < b.published_date ? 1 : -1;
                        if (a.rank !== b.rank) return a.rank < b.rank ? 1 : -1;
                        return 0;
                    });

                    // Simple duplicate detection
                    const mapped = newsResult.map((n, i) => {
                        newsResult.slice(0, i).forEach(d => {
                            if (d.title === n.title && d.url === n.url) {
                                return {
                                    ...n,
                                    duplicate: true
                                }
                            }
                        });
                        return {
                            ...n,
                            duplicate: false
                        }
                    }).reverse();

                    const r = newsOnly ? mapped : Array.from(
                        { length: Math.ceil((endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)) },
                        (_, i) => new Date(startDate.getTime() + i * (24 * 60 * 60 * 1000)).toISOString().split('T')[0]
                    ).map(date => {
                        const newsForDate = mapped.filter(n => new Date(n["date"] * 1000).toISOString().split('T')[0] === date);
                        return {
                            published_date: date,
                            news: newsForDate
                        }
                    })

                    return id !== undefined ? {
                        news: r,
                        id: id
                    } : r;

                });
            }
            console.error(response);
            return id !== undefined ? {
                news: [],
                id: id
            } : [];
        });

    }, [callApi, newsOnly]);

    return fetchNews;
}
