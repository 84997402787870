import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { RubricType } from "src/api/goodpoint/Const";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import useFetchRubricQuestions from "src/api/goodpoint/useFetchRubric";
import RubricStructure from "src/pages/goodpoint/exam/tabs/rubric/structure/RubricStructure";
import LoadingTab from "src/components/ui/LoadingTab";
import DownloadButton from "src/components/ui/DownloadButton";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { capitalize } from "@mui/material";
import { EditMode, RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";
import useCallApi from "src/api/useCallApi";
import slugify from "slugify";


function RubricTypeSelect({ rubricType, setRubricType }) {
    return (
        <div className="rubric-type-select flex-row gap-large">
            <button
                className={
                    `rubric-type-enhanced clickable border-mid rounded-small ` +
                    `${rubricType === RubricType.ENHANCED
                        ? "bg-orange-mid font-weight-bold"
                        : "bg-yellow-light"
                    }`
                }
                onClick={() => setRubricType(RubricType.ENHANCED)}
            >
                {rubricType === RubricType.ENHANCED ? (
                    <b>Enhanced with AI</b>
                ) : (
                    <p>Enhanced with AI</p>
                )}
            </button>
            <button
                className={
                    `rubric-type-original clickable border-mid rounded-small ` +
                    `${rubricType === RubricType.ORIGINAL
                        ? "bg-orange-mid font-weight-bold"
                        : "bg-yellow-light"
                    }`
                }
                onClick={() => setRubricType(RubricType.ORIGINAL)}
            >
                {rubricType === RubricType.ORIGINAL ? (
                    <b>Original</b>
                ) : (
                    <p>Original</p>
                )}
            </button>
        </div>
    )
}


function DownloadRubricButton({ type = "enhanced", ...props }) {
    const { examObject } = useContext(ExamContext);
    const examId = examObject.id;
    const examName = examObject.name;
    const filename = `${slugify(examName, { lower: true, strict: true, replacement: "_" })}_rubric_${type === "combined" ? "original" : type}.pdf`;
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.Rubric.Download(examId, type)}
            filename={filename}
            text={`Download ${capitalize(type === "combined" ? "original" : type)} Rubric`}
            bgColour={
                type === "enhanced" 
                    ? "var(--zanista-orange-mid)" 
                    : "var(--zanista-yellow-light)"
            }
            {...props}
        />
    );
}


export default function RubricCompleted() {
    const { examObject, setFooterControls } = useContext(ExamContext);
    const fetchRubric = useFetchRubricQuestions(examObject.id);
    const callApi = useCallApi();

    const [rubric, setRubric] = useState(null);
    const [rubricType, setRubricType] = useState(RubricType.ENHANCED);
    const [rubricUrl, setRubricUrl] = useState(null);
    const [editMode, setEditMode] = useState(EditMode.DEFAULT);

    useLayoutEffect(() => {
        setFooterControls([
            <DownloadRubricButton type={RubricType.ENHANCED}/>,
            <DownloadRubricButton type={RubricType.PROCESSED}/>,
            <DownloadRubricButton type={RubricType.COMBINED}/>,
        ]);
    }, [setFooterControls, examObject.id]);

    useEffect(() => {
        if (rubric) return;
        let fetchedRubric = fetchRubric();
        if (fetchedRubric) {
            if (fetchedRubric["rubric"]) {
                const newRubric = fetchedRubric["rubric"].sort((q1, q2) => q1["index"] - q2["index"]);
                setRubric(newRubric);
            }
        }
    }, [fetchRubric, rubric]);

    useEffect(() => {
        if (rubricUrl) return;
        callApi("GET", GoodPointApi.Rubric.Link(examObject.id))
            ?.then(response => {
                if (response.status === 200) {
                    response.json().then(body => {
                        var url = body["data"]["rubric_url"];
                        setRubricUrl(url);
                    })
                }
            })
    }, [callApi, examObject.id, rubricUrl]);

    return (
        <RubricContext.Provider value={{
            rubric, setRubric, rubricUrl, setRubricUrl, editMode, setEditMode, rubricType, setRubricType
        }}>
            <RubricTypeSelect rubricType={rubricType} setRubricType={setRubricType} />
            {
                rubric != null ? (
                    <RubricStructure rubric={rubric} enhanced={rubricType === RubricType.ENHANCED} />
                ) : (
                    <LoadingTab />
                )
            }
        </RubricContext.Provider>
    )
}
