import dayjs from "dayjs";

/**
 * Returns true if the first item's "last_modified" is greater than the second item's "last_modified", otherwise false.
 * If either item's "last_modified" is undefined, returns undefined.
 */
function compareLastModified(item1, item2) {
    const lm_1 = item1["last_modified"];
    const lm_2 = item2["last_modified"];
    if (!lm_1 || !lm_2) {
        return undefined;
    }
    const result = dayjs(lm_2).isBefore(dayjs(lm_1));
    return result;
}

export { compareLastModified }