import { Link } from "react-router-dom"
import { PRODUCT_COLS } from "src/pages/dashboard/Dashboard"

import "./ProductCard.css"

function Card({ link = null, index, className, children }) {
    return <Link
        to={link}
        className={`
            product-card flex-col rounded-big align-center justify-content-center 
            ${(link !== null) ? "clickable" : "cursor-disabled"}
            ${className}
        `}
        style={{
            gridRow: Math.floor(index / PRODUCT_COLS) + 1,
            gridColumn: index % PRODUCT_COLS + 1
        }}
    >
        {children}
    </Link>
}

export function ProductCard({ name, index, subtitle, Icon, features, link }) {
    return (
        <Card link={link} className="bg-orange-mid border-mid" index={index}>
            <Icon width={80} height={80} strokeWidth={1.0} />
            <h2 className="product-name font-size-x-large font-weight-bold text-align-center">{name}</h2>
            <p className="product-subtitle margin-small text-align-center">{subtitle}</p>
            <ul>
                {
                    features.map((feature, index) => {
                        return <li className="font-size-small" key={index}>{feature}</li>
                    })
                }
            </ul>
        </Card>
    )
}

export function ComingSoonCard({ index }) {
    return (
        <Card className="bg-orange-light border-thin border-grey" index={index}>
            <p className="fg-grey font-italic font-size-x-large position-relative text-align-center margin-mid">
                Coming Soon...
            </p>
        </Card>
    )
}