
function Blog() {
    return (
        <div className='pt-10 px-5'>
            <FeedSection />
        </div>
    );
}

const posts = [
    "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7204024568762486784",
    "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7198825540160548864",
    "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7186685306245390338",
    "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7177959419744276480",
    "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7179544637278179328"
]

function FeedSection() {
    return (
        <div>
            <div className="flex-row justify-start items-end mt-2 mb-4 bg-blue-900 rounded p-2 gap-1 cursor-pointer"
                onClick={() => window.open("https://www.linkedin.com/company/zanista-ai/posts/?feedView=all")}
            >
                <img src="https://img.icons8.com/?size=100&id=13930&format=png&color=000000" alt="LinkedIn"
                    className="mt-1 h-8 object-fit"
                />
                <h1 className='text-base md:text-xl text-white text-bold'>Follow Zanista AI in LinkedIn</h1>
            </div>
            <div className='flex-col flex-1'>
                {
                    posts.map((post, index) => (
                        <div key={index} className="mx-auto min-w-full flex-1 mb-4">
                            <iframe className=" min-w-full h-screen" src={post} frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}


export default Blog;
