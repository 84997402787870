import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";

export default function useSendShare(examId) {
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    });

    const sendShare = useCallback((email) => {
        const postBody = {
            "email": email
        };
        return callApi("POST", GoodPointApi.Exam.Share(examId), { body: postBody })
            ?.then(response => response.status === 200);
    }, [callApi, examId]);

    return sendShare;
}
