import { Tooltip } from "@mui/material";
import { ArrowDownCircleSolid, ArrowUpCircleSolid, Expand, InfoCircle, MinusCircleSolid, WarningCircle } from "iconoir-react";
import { useState } from "react";

function NewsLists({ ticker, newsList }) {
    const dateLists = {};
    newsList.forEach(item => {
        // if (!item["sentiment"]["direction"] || item["sentiment"]["direction"] === "Irrelevant") return;
        let date = new Date(item["date"] * 1000).toDateString();
        if (date === "Invalid Date") {
            date = new Date(item["date"]).toDateString();
        }
        if (!dateLists[date]) {
            dateLists[date] = [];
        }
        dateLists[date].push(item);
    });
    return (
        <details>
            <summary className="list-item">
                <div className="grid grid-cols-[1fr_auto] items-center">
                    <h2>News summaries for {ticker}</h2>
                    <span className="text-sm font-light ml-2">{newsList.length} News</span>
                </div>
            </summary>
            {
                Object.entries(dateLists).sort(([d1, v1], [d2, v2]) => ((+ (new Date(d1) < new Date(d2))) * 2) - 1).map(([date, newsList]) => {
                    const positiveNews = newsList.filter(item => item["sentiment"]["direction"]?.includes("Positive"));
                    const negativeNews = newsList.filter(item => item["sentiment"]["direction"]?.includes("Negative"));
                    const neutralNews = newsList.filter(item => item["sentiment"]["direction"] === "Neutral");
                    const irrelevantNews = newsList.filter(item => !(item["sentiment"]["direction"]?.includes("Positive") || item["sentiment"]["direction"]?.includes("Negative") || item["sentiment"]["direction"]?.includes("Neutral")));
                    return (
                        <details key={date}>
                            <summary className="list-item">
                                <div className="grid grid-cols-[1fr_auto] ">
                                    Published Date: {date}
                                    <span className="text-sm font-light">{positiveNews.length + negativeNews.length} Important News</span>
                                </div>
                            </summary>
                            <NewsList className="positive" title="Positive News" newsList={positiveNews} />
                            <NewsList className="negative" title="Negative News" newsList={negativeNews} />
                            <NewsList className="neutral" title="Neutral News" newsList={neutralNews} />
                            <NewsList className="neutral" title="Irrelevant News" newsList={irrelevantNews} />
                        </details>
                    );
                })
            }
        </details>
    );
}

function NewsListsDict({ newsData, ticker = undefined, isLoading = false }) {

    const [expanded, setExpanded] = useState(true);

    if (!newsData || Object.keys(newsData).length === 0) return (
        <details>
            <summary className="list-item">
                <div className="grid grid-cols-[1fr_auto] items-center">
                    <h2>News Summaries</h2>
                    <span className="text-sm font-light ml-2"></span>
                </div>
            </summary>
        </details>
    );

    if (ticker === undefined) {
        ticker = Object.keys(newsData)[0];
    }

    // TODO: Check this count
    const totalNews = newsData[ticker] ? Object.values(newsData[ticker]).reduce((acc, curr) => acc + (curr["sentiment"]["news_count"] ? curr["sentiment"]["news_count"] : 0), 0) : 0;
    
    function getRelevantNews(sentiment) {
        const basicSentiment = sentiment["basic"];
        return basicSentiment["neutral"] + basicSentiment["positive"] + basicSentiment["negative"];
    }

    return (
        <details>
            <summary className="list-item title">
                <div className="grid grid-cols-[1fr_auto] items-center">
                    <h2>News Summaries</h2>
                    <span className="flex flex-row items-center justify-end gap-2 mx-2">
                        {
                            isLoading && 
                            <span className="text-sm font-light flex items-center gap-2">
                                <div className="w-4 h-4 border-2 border-gray-300 border-t-orange-500 rounded-full animate-spin"></div>
                            </span>
                        }
                        <p className="text-sm font-light">{totalNews} News</p>
                        <ExpandButton expanded={expanded} onClick={() => {}} />
                    </span>
                </div>
            </summary>
                
            <div className={`news-list-container relative ${expanded ? "expanded" : "collapsed"}`}>
                {
                newsData[ticker] &&
                [...newsData[ticker]].reverse().map((obj) => {
                    const date = obj["date"];
                    const newsList = obj["news"];
                    const sentiment = obj["sentiment"];

                    const positiveNews = newsList.filter(item => item["sentiment"]["direction"]?.includes("Positive"));
                    const negativeNews = newsList.filter(item => item["sentiment"]["direction"]?.includes("Negative"));
                    const neutralNews = newsList.filter(item => item["sentiment"]["direction"] === "Neutral");
                    const irrelevantNews = newsList.filter(item => !(item["sentiment"]["direction"]?.includes("Positive") || item["sentiment"]["direction"]?.includes("Negative") || item["sentiment"]["direction"]?.includes("Neutral")));
                    const duplicateNews = newsList.filter(item => item["duplicate"]);

                    const importantNews = sentiment["basic"] ? getRelevantNews(sentiment) : positiveNews.length + negativeNews.length
                    return (
                        <details key={date} onClick={() => setExpanded(!expanded)}>
                            <summary className="list-item news-day">
                                <div className="flex-row justify-between">
                                    {new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/\s/g, ' ')}
                                    <div className="flex-row gap-x-2 items-center">
                                        <span className="text-sm font-light">{importantNews} Important News</span>
                                        <SentimentOverview sentiment={sentiment} />
                                    </div>
                                </div>
                            </summary>
                            {
                                importantNews !== 0 && newsList.length === 0 && (
                                    <div className="flex-row items-center gap-2 ml-2">
                                            <span className="text-sm font-light flex items-center gap-2">
                                                <div className="w-4 h-4 border-2 border-gray-300 border-t-orange-500 rounded-full animate-spin"></div>
                                            </span>
                                            Loading News...
                                    </div>
                                )
                            }
                            {
                                importantNews === 0 && <p className="ml-4">No news</p>
                            }
                            <NewsList className="positive" title="Positive News" newsList={positiveNews} />
                            <NewsList className="negative" title="Negative News" newsList={negativeNews} />
                            <NewsList className="neutral" title="Neutral News" newsList={neutralNews} />
                            {
                                irrelevantNews.length > 0 ? 
                                    <NewsList className="neutral" title="Irrelevant News" newsList={irrelevantNews} />
                                :
                                    sentiment["basic"] && 
                                    <PlaceHolderNewsList 
                                        className="neutral" 
                                        title="Irrelevant News" 
                                        message="Irrelevant News is not displayed"
                                        count={sentiment["basic"]["irrelevant"]} 
                                    />
                            }
                            <NewsList className="neutral" title="Duplicate News" newsList={duplicateNews} />
                        </details>
                    );
                })
            }
            </div>
        </details>
    );
}

function SentimentOverview({ sentiment }) {
    const iconSize = 18;
    
    if (!sentiment || !sentiment.basic) return (
        <div className="flex-row items-center text-sm text-gray-300">
            <MinusCircleSolid width={iconSize} height={iconSize} />
        </div>
    )
    
    const positive = sentiment.basic["positive"]
    const negative = sentiment.basic["negative"]
    const neutral = sentiment.basic["neutral"]
    const net = positive - negative;
    
    if (net === 0) return (
        <Tooltip 
            title={`${positive} positive, ${negative} negative, ${neutral} neutral`}
            componentsProps={{
                tooltip: {
                    sx: {
                        color: "black", 
                        backgroundColor: "var(--orange-mid)",
                        fontSize: "12px",
                        border: "1px solid rgba(0, 0, 0, 0.5)"
                    }
                }
            }}
            placement="left"
        >
            <div className="flex-row items-center text-sm text-gray-300">
                <MinusCircleSolid 
                    width={iconSize} 
                    height={iconSize} 
                />
            </div>
        </Tooltip>
    )

    if (net > 0) {
        return (
            <Tooltip 
                title={`${positive} positive, ${negative} negative, ${neutral} neutral`}
                componentsProps={{
                    tooltip: {
                        sx: {
                            color: "black", 
                            backgroundColor: "var(--orange-mid)",
                            fontSize: "12px",
                            border: "1px solid rgba(0, 0, 0, 0.5)"
                        }
                    }
                }}
                placement="left"
            >
                <div className="flex-row items-center text-sm text-green-500">
                    <ArrowUpCircleSolid width={iconSize} height={iconSize} />
                </div>
            </Tooltip>
        )
    }

    return (
        <Tooltip 
            title={`${positive} positive, ${negative} negative, ${neutral} neutral`}
            componentsProps={{
                tooltip: {
                    sx: {
                        color: "black", 
                        backgroundColor: "var(--orange-mid)",
                        fontSize: "12px",
                        border: "1px solid rgba(0, 0, 0, 0.5)"
                    }
                }
            }}
            placement="left"
        >
            <div className="flex-row items-center text-sm text-red-500">
                <ArrowDownCircleSolid width={iconSize} height={iconSize} />
            </div>
        </Tooltip>
    )

}

function PlaceHolderNewsList({ className, title, count, message = undefined }) {
    return (
        <details key={title}>
            <summary className={`list-item news-list ${className}`}>
                <div className="grid grid-cols-[1fr_auto] text-sm">
                    {title}
                    <span className="text-sm font-light">{count} News</span>
                </div>
            </summary>
            {
                message && (
                    <ul>
                        <li className="mx-4">
                            {message}
                        </li>
                    </ul>
                )
            }
        </details>
    )
}

function NewsList({ className, title, newsList }) {
    if (newsList === undefined || newsList.length === 0) return null;
    // Sort by rank
    newsList.sort((a, b) => {
        if (a.rank === b.rank && a.rank === -1) {
            return 0;
        } else if (a.rank === -1) {
            return 1;
        } else if (b.rank === -1) {
            return -1;
        }
        return a.rank > b.rank ? 1 : -1;
    });
    return (
        <details key={title}>
            <summary className={`list-item news-list ${className}`}>
                <div className="grid grid-cols-[1fr_auto] text-sm">
                    {title}
                    <span className="text-sm font-light">{newsList.length} News</span>
                </div>
            </summary>
            <ul>
                {
                    newsList.map(item => {
                        return (
                            <li className={`news-item ${item["duplicate"] ? "duplicate-news" : ""}`}>
                                <b className="text-sm">{item.title}</b>
                                <br />
                                <span className="source text-sm text-gray-600">🌐 <a href={item.url} target="_blank" rel="noopener noreferrer">{item["domain"]}</a></span>
                                <br />
                                {
                                    item["sentiment"]["reason"] ? (
                                        <Tooltip 
                                            title={item["sentiment"]["reason"]} 
                                            placement="right" 
                                            style={{cursor: "help"}} 
                                            componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                        color: "black", 
                                                        backgroundColor: "var(--orange-mid)",
                                                        fontSize: "12px"
                                                    }
                                                }
                                            }}
                                        >
                                            <span className="text-sm">Sentiment: {item["sentiment"]["direction"]} {item["sentiment"]["intensity"] !== undefined && item["sentiment"]["intensity"] !== null ? `- ${item["sentiment"]["intensity"]}` : ""}</span>
                                        </Tooltip>
                                    ) : (
                                        <span className="text-sm">Sentiment: {item["sentiment"]["direction"]} {item["sentiment"]["intensity"] !== undefined && item["sentiment"]["intensity"] !== null ? `- ${item["sentiment"]["intensity"]}` : ""}</span>
                                    )
                                }
                                <br />
                                <span className="text-sm text-start">Rank: {item["rank"] === -1 ? "None" : item["rank"]}</span>
                                <br />
                                {
                                    !(item["parsing_allowed"] === false || 
                                    (item["parsing_allowed"] !== false && 
                                    item["summary"] === null)) &&
                                    <br />
                                }
                                {
                                    item["parsing_allowed"] === false &&
                                    <>
                                        <div className="flex flex-row justify-start items-center gap-1">
                                            <WarningCircle className="text-red-500" width={18} height={18} />
                                            <span className="text-sm text-red-500">Unable to parse source</span>
                                        </div>
                                    </>
                                }
                                {
                                    item["parsing_allowed"] !== false && item["summary"] === null &&
                                    <>
                                        <div className="flex flex-row justify-start items-center gap-1">
                                            <InfoCircle className="text-blue-500" width={18} height={18} />
                                            <span className="text-sm text-blue-500">Snippet Only</span>
                                        </div>
                                    </>
                                }
                                {
                                    item["summary"] !== null && item["summary"] !== "" ? item["summary"] : item["snippet"]
                                }
                            </li>
                        );
                    })
                }
            </ul>
        </details>
    )
}

function ExpandButton({ expanded, onClick }) {
    return (
        <div onClick={onClick}>
            <Expand />
        </div>
    )
}

export { NewsLists, NewsListsDict };