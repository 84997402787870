import {
    CheckSquareSolid,
    Square,
    FastArrowUp,
    FastArrowDown,
} from "iconoir-react";
import { useContext, useState, useMemo, useEffect } from "react";
import { useFetchOverallQuestionFeedback } from "src/api/goodpoint/useFetchFeedback";
import Divider from "src/components/layout/Divider";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import QuestionFeedbackStructure from "src/pages/goodpoint/exam/tabs/grading/class-performance/question-feedback/QuestionFeedbackStructure";

export default function QuestionFeedback() {
    const { examObject, questionFeedback, setQuestionFeedback } =
        useContext(ExamContext);

    const fetchFeedback = useFetchOverallQuestionFeedback(examObject.id);

    const [isFilterOnlyMistakes, setIsFilterOnlyMistakes] = useState(false);
    const [orderBy, setOrderBy] = useState("question_number");
    const [isAscendingOrder, setIsAscendingOrder] = useState(true);

    useEffect(() => {
        // Update the context value with the memoized result when it changes
        if (questionFeedback) return;
        setQuestionFeedback(fetchFeedback());
    }, [fetchFeedback, questionFeedback, setQuestionFeedback]);

    const sortedQuestionFeedback = useMemo(() => {
        if (!questionFeedback) return null;
        return Object.values(questionFeedback).sort((feedback1, feedback2) => {
            var result =
                orderBy === "percentage"
                    ? feedback1["avg_mark"] / feedback1["max_marks"] -
                    feedback2["avg_mark"] / feedback2["max_marks"]
                    : feedback1[orderBy] - feedback2[orderBy];
            return isAscendingOrder ? result : -result;
        });
    }, [isAscendingOrder, orderBy, questionFeedback]);

    const hasMistakes = useMemo(() => {
        if (!questionFeedback) return;
        return Object.values(questionFeedback).some(
            (feedback) => feedback["marks"] < feedback["max_marks"]
        );
    }, [questionFeedback]);

    return (
        <div className="feedback-container">
            <div className="feedback-options">
                <div
                    className={`feedback-mistakes-filter flex-row align-center margin-small ${hasMistakes ? "" : "translucent"
                        }`}
                >
                    <div
                        className={`${hasMistakes ? "cursor-pointer" : "pointer-disabled"}`}
                        onClick={() =>
                            hasMistakes && setIsFilterOnlyMistakes(!isFilterOnlyMistakes)
                        }
                    >
                        {isFilterOnlyMistakes ? (
                            <CheckSquareSolid color="var(--zanista-orange-dark)" />
                        ) : (
                            <Square color="var(--zanista-orange-dark)" />
                        )}
                    </div>
                    <p className="margin-small">Show only mistakes</p>
                </div>
                <div className="feedback-order-by flex-row align-center margin-small">
                    <p>Order by:</p>
                    <select
                        className="order-by-select padding-x-small font-size-large margin-small"
                        value={orderBy}
                        onChange={(event) => setOrderBy(event.target.value)}
                    >
                        <option value="question_number">Question Number</option>
                        <option value="avg_mark">Marks</option>
                        <option value="percentage">Percentage</option>
                    </select>
                    <div
                        className="cursor-pointer"
                        onClick={() => setIsAscendingOrder(!isAscendingOrder)}
                    >
                        {isAscendingOrder ? (
                            <FastArrowUp color="var(--zanista-orange-dark)" />
                        ) : (
                            <FastArrowDown color="var(--zanista-orange-dark)" />
                        )}
                    </div>
                </div>
            </div>
            <Divider
                lineColour="rgba(0, 0, 0, 0.1)"
                shadowColour="rgba(0, 0, 0, 0.2)"
            />
            <QuestionFeedbackStructure
                questionFeedback={sortedQuestionFeedback}
                setQuestionFeedback={setQuestionFeedback}
                isFilterOnlyMistakes={isFilterOnlyMistakes}
            />
        </div>
    );
}
