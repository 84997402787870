import BackLink from 'src/components/nav/BackLink';
import { NewExam } from "src/pages/goodpoint/dashboard/ExamItem"
import Title from 'src/components/content/Title';
import { StepState } from 'src/api/goodpoint/Const';
import ExamMenu from 'src/pages/goodpoint/dashboard/ExamMenu';
import useExamsList from 'src/pages/goodpoint/dashboard/useExamsList';
import { ExamsListContext } from 'src/pages/goodpoint/dashboard/ExamsListContext';
import { useLayoutEffect, useState } from 'react';
import Loading from 'src/components/ui/Loading';
import ScrollToTopButton from 'src/components/nav/ScrollToTopButton';
import { useHasRole } from 'src/api/useGetRole';

export function AIGraderDashboard() {
    const { exams, setExams, queriedServer } = useExamsList();
    const isStudent = useHasRole("student");

    const [isLoading, setIsLoading] = useState(true);
    useLayoutEffect(() => {
        if (queriedServer || (exams && Object.keys(exams).length > 0)) {
            setIsLoading(false);
        }
    }, [exams, queriedServer]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Dashboard" href="/dashboard" />
                <Title title="GoodPoint" margin='0px 0px 10px 0px'/>
                {
                    isLoading
                        ? <Loading />
                        : <>
                            {!isStudent && <NewExam />}
                            {
                                isStudent && Object.keys(exams).length === 0 &&
                                <div className="flex-col gap-large justify-center h-16 mx-2">
                                    <p>No exams have been shared with this account</p>
                                </div>
                            }
                            <ExamsListContext.Provider value={{ exams, setExams }}>
                                {/* Show graded exams only to students */}
                                {!isStudent && <ExamMenu title="Ongoing Exams" filter={item => item.grading_step !== StepState.COMPLETED} />}
                                <ExamMenu title="Graded Exams" filter={item => item.grading_step === StepState.COMPLETED} />
                            </ExamsListContext.Provider>
                        </>
                }
            </div>
            <ScrollToTopButton />
        </div>
    );
}