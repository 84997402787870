import "./ProgressBar.css"

function ProgressBar({ progress, colour="zanistaOrangeMid" }) {
    const p = Math.round(progress * 100)
    return (
        <div className="progress-bar border-mid rounded-small">
            <div className={`progress-bar-done bg-${colour}`} style={{ width: `${p}%` }}></div>
            <p>{p}%</p>
        </div>
    )
}

export default ProgressBar