import { loadFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";

export function getAllStudentAnswers(examId) {
    return loadFromLocalStorage(StorageKeys.STUDENT_ANSWERS, {})[examId];
}

export function setAllStudentAnswers(examId, allStudentAnswers) {
    const store = loadFromLocalStorage(StorageKeys.STUDENT_ANSWERS, {});
    store[examId] = allStudentAnswers;
    return saveToLocalStorage(StorageKeys.STUDENT_ANSWERS, store);
}

export function getStudentAnswerById(examId, studentId) {
    const classAnswers = getAllStudentAnswers(examId) ?? {};
    return classAnswers[studentId];
}

export function saveStudentAnswerById(examId, studentId, studentAnswer) {
    const allStudentAnswers = getAllStudentAnswers(examId) ?? {};
    allStudentAnswers[studentId] = studentAnswer;
    setAllStudentAnswers(examId, allStudentAnswers);
}

export function getStudentList(examId) {
    return Object.keys(getAllStudentAnswers(examId));
}

export function updateStudentsList(examId, studentList) {
    const allStudentAnswers = getAllStudentAnswers(examId) ?? {};
    for (const studentId in allStudentAnswers) {
        if (studentList[studentId] === undefined) {
            delete allStudentAnswers[studentId];
        }
    }
    for (const studentId in studentList) {
        if (allStudentAnswers[studentId] === undefined) {
            allStudentAnswers[studentId] = {};
        }
    }
    setAllStudentAnswers(examId, allStudentAnswers);
    return studentList;
}