import "./Products.css"
//import ReactPlayer from 'react-player/youtube'
import { ContactPopup, Subject } from 'src/pages/home/components/ContactPopup';
import heroImg from 'src/assets/home/newswitch-hero.png';
import ProductHeroSection from 'src/pages/home/components/ProductHeroSection';
import { NavArrowRight } from "iconoir-react";
import ScrollAnimation from "react-animate-on-scroll";

const demoPlaceholder = "I would like to set up a short meeting to find out more about NewsWitch. Please reach out to me by email."

function NewsWitchProduct() {
    return (
        <div>
            <ProductHeroSection
                title="NewsWitch"
                subtitle="Detailed reports with news summaries for any number of tickers and timeframes"
                image={heroImg}
                CTASection={CTASection}
            />
            {
                // <HowItWorksSection />
            }
            <div className="pt-9">
                <TryNowSection />
            </div>
        </div>
    );
}

const CTASection = () => {
    const bigger = true;
    return (
        <div className="flex-row justify-start gap-4">
            <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                <button className={`clickable ${bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'} leading-snug bg-white hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`}>
                    Request a demo
                    <NavArrowRight className="inline-block" />
                </button>
            </ContactPopup>
        </div>
    )
}

// function HowItWorksSection() {
//     return (
//         <div className="flex-col justify-start items-start pb-4">
//             <div className="mx-auto w-full max-w-[800px] aspect-video rounded">
//                 <ReactPlayer 
//                     url="https://www.youtube.com/watch?v=TODO"
//                     width={"100%"}
//                     height={"100%"}
//                     controls
//                 />
//             </div>
//         </div>
//     );
// }



function TryNowSection() {
    const bigger = true;
    return (
        <div className={`relative min-h-screen flex overflow-clip px-5 sm:px-10 bg-primary-gradient rounded-3xl`}>
            <div className="max-w-screen-xl mx-auto flex flex-col justify-center items-center text-center ">
                <ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'>
                    <h1 className="afacad-flux-normal text-5xl">Try NewsWitch Now</h1>
                    <span className="text-gray-600 text-sm afacad-flux-normal">
                        Request a demo to see how NewsWitch works.
                    </span>
                </ScrollAnimation>
                <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                    <button className={`mt-5 clickable ${bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'} leading-snug bg-secondary hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`}>
                        Request a demo
                        <NavArrowRight className="inline-block" />
                    </button>
                </ContactPopup>
            </div>
        </div>
    );
}

export default NewsWitchProduct;