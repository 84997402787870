import "//unpkg.com/mathlive";
import { unTokenizeText } from "./Helpers";
import Latex from "react-latex-next";
export default function RenderMath({ tokens }) {
    if (!Array.isArray(tokens)) {
        return "";
    }

    const content = unTokenizeText(tokens);

    //if it contains tilde 
    return (
        <div>
            {content.includes("tilde") ? (
                tokens.map((token, index) => {
                    if (token.type === "latex") {
                        const isBlockLatex =
                            token.content.startsWith("$$") && token.content.endsWith("$$");

                        return (
                            <div
                                key={token.id}
                                style={{
                                    display: isBlockLatex ? "flex" : "inline-block", // Use flex for centering block latex
                                    justifyContent: isBlockLatex ? "center" : "unset", // Center horizontally if block latex
                                    alignItems: isBlockLatex ? "center" : "unset", // Center vertically if needed
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                <math-field
                                    read-only
                                    style={{
                                        display: isBlockLatex ? "block" : "inline-block",
                                        whiteSpace: "pre-wrap",
                                        textAlign: isBlockLatex ? "center" : "unset", // Center the block latex
                                        margin: isBlockLatex ? "0 auto" : "0",
                                    }}
                                >
                                    {token.content}
                                </math-field>
                            </div>
                        );
                    }
                    return (
                        <span
                            key={token.id}
                            style={{ whiteSpace: "pre-wrap" }}
                            className="z-10 focus:outline-none"
                        >
                            {token.content === "" ? <br /> : token.content}
                        </span>
                    );
                })
            ) : (
                <Latex>{content}</Latex>
            )}
        </div>
    );

}
