export default function NewTabLink({ className, to, children }) {
    return (
        <button 
            className={className}
            onClick={() => {
                window.open(to, "_blank", "noopener,noreferrer");
            }}
        >
            {children}
        </button>
    )
}