export const ExamStep = ({
    CONFIGURE_EXAM: "Configure exam",
    UPLOAD_RUBRIC: "Upload rubric",
    PROCESS_RUBRIC: "Process rubric",
    UPLOAD_STUDENT_ANSWERS: "Upload student answers",
    PROCESS_STUDENT_ANSWERS: "Process student answers",
    READY_TO_GRADE: "Ready to grade",
    GRADED: "Graded"
})

export const Tab = ({
    INFO: "info",
    RUBRIC: "rubric",
    ANSWERS: "student answers",
    GRADING: "grading"
});

export const TabState = ({
    COMPLETE: "complete",
    CURRENT: "current",
    NEXT: "next",
    INCOMPLETE: "incomplete"
})

export const StepState = ({
    NOT_AVAILABLE: 0,
    AVAILABLE: 1,
    READY: 2,
    PROCESSING: 3,
    COMPLETED: 4,
    FAILED: 5
})

export const StepError = ({
    INSUFFICIENT_CREDITS: "Insufficient Credits"
})

export const LogType = ({
    INFO: "info",
    ERROR: "error",
    SUCCESS: "success",
    FAIL: "fail",
    CLOSE: "close"
})

export const RubricType = ({
    ORIGINAL: "original",
    COMBINED: "combined",
    PROCESSED: "processed",
    ENHANCED: "enhanced"
})

export const GradingType = ({
    CLASS: "class",
    STUDENTS: "students"
})

export const GradingImages = ({
    QUANTILE_PLOT: "quantileplot",
    OVERALL_DIST: "exam_overalldist"
})
