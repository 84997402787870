/* eslint-disable */
import { useState, useContext, useMemo, useEffect } from 'react';
import { ExamContext } from 'src/pages/goodpoint/exam/ExamContext';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
    NavArrowLeft, NavArrowRight,
    Plus, Minus, ZoomIn,
    FastArrowLeft, FastArrowRight,
    AlignLeftBoxSolid, AlignRightBoxSolid,
    Xmark, DownloadSquare,
    Expand, Collapse, Link as LinkIcon, OpenNewWindow
} from 'iconoir-react';
import "./PdfViewer.css"
import { PdfViewerLoadingSkeleton, PdfViewerError } from './PdfViewerSkeleton';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import GoodPointApi from 'src/api/goodpoint/GoodPointApi';
import ScrollTrigger from 'react-scroll-trigger';
import EmbeddedPdf from 'src/components/content/EmbeddedPdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const Button = ({ disabled = null, onClick, children, className = '' }) => {
    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={`${!disabled ? 'hover:bg-gray-200 transition-all ease-in' : 'opacity-25'} px-1 ${className}`}
        >
            {children}
        </button>
    )
}

export default function PdfViewer({ 
    file, 
    fileName = "file", 
    showToolbar = true, 
    onClose, 
    height = "1200px",
    post = {} 
}) {
    const [showFullscreenPopup, setShowFullscreenPopup] = useState(false);
    const [pinned, setPinned] = useState(false);

    const OpenWindowButton = () => {
        const handleOpen = () => {
            const link = document.createElement("a");
            link.href = file;
            link.target = "_blank";  // Opens link in a new tab
            link.rel = "noopener noreferrer"; // Adds security for new tab
            link.download = "";  // Optional: set a custom file name here
            link.click();
        };

        return (
            <Button onClick={handleOpen} disabled={!file}>
                <OpenNewWindow />
            </Button>
        );

    }

    const ShareLinkButton = () => {
        const postId = post.id;
        return (
            <Link
                to={`/paperpal/${postId}`}
                target="_blank"
                rel="noopener noreferrer" // Security measure for new tab links
                style={{ textDecoration: 'none', color: 'inherit' }}
            >
                <Button onClick={() => { }}>
                    <LinkIcon />
                </Button>
            </Link>
        )
    }

    const pdf = useMemo(() => <EmbeddedPdf pdfUrl={file} width="100%" height="100%"/>, [file]);

    const ViewPdf = ({ expand = true }) => {
        return (
            <div className={`shadow-lg shadow-slate-400 border-t-2 border-t-slate-200 h-[${height}]`}>
                <div className="w-full bg-gray-100">
                    <OpenWindowButton />
                    {
                        post && post.id &&
                            <ShareLinkButton />
                    }
                </div>

                <div className=" overflow-auto h-[100%]">
                    <div style={{ width: '100%', height: '100%' }}>
                        {pdf}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {
                showFullscreenPopup ? (
                    <Popup
                        open={showFullscreenPopup}
                        closeOnDocumentClick={false}
                        onClose={() => setShowFullscreenPopup(false)}
                    >
                        <div className="w-screen h-screen bg-white">
                            <ViewPdf expand={false} />
                        </div>
                    </Popup>
                ) : (
                    <ViewPdf expand={true} />
                )
            }
        </div>
    );
}
