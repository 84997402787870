import { useMemo, useState, useContext, useEffect } from "react";
import Divider from "src/components/layout/Divider";
import { StructureItem } from "src/pages/goodpoint/exam/components/structure/StructureItem";
import StructureSection from "src/pages/goodpoint/exam/components/structure/StructureSection";
import { ExamContext } from "../../../ExamContext";
import { findNestedQuestionText } from "src/components/ui/Helpers";
import { getRubricById } from "src/storage/RubricStorage";


function AnswerHeader({ itemData, nestingLevel }) {
    const numParts = useMemo(() => {
        const subQuestions = itemData.sub_questions;
        return Object.keys(subQuestions ?? {}).length;
    }, [itemData.sub_questions]);

    return (
        <div className="answers-item-header fill-width">
            <p className="answers-item-question-tag justify-self-left">
                <b>
                    {nestingLevel > 0 ? "Part " : ""}
                    {itemData.name}
                </b>
            </p>
            {numParts > 0 && (
                <p className="answers-item-parts justify-self-right">
                    {numParts} Parts
                </p>
            )}
        </div>
    );
}

function AnswerItemContent({ itemData }) {
    const { examObject } = useContext(ExamContext);
    // const { selectedStudent, setStudents, students } = useContext(StudentsContext);
    
    let questionText = null;

    //To get the question text to display
    const rubricData = getRubricById(examObject.id);
    if (rubricData && rubricData.rubric) {
        const rubric = rubricData.rubric;
        questionText = findNestedQuestionText(itemData, rubric);
    }


    //need a setSolution to immediately show the change on the screen without collapsing the questions
    const [solution, setSolution] = useState(itemData["answer_content"]);

    //need this to ensure the content on the screen changes when u switch to another student
    useEffect(() => {
        setSolution(itemData["answer_content"]);
    }, [itemData]);

    //need this to sync it up with the overall students object, so when u switch back to the same student
    //the change is still there
    // useEffect(() => {
    //     if (!solution || !selectedStudent) {
    //         return;
    //     }
    //     const studentId = selectedStudent.student_id;
    //     const newValues = {
    //         student_solution: solution,
    //     };

    //     const updatedStudentAnswer = { ...selectedStudent["answer_content"] };
    //     // updateNestedStructure(updatedStudentAnswer, itemData.keys, newValues, "content");

    //     let newStudents = { ...students };
    //     newStudents[studentId]["answer_content"] = updatedStudentAnswer;

    //     setStudents(newStudents);
    //     setAllStudentAnswers(examObject.id, newStudents);

    // }, [examObject.id, selectedStudent, setStudents, solution, students]);


    return (
        <div className="student-answer-content">
            {questionText && (
                <StructureSection
                    heading={"Question"}
                    content={questionText}
                    children={undefined}
                    hide
                />
            )}
            {solution && (
                <StructureSection
                    heading={"Answer"}
                    content={solution}
                    setContent={setSolution}
                    children={undefined}
                />
            )}
            {!(solution || itemData["sub_items"].length > 0) && (
                <>
                    <p>(empty)</p>
                    <Divider
                        lineColour="rgba(0, 0, 0, 0.3)"
                        shadowColour="rgba(0, 0, 0, 0.20)"
                    />
                </>
            )}
        </div>
    );
}

export default function AnswerItem(props) {
    return (
        <StructureItem
            HeaderComponent={AnswerHeader}
            ContentComponent={AnswerItemContent}
            {...props}
        />
    );
}
