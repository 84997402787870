import "./StudentFeedback.css";
import { CheckSquareSolid, FastArrowDown, FastArrowUp, Square } from "iconoir-react";
import { useContext, useMemo, useState, useEffect } from "react";
import { useFetchStudentQuestionFeedback } from "src/api/goodpoint/useFetchFeedback";
import Divider from "src/components/layout/Divider";
import { addKeysToSubquestions } from "src/components/ui/Helpers";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";
import StudentFeedbackStructure from "src/pages/goodpoint/exam/tabs/grading/student-performance/student-feedback/StudentFeedbackStructure";


export default function StudentQuestionFeedback() {
    const { examObject } = useContext(ExamContext);
    const { selectedStudent, questionFeedback, setQuestionFeedback } = useContext(StudentsContext);

    const fetchFeedback = useFetchStudentQuestionFeedback(examObject.id, selectedStudent?.student_id);

    const [isFilterOnlyMistakes, setIsFilterOnlyMistakes] = useState(false);
    const [isFilterOnlyFlagged, setIsFilterOnlyFlagged] = useState(false);
    const [orderBy, setOrderBy] = useState("question_number");
    const [isAscendingOrder, setIsAscendingOrder] = useState(true);

    useEffect(() => {
        if (!selectedStudent) return;
        let result = fetchFeedback();

        // TODO: this should be done on the backend eventually
        if (result) {
            result = addKeysToSubquestions(result);
        }
        setQuestionFeedback(result);

    }, [selectedStudent, fetchFeedback, setQuestionFeedback]);

    const sortedQuestionFeedback = useMemo(() => {
        if (!questionFeedback) return null;
        return Object
            .values(questionFeedback)
            .sort((feedback1, feedback2) => {
                var result = orderBy === "percentage"
                    ? (feedback1["marks"] / feedback1["max_marks"]) - (feedback2["marks"] / feedback2["max_marks"])
                    : feedback1[orderBy] - feedback2[orderBy];
                return isAscendingOrder ? result : -result;
            });
    }, [isAscendingOrder, orderBy, questionFeedback]);

    const hasFlagged = useMemo(() => {
        if (!questionFeedback) return;
        return Object.values(questionFeedback).some((feedback) => feedback.flagged);
    }, [questionFeedback]);

    const hasMistakes = useMemo(() => {
        if (!questionFeedback) return;
        return Object.values(questionFeedback).some((feedback) => feedback["marks"] < feedback["max_marks"]);
    }, [questionFeedback])


    return <div className="feedback-container">
        <div className="feedback-options">
            <div className={`feedback-mistakes-filter flex-row align-center margin-small ${hasMistakes ? "" : "translucent"}`}>
                <div
                    className={`${hasMistakes ? "cursor-pointer" : "pointer-disabled"}`}
                    onClick={() => hasMistakes && setIsFilterOnlyMistakes(!isFilterOnlyMistakes)}
                >
                    {
                        isFilterOnlyMistakes ? <CheckSquareSolid color="var(--zanista-orange-dark)" /> : <Square color="var(--zanista-orange-dark)" />
                    }
                </div>
                <p className="margin-small">Show only mistakes</p>
            </div>
            <div className={`feedback-flagged-filter flex-row align-center margin-small ${hasFlagged ? "" : "translucent"}`}>
                <div
                    className={`${hasFlagged ? "cursor-pointer" : "pointer-disabled"}`}
                    onClick={() => hasFlagged && setIsFilterOnlyFlagged(!isFilterOnlyFlagged)}
                >
                    {
                        isFilterOnlyFlagged ? <CheckSquareSolid color="var(--zanista-orange-dark)" /> : <Square color="var(--zanista-orange-dark)" />
                    }
                </div>
                <div className="margin-small flex-row">
                    <p className="margin-x-small">Show only feedback needing review</p>
                    {/* <sup><QuestionMark color="var(--zanista-orange-dark)" className="cursor-pointer" width={20}/></sup> */}
                </div>
            </div>
            <div className="feedback-order-by flex-row align-center margin-small">
                <p>Order by:</p>
                <select className="order-by-select padding-x-small font-size-large margin-small" value={orderBy} onChange={(event) => setOrderBy(event.target.value)}>
                    <option value="question_number">Question Number</option>
                    <option value="marks">Marks</option>
                    <option value="percentage">Percentage</option>
                </select>
                <div className="cursor-pointer" onClick={() => setIsAscendingOrder(!isAscendingOrder)}>
                    {
                        isAscendingOrder ? <FastArrowUp color="var(--zanista-orange-dark)" /> : <FastArrowDown color="var(--zanista-orange-dark)" />
                    }
                </div>
            </div>
        </div>
        <Divider lineColour='rgba(0, 0, 0, 0.1)' shadowColour='rgba(0, 0, 0, 0.2)' />
        <StudentFeedbackStructure
            studentFeedback={sortedQuestionFeedback}
            isFilterOnlyFlagged={isFilterOnlyFlagged}
            isFilterOnlyMistakes={isFilterOnlyMistakes}
        />
    </div>
}