import { loadFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";

export function loadCurrentTabs() {
    return loadFromLocalStorage(StorageKeys.ASSESSMENT_TABS, {});
}

export function loadCurrentTabById(examId) {
    return loadCurrentTabs()[examId];
}

export function setCurrentTabById(examId, currentTab) {
    const newCurrentTabs = loadCurrentTabs();
    newCurrentTabs[examId] = currentTab;
    return saveToLocalStorage(StorageKeys.ASSESSMENT_TABS, newCurrentTabs);
}