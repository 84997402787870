import { replacer, reviver } from "src/util/Json";
const { ENABLE_LOCAL_STORAGE } = process.env;

const CURRENT_VERSION = "3.0";

function checkDataVersion() {
    const version = localStorage.getItem("data-version");
    if (version !== CURRENT_VERSION) {
        localStorage.setItem("data-version", CURRENT_VERSION);
        return false;
    }
    return true;
}

export const StorageKeys = {
    ASSESSMENTS: "exam-descs",
    ASSESSMENT_OBJECTS: "exam-objects",
    ASSESSMENT_TABS: "exam-tabs",
    RUBRICS: "rubrics",
    STUDENT_ANSWERS: "student-answers",
    STUDENT_LIST: "students-list",
    GRADING_RESULTS: "grading-results",
};

export function loadFromLocalStorage(storageKey, defaultValue = null) {
    if (!ENABLE_LOCAL_STORAGE) {
        return defaultValue;
    }
    if (checkDataVersion()) {
        const value = localStorage.getItem(storageKey);
        return value ? JSON.parse(value, reviver) : defaultValue;
    } else {
        return defaultValue;
    }
}

export function saveToLocalStorage(storageKey, value) {
    if (!ENABLE_LOCAL_STORAGE) {
        return value;
    }
    if (checkDataVersion()) {
        try {
            localStorage.setItem(storageKey, JSON.stringify(value, replacer));
            return value;
        } catch (error) {
            return;
        }
    } else {
        return value;
    }
}
