import { createContext } from "react";

//EditToolbar
export const EditMode = {
    DEFAULT: "default",
    REORDER: "reorder",
    DELETE: "delete",
};

export const ExamContext = createContext({
    examObject: null,
    updateExamObject: (_a) => { },
    examState: null,
    updateExamState: (_s) => { },
    footerControls: null,
    setFooterControls: (_fc) => { },

    // EditToolbar
    editMode: EditMode.DEFAULT,
    setEditMode: (_e) => { },

    // delete checkboxes
    deleteSelected: null,
    setDeleteSelected: (_ds) => { },

    // question feedback
    questionFeedback: null,
    setQuestionFeedback: (_q) => { },

    // student feedback
    studentFeedback: null,
    setStudentFeedback: (_sf) => { },

    // class feedback
    classFeedback: null,
    setClassFeedback: (_cs) => { },

    // pdf preview
    showPDF: null,
    setShowPDF: (_df) => { },
    rightPDF: null,
    setRightPDF: (_df) => { },
    file: null,
    setFile: (_f) => { },

    // current Tab
    currentTab: null,
});
