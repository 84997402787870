import './ZanistaHeader.css'
import logo from '../../assets/logo.png'
import { ProfileCircle, Xmark } from 'iconoir-react';
import { Link, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import useCallApi from 'src/api/useCallApi';
import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { capitalize } from 'src/util/StringUtil';
import GoodPointApi from 'src/api/goodpoint/GoodPointApi';
import { ComponentGuard } from 'src/api/RouteGuard';
import { protectedResources } from 'src/auth/AuthConfig';


export default function ZanistaHeader() {
    const { instance } = useMsal();
    const callApi = useCallApi();

    const [userInfo, setUserInfo] = useState(null);
    const location = useLocation();

    useEffect(() => {
        callApi("GET", GoodPointApi.User)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    // Get everything from local storage and update exams list
                    setUserInfo(body["data"]);
                });
            } else if (response.status === 401) {
                // User does not have access to the API
                console.error("User does not have currently have access: " + response.status + " " + response.statusText);
                setUserInfo({
                    first_name: "",
                    last_name: "",
                    job_title: "",
                    email: "",
                    role: "user",
                    access: false,
                    credits: 0,
                });
            } else {
                console.error("Failed to fetch user info: " + response.status + " " + response.statusText);
            }
        });
    }, [callApi]);

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    const handleDelete = () => {
        if (!instance.getActiveAccount() || !instance.getActiveAccount().idTokenClaims['extension_UserID']) {
            return;
        }
        if (!window.confirm("Are you sure you want to delete your account?")) {
            return;
        }
        callApi("DELETE", protectedResources.apiManagement.endpoint + "/user", {
            body: {
                user_id: instance.getActiveAccount().idTokenClaims['extension_UserID']
            }
        }).then(response => {
            if (response.status === 200) {
                handleLogout();
            } else {
                console.error("Failed to delete user: " + response.status + " " + response.statusText);
            }
        });
    }

    return (
        <div className="centered">
            <header className="zanista-header" id="zanista-header">
                <div className="header-container">
                    <div className="flex-row align-center gap-mid">
                        <Link to="/">
                            <img src={logo} alt="Zanista AI" />
                        </Link>
                        {
                            location.pathname !== "/dashboard" &&
                            <Link
                                className="text-xs mx-1 hover:text-orange-600 hidden sm:inline cursor-pointer"
                                to="/dashboard"
                            >
                                Dashboard
                            </Link>
                        }
                    </div>
                    <nav>
                        <ul>
                            <li>
                                <ComponentGuard roles={["admin"]} >
                                    {
                                        userInfo &&
                                        <p className='text-xs mr-2'>{userInfo.first_name} {userInfo.last_name}</p>
                                    }
                                </ComponentGuard>
                            </li>
                            <li>
                                <ComponentGuard roles={["admin"]} >
                                    <button className="text-xs h-min p-1 mt-1 mr-4 hidden md:inline rounded outline outline-1 bg-[var(--orange-mid)]">
                                        <Link to="/admin" className='text-xs'>
                                            Admin Portal
                                        </Link>
                                    </button>
                                </ComponentGuard>
                                <Popup position="bottom right" trigger={
                                    <div className="cursor-pointer">
                                        <ProfileCircle
                                            height={32}
                                            width={32} />
                                    </div>
                                } >
                                    {closeUserInfo => (
                                        <div className='user-info-modal'>
                                            <div className='user-info-title'>
                                                <p>User Info</p>
                                                <div onClick={() => closeUserInfo()} className="cursor-pointer">
                                                    <Xmark />
                                                </div>
                                            </div>
                                            {!userInfo ?
                                                <div>
                                                    <p>Fetching user data...</p>
                                                </div>
                                                :
                                                <div>
                                                    <p>{capitalize(userInfo.first_name) + " " + capitalize(userInfo.last_name)}</p>
                                                    {
                                                        userInfo.email &&
                                                        <p>{userInfo.email}</p>
                                                    }
                                                    <ComponentGuard roles={["admin"]} >
                                                        <p>{"Job Title: " + capitalize(userInfo.job_title)}</p>
                                                        <p>{"Role: " + capitalize(userInfo.role)}</p>
                                                        <p>{"Access: " + capitalize(userInfo.access.toString())}</p>
                                                    </ComponentGuard>
                                                    <p>{"Credits: " + capitalize(userInfo.credits.toString())}</p>

                                                </div>
                                            }
                                            <div className='flex-col gap-1'>
                                                <div className='flex-row gap-4'>
                                                    <button onClick={() => { closeUserInfo(); handleLogout(); }}>
                                                        Logout
                                                    </button>
                                                    {userInfo &&
                                                        <button onClick={closeUserInfo}>
                                                            <Link to="/checkout" >
                                                                Buy Credits
                                                            </Link>
                                                        </button>
                                                    }
                                                </div>
                                                {userInfo &&
                                                    <button onClick={handleDelete} className='w-fit'>
                                                        Delete Account
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        </div>
    );
}