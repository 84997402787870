import { useCallback } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";

export default function useSendInfo(examId) {
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    });

    const sendInfo = useCallback((configUpdates) => {
        const postBody = { "info": configUpdates };
        return callApi("POST", GoodPointApi.Exam.Info(examId), { body: postBody })
            ?.then(response => {
                if (response.status !== 200) {
                    console.error(`Failed to post info: (${response.status}) ${response.statusText}`);
                }
            });
    }, [callApi, examId]);

    return sendInfo;
}