import LoadingTab from "src/components/ui/LoadingTab";
// import axios from "axios";
import { useMemo } from "react";
// import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { ExamStructure } from "src/pages/goodpoint/exam/components/structure/ExamStructure";
import StudentFeedbackItem from "src/pages/goodpoint/exam/tabs/grading/student-performance/student-feedback/StudentFeedbackItem";
// import useSWR from "swr";
// import { StudentsContext } from "../../../answers/StudentsContext";
// import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


export default function StudentFeedbackStructure({
    studentFeedback,
    isFilterOnlyMistakes = false,
    isFilterOnlyFlagged = false
}) {
    // const {selectedStudent} = useContext(StudentsContext);
    // const {examObject} = useContext(ExamContext);

    // const endpoint = GoodPointApi.Answers.File(examObject.id, selectedStudent.student_id)

    // const fetcher = url => axios.get(url).then(res => res.data);
    // const { data, isLoading, error } = useSWR(endpoint, fetcher, {
    //     revalidateOnFocus: false,
    //     revalidateOnReconnect: false,
    //     refreshInterval: 172000 
    // });

    const numAnswers = useMemo(() => {
        return Object.keys(studentFeedback ?? {}).length;
    }, [studentFeedback]);

    function Header() {
        return (
            <div className="flex-row align-center gap-large">
                <p>{numAnswers} Question{(numAnswers > 1) ? "s" : ""} Answered</p>
            </div>
        )
    }

    if (!studentFeedback) {
        return <LoadingTab />
    }

    return (
        <ExamStructure
            structureMap={studentFeedback}
            HeaderComponent={Header}
            ItemComponent={StudentFeedbackItem}
            initiallyExpanded={true}
            itemCommonProps={{ isFilterOnlyMistakes, isFilterOnlyFlagged }}
            listDisplay={[0, 0, 1, 1, 1, 1]}
        // fileUrl={isLoading || error ? "" : data.answerUrl}
        />
    )
}