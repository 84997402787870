import Plot from "react-plotly.js";
import { useState, useEffect, useMemo } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

const SentimentChart = ({ ticker, eventDate = new Date() }) => {
    let mobileMode = false;
    if (window.innerWidth < 768) {
        mobileMode = true;
    }

    const callAPI = useNewsWitchApi();
    const [sentimentData, setSentimentData] = useState([]);
    const [netSentimentData, setNetSentimentData] = useState([]);
    const [dailySentimentData, setDailySentimentData] = useState([]);
    const [largestSentiment, setLargestSentiment] = useState(5);
    const [xData, setXData] = useState([]);
    const [volumeData, setVolumeData] = useState([]);
    const [largestVolume, setLargestVolume] = useState(100);
    const alpha = 0.75;

    // const handleAlphaChange = (event) => {
    //     setAlpha(Number(event.target.value));
    // };

    const daysBefore = 31;
    const startDate = useMemo(() => new Date(eventDate.getTime() - (daysBefore * 24 * 60 * 60 * 1000)), [eventDate]);
    const endDate = useMemo(() => eventDate, [eventDate]);

    const startDateStr = startDate.toISOString().split('T')[0];
    const endDateStr = endDate.toISOString().split('T')[0];

    useEffect(() => {
        callAPI("GET", `/sentiment?ticker=${ticker}&start_date=${startDateStr}&end_date=${endDateStr}`)?.then(response => {
            if (response.status === 200) {
                response.json().then(body => {
                    // @ts-ignore
                    const sentiments = body["sentiment"].sort((a, b) => new Date(a["date"]) - new Date(b["date"]));
                    setSentimentData(Array.from(
                        { length: Math.ceil((endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)) },
                        (_, i) => new Date(startDate.getTime() + i * (24 * 60 * 60 * 1000)).toISOString().split('T')[0]
                    ).map(date => {
                        const sentiment = sentiments.find(s => s["date"] === date);
                        if (sentiment) {
                            return sentiment;
                        } else {
                            return { 
                                date: date,
                                net_sentiment: null, 
                                basic: { positive: 0, negative: 0 } 
                            };
                        }
                    }));
                });
            }
        });
    }, [ticker, startDateStr, endDateStr, callAPI, startDate, endDate]);

    useEffect(() => {
        const newNetSentimentData = []
        const newDailySentimentData = []
        const newXData = []
        const newVolumeData = []

        let prevNetSentiment = null;
        // Apply exponential smoothing using alpha
        sentimentData.forEach(item => {
            const dailySentiment = item["basic"]["positive"] - item["basic"]["negative"];
            if (prevNetSentiment === null) {
                prevNetSentiment = item["net_sentiment"] ? item["net_sentiment"] : 0;
                newNetSentimentData.push(item["net_sentiment"] ? item["net_sentiment"] : 0);
            } else {
                const smoothedNetSentiment = alpha * dailySentiment + (1 - alpha) * prevNetSentiment;
                newNetSentimentData.push(smoothedNetSentiment);
                prevNetSentiment = smoothedNetSentiment;
            }
            newDailySentimentData.push(dailySentiment);
            newXData.push(item["date"]);
            newVolumeData.push(item["news_count"] || 0);
        });

        const minSentiment = Math.min(...newDailySentimentData, -5);
        const maxSentiment = Math.max(...newDailySentimentData, 5);
        const largestSentiment = Math.max(Math.abs(minSentiment), Math.abs(maxSentiment));
        const largestVolume = Math.max(...newVolumeData);

        setXData(newXData);
        setNetSentimentData(newNetSentimentData);
        setDailySentimentData(newDailySentimentData);
        setLargestSentiment(largestSentiment);
        setVolumeData(newVolumeData);
        setLargestVolume(largestVolume);
    }, [sentimentData, alpha]);

    return (
    <Plot className="w-full my-4"
        data={[
            {
                x: xData,
                y: netSentimentData,
                text: netSentimentData.map(y => y.toFixed(2)),
                type: 'scatter',
                mode: 'lines',
                line: { 
                    color: 'rgba(0, 0, 255, 0.6)',
                    width: 3,
                    shape: 'spline',
                    smoothing: 0.75,
                },
                marker: {
                    size: 8,
                    color: 'rgba(0, 0, 255, 0.8)',
                },
                hoverinfo: 'x+text',
                name: "Net Sentiment",
                showlegend: true,
                yaxis: 'y1',
            },
            {   // Sentiment data
                x: xData,
                y: dailySentimentData,
                text: dailySentimentData.map(y => {
                    if (y >= 0) {
                        return `+${y.toFixed(0)}`;
                    } else {
                        return y.toFixed(0);
                    }
                }),
                textfont: {
                    // invisible
                    color: 'rgba(0, 0, 0, 0)',
                },
                type: 'bar',
                yaxis: 'y2',
                name: 'Daily Sentiment',
                marker: { 
                    color: dailySentimentData.map((val, index) => val < 0 ? 'rgba(255, 0, 0, 0.4)' : 'rgba(0, 204, 102, 0.4)'),
                    line: {
                        width: 1.4,
                        color: 'rgba(255, 255, 255, 1)',
                    },
                },
                showlegend: true,
                hoverinfo: 'x+text',
                offset: 0.05,
            },
            {   // Volume data
                x: xData,
                y: volumeData,
                text: volumeData.map(y => `Total News: ${y}`),
                textfont: {
                    // invisible
                    color: 'rgba(0, 0, 0, 0)',
                },
                type: 'bar',
                yaxis: 'y3',
                name: 'News Volume',
                marker: { 
                    color: 'rgba(140, 140, 140, 1)',
                    line: {
                        width: 1.4,
                        color: 'rgba(255, 255, 255, 1)',
                    }
                },
                showlegend: true,
                hoverinfo: 'x+text',
                offset: 0.05,
            },
        ]}
        layout={{
        autosize: true,
        margin: { 
            t: 10,
            b: 80, 
            l: mobileMode ? 20 : 80, 
            r: mobileMode ? 20 : 80
        },
        barmode: 'relative',
        bargap: 0.1,
        hovermode: 'closest',
        xaxis: {
            tickvals: xData,
            ticks: 'outside',
            tickfont: {
                size: 16,
            },
            tickangle: 45,
            range: [startDate.getTime(), endDate.getTime()],
            showgrid: true,
            gridcolor: 'gray',
            showline: true,
            linecolor: 'gray',
            mirror: true,
            gridwidth: 0.5,
            zeroline: false,
        },
        yaxis: {
            title: {
                text: 'Daily Sentiment',
                font: {
                    size: 18,
                }
            },
            range: [largestSentiment * -1.2, largestSentiment * 1.2],
            side: 'left',
            showgrid: true,
            gridcolor: 'gray',
            showline: true,
            linecolor: 'gray',
            mirror: true,
            gridwidth: 0.5,
            overlaying: 'y2',
            showticklabels: true,
        },
        yaxis2: {
            title: {
                text: 'Net Sentiment',
                font: {
                    size: 18,
                }
            },
            side: 'right',
            range: [largestSentiment * -1.2, largestSentiment * 1.2],
            showgrid: false,
            overlaying: 'free',
        },
        yaxis3: {
            side: 'right',
            range: [0, largestVolume * 10],
            showgrid: false,
            overlaying: 'y2',
            showticklabels: false,
            tickvals: [0, largestVolume],
        },
        shapes: [
            {   // Horizon line
                type: 'line',
                x0: 0,
                x1: 1,
                xref: 'paper',
                y0: 0,
                y1: 0,
                line: {
                    color: 'blue',
                    width: 3,
                    dash: 'dash',
                },
            },
        ],
        showlegend: true,
        legend: {
            title: {
                text: `${ticker} (Event Date: ${eventDate.toISOString().slice(0, 10)})`,
                font: {size: 12},
                side: 'top',
            },
            x: 0.01,
            y: 0.99,
            xanchor: 'left',
            yanchor: 'top',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            font: {size: 12},
            itemclick: false,
            orientation: 'v',
            borderwidth: 1,
        },
        }}
        config={{
            responsive: true,
            scrollZoom: false,
            displayModeBar: false,
        }}
        />
    );
};

export default SentimentChart;