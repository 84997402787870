// Replace Map instances with a datatype and the Array form for JSON.stringify
function replacer(_key, value) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()),
        };
    } else {
        return value;
    }
}

// Get Maps back from JSON using JSON.parse
function reviver(_key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}

export { replacer, reviver }