import { loadFromLocalStorage, saveToLocalStorage, StorageKeys } from "src/storage/LocalStorage";
// import GoodPointApi from "src/api/goodpoint/GoodPointApi";

export function loadRubrics() {
    return loadFromLocalStorage(StorageKeys.RUBRICS, {});
}

export function setRubrics(rubrics) {
    return saveToLocalStorage(StorageKeys.RUBRICS, rubrics);
}

export function getRubricById(examId, enhanced = false) {
    const rubrics = loadRubrics();
    const rubricData = rubrics[examId];
    if (!rubricData) return undefined;

    return {
        rubric: rubricData[enhanced ? "enhanced" : "original"],
        rubricUrl: rubricData["rubricUrl"]
    };
}


export function saveRubric(examId, data) {
    const currentTime = new Date();
    const expiryTime = new Date(currentTime.getTime() + 48 * 60 * 60 * 1000);

    const newRubrics = loadRubrics();
    if (!data) {
        return;
    }
    //race conditions ate my ass 

    if (!newRubrics[examId]) {
        newRubrics[examId] = {};
    }

    if (data.rubric) {
        newRubrics[examId] = data.rubric;
    }

    if (data.rubricUrl) {
        newRubrics[examId]["rubricUrl"] = data.rubricUrl;
        newRubrics[examId]["expiryTime"] = expiryTime.getTime();
    }

    return setRubrics(newRubrics);
}

