import Popup from "reactjs-popup";
import "./AreYouSureModal.css"
import { WarningTriangleSolid } from "iconoir-react";
import { useState } from "react";
import { TailSpin } from "react-loader-spinner";


export default function AreYouSureModal({
    popupOpenHook,
    title,
    content,
    onClosePopup,
    onConfirm = undefined,
    onCancel = undefined,
    danger = false,
    confirmContent = "Confirm",
    cancelContent = "Cancel"
}) {
    const [popupOpen, setPopupOpen] = popupOpenHook;
    const [confirmClicked, setConfirmClicked] = useState(false);
    return (
        <Popup
            className="popup-content"
            open={popupOpen}
            closeOnDocumentClick={false}
            onClose={() => {setConfirmClicked(false); onClosePopup();}}
        >
            <div className="bg-white rounded-md shadow-lg p-10">
                <div className="px-2 py-2 text-2xl font-semibold border-b-2 border-gray-400">
                    {danger && (
                        <WarningTriangleSolid className="text-yellow-500 text-2xl inline-block mb-2 mr-2" />
                    )}
                    {title}
                </div>
                <div className="py-5 text-lg">{content}</div>

                <div className="flex-row gap-large mt-5 justify-between">
                    <button
                        className="clickable bg-orange-50 border-thin rounded-small py-1 text-lg"
                        onClick={() => {
                            onConfirm && onConfirm();
                            setConfirmClicked(true);
                        }}
                    >
                        {
                            confirmClicked
                                ? <TailSpin strokeWidth={3.0} color="black" height={20} wrapperClass="padding-small" />
                                : confirmContent
                        }
                    </button>

                    <button
                        className="clickable bg-orange-mid border-thin rounded-small py-1 text-lg"
                        onClick={() => {
                            setConfirmClicked(false);
                            if (onCancel === undefined) {
                                setPopupOpen(false);
                            } else {
                                onCancel();
                            }
                        }}
                    >
                        {cancelContent}
                    </button>
                </div>
            </div>
        </Popup>
    );
}
