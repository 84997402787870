import { useContext } from "react";
import { ExamStructure } from "src/pages/goodpoint/exam/components/structure/ExamStructure";
import "./RubricStructure.css";
import { RubricItem } from "src/pages/goodpoint/exam/tabs/rubric/structure/RubricItem";
import { saveRubric } from "src/storage/RubricStorage";
import LoadingTab from "src/components/ui/LoadingTab";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";


export default function RubricStructure({ rubric, enhanced = false }) {
    const { setRubric, rubricUrl } = useContext(RubricContext);

    function RubricHeader({ structureMap }) {
        const numQuestions = structureMap.length;
        const totalMarks = structureMap.reduce((acc, q) => q.marks ? acc + q.marks : acc, 0);

        return <div className="flex-row align-center gap-large">
            <p>{numQuestions} Question{(numQuestions > 1) ? "s" : ""}</p>
            <p>Total Marks: <b>{totalMarks}</b></p>
        </div>
    }

    if (!rubric) {
        return <LoadingTab />;
    }

    return (
        <div className="flex-col gap-mid">
            <ExamStructure
                structureMap={rubric}
                setStructure={setRubric}
                saveStructure={saveRubric}
                HeaderComponent={RubricHeader}
                ItemComponent={RubricItem}
                itemCommonProps={{ enhanced }}
                fileUrl={rubricUrl}
                listDisplay={[1, 1, 1, 1, 1, 1]}
            />
        </div>
    );
}
