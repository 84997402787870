import { useContext } from "react";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import ProcessingWaitPage from "src/pages/goodpoint/exam/components/ProcessingWaitPage";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


export default function AnswersProcessing() {
    const { examObject } = useContext(ExamContext);
    return <ProcessingWaitPage
        logsEndpoint={GoodPointApi.Answers.Logs(examObject.id)}
        stateName={"answers"}
        text="Processing student answers..."
    />
}