import "./Products.css"
import goodpointPipelineImg from 'src/assets/home/goodpoint-pipeline.jpg'
import ReactPlayer from 'react-player/youtube'
import { ContactPopup, Subject } from 'src/pages/home/components/ContactPopup';
import { Link } from 'react-router-dom';
import heroImg from 'src/assets/home/goodpoint-screenshots/question-feedback.png';
import ProductHeroSection from 'src/pages/home/components/ProductHeroSection';
import { Cpu, DashboardSpeed, FontQuestion, Group, NavArrowRight, OpenBook, Sparks, TestTube, Timer } from "iconoir-react";
import Feature from "src/pages/home/components/Feature";
import ScrollAnimation from "react-animate-on-scroll";

const demoPlaceholder = "I would like to set up a short meeting to find out more about GoodPoint. Please reach out to me by email."

function GoodPointProduct() {
    return (
        <div className="">
            <ProductHeroSection
                title="GoodPoint"
                subtitle="Automated grading with human-level precision and feedback"
                image={heroImg}
                CTASection={CTASection}
            />
            <FeaturesSection />
            <HowItWorksSection />
            <TryNowSection />
        </div>
    );
}


function FeaturesSection() {
    return (
        <div className="bg-transparent md:py-12 py-8 md:px-8 px-0">
            <div className="container mx-auto">
                <div className="grid md:grid-cols-2 grid-cols-1 gap-x-4 md:gap-y-16 gap-y-8">
                    <Feature
                        icon={<Group size={32} />}
                        title={"Personalised feedback for every student"}
                        description={"Question by question feedback gives students the necessary resources to learn from their mistakes."}
                        gray
                    />
                    <Feature
                        icon={<Timer size={32} />}
                        title={"More time for educators"}
                        description={"GoodPoint allows educators to focus more on teaching and less on marking."}
                    />
                    <Feature
                        icon={<TestTube size={32} />}
                        title={"Pilot programs and partnerships"}
                        description={"We are currently engaged in pilot programs with educators from all over the world to improve our product."}

                    />
                    <Feature
                        icon={<DashboardSpeed size={32} />}
                        title={"Fast marking with AI"}
                        description={"No more waiting weeks for results, only to have forgotten the content."}
                        gray
                    />
                </div>
            </div>
        </div>
    );
}

function HowItWorksSection() {
    return (
        <div className="flex-col justify-center items-center py-4 px-4 text-center w-full">
            <ScrollAnimation
                animateIn='fadeIn'
                animateOut='fadeOut'
            >
                <div>
                    <h2 className="py-2 font-bold text-3xl afacad-flux-normal">
                        How GoodPoint Works
                    </h2>
                    <p className="font-bold text-sm pb-1 afacad-flux-normal text-accent">
                        Not just another GPT-wrapper.
                    </p>
                    <p className="text-gray-600 text-sm afacad-flux-normal">
                        GoodPoint is capable of evaluating STEM subjects for pre-university, undergraduate and postgraduate courses using a robust pipeline.
                    </p>
                </div>
            </ScrollAnimation>
            <div className="grid grid-cols-2 gap-6 py-8">
                <Feature
                    icon={<Cpu size={24} />}
                    title="Pre-processing"
                    description="Transformations are applied to the raw scan of the exam paper."
                    gray
                />
                <Feature
                    icon={<FontQuestion size={24} />}
                    title="OCR Extraction"
                    description="Our sophisticated OCR pipeline extracts the paper content page by page."
                />
                <Feature
                    icon={<Sparks size={24} />}
                    title="Rubric Enhancement"
                    description="The rubric provided is enhanced with our own solver and the student answers are evaluated based on this rubric."

                />
                <Feature
                    icon={<OpenBook size={24} />}
                    title="Marking and Feedback"
                    description="The final result is an automatically graded exam with customized feedback for the students."
                    gray
                />
            </div>
            <ScrollAnimation
                animateIn='fadeIn'
                animateOut='fadeOut'
            >
                <div className='py-6'>
                    <img src={goodpointPipelineImg} alt="GoodPoint Pipeline" className="w-full" />
                </div>
            </ScrollAnimation>

            <div className="mx-auto mt-2 w-full max-w-[800px] aspect-video rounded">
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=TlNLRbWxSB4"
                    width={"100%"}
                    height={"100%"}
                    controls
                />
            </div>
        </div>
    );
}


export function CTASection({ className = "", bigger }) {
    bigger = true;
    return (
        <div className={`flex-row justify-start gap-4 mt-5 ${className} animate-fadeIn`}>
            <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                <button className={`clickable ${bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'} leading-snug bg-white hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`}>
                    Request a demo
                    <NavArrowRight className="inline-block" />
                </button>
            </ContactPopup>
            <Link to="/dashboard">
                <button className={`clickable ${bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'} leading-snug bg-secondary hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`}>
                    Sign Up
                </button>
            </Link>
        </div>
    );
}

function TryNowSection() {
    return (
        <div className={`relative min-h-screen flex overflow-clip px-5 sm:px-10 bg-primary-gradient rounded-3xl`}>
            <div className="max-w-screen-xl mx-auto flex flex-col justify-center items-center ">
                <ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'>
                    <h1 className="afacad-flux-normal text-6xl">Try
                        <span className=" text-accent afacad-flux-normal text-6xl">Goodpoint </span>now.</h1>
                    <span className="text-gray-600 text-sm afacad-flux-normal">
                        Request a demo or sign up and be notified when GoodPoint is available.
                    </span>
                </ScrollAnimation>
                <CTASection className="justify-center" bigger />
            </div>
        </div>
    );
}

export default GoodPointProduct;