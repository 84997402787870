import { useContext } from "react";
import { StepState } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import FileUploader from "src/components/ui/FileUploader";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


export default function RubricAvailable() {
    const { examObject, updateExamState } = useContext(ExamContext);
    return (
        <FileUploader
            title="Upload Rubric"
            text=".pdf"
            uploadEndpoint={GoodPointApi.Rubric.Upload(examObject.id)}
            onUpload={() => {
                updateExamState({ rubric: StepState.READY });
            }}
            formDataKey="rubrics"
        />
    )
}