import "./Products.css"
import pipelineImg from 'src/assets/home/exam-master-pipeline.jpg'
//import ReactPlayer from 'react-player/youtube'
import { ContactPopup, Subject } from "src/pages/home/components/ContactPopup";
import FeatureItem from 'src/pages/home/components/FeatureItem';
import heroImg from 'src/assets/home/exam-hero.jpg';
import ProductHeroSection from 'src/pages/home/components/ProductHeroSection';
import ScrollAnimation from "react-animate-on-scroll";

const demoPlaceholder = "I would like to set up a short meeting to find out more about AI Exam Master. Please reach out to me by email."

function ExamMasterProduct() {
    return (
        <div>
            <ProductHeroSection
                title="AI Exam Master"
                subtitle="Custom problem sets and solutions for an enhanced learning experience"
                image={heroImg} square
                CTASection={CTASection}
            />
            <FeaturesSection />
            {
                //<HowItWorksSection />
            }
            <TryNowSection />
        </div>
    );
}

const CTASection = () => {
    const bigger = true;
    return (
        <div className="flex-row justify-start gap-4">
            <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                <button className={`clickable ${bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'} leading-snug bg-white hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`}>
                    Request a demo
                </button>
            </ContactPopup>
        </div>
    )
}

function FeaturesSection() {
    return (
        <div className="flex-col justify-start items-start pb-4">
            <div className="flex-col gap-6 pb-6">

                <ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'>
                    <FeatureItem title="AI-Powered Creation" text="Custom problem sets generated with Wolfram Alpha-powered solutions" />
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'>
                    <FeatureItem title="Smart Adaptation" text="Question difficulty automatically adjusted and progress tracked for optimized learning." />
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'>
                    <FeatureItem title="Real Time Collaborative Learning" text="Group study support and classroom interaction for collective education." />
                </ScrollAnimation>
            </div>
            <ScrollAnimation animateIn='fadeIn'
                animateOut='fadeOut'>
                <div>
                    <img src={pipelineImg} alt="Exam Master Pipeline" className="w-full" />
                </div>
            </ScrollAnimation>

        </div>
    );
}


// function HowItWorksSection() {
//     return (
//         <div className="flex-col justify-start items-start pb-4">
//             <div className="mx-auto w-full max-w-[800px] aspect-video rounded">
//                 <ReactPlayer 
//                     url="https://www.youtube.com/watch?v=TODO"
//                     width={"100%"}
//                     height={"100%"}
//                     controls
//                 />
//             </div>
//         </div>
//     );
// }

// function TryNowSection() {
//     return (
//         <div className="flex-col justify-start items-start py-12">
//             <div>
//                 <h2 className="py-2 font-bold text-2xl kanit-regular">
//                     Try AI Exam Master Now
//                 </h2>
//                 <span className="text-gray-600 text-sm">
//                     Request a demo to receive custom problem sets and solutions powered by AI.
//                 </span>
//             </div>
//             <div className="flex-row justify-content-center py-4 gap-4">
//                 <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
//                     <button className="bg-orange-300 hover:bg-orange-400 text-black outline outline-2 rounded">
//                         Request a demo
//                     </button>
//                 </ContactPopup>
//             </div>
//         </div>
//     )
// }


function TryNowSection() {
    const bigger = true;
    return (
        <div className={`relative min-h-screen flex overflow-clip px-5 sm:px-10 bg-primary-gradient rounded-3xl text-center`}>
            <div className="max-w-screen-xl mx-auto flex flex-col justify-center items-center ">
                <ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'>
                    <h1 className="afacad-flux-normal text-6xl">
                        Try AI Exam Master Now</h1>
                    <span className="text-gray-600 text-sm afacad-flux-normal">
                        Request a demo to receive custom problem sets and solutions powered by AI.
                    </span>
                </ScrollAnimation>
                <div className="flex-row justify-content-center py-4 gap-4">
                    <ContactPopup subject={Subject.DEMO} placeholder={demoPlaceholder}>
                        <button className={`clickable ${bigger ? 'lg:text-xl p-5 text-sm' : 'text-xs lg:text-sm'} leading-snug bg-secondary hover:bg-accent text-black font-semibold shadow-xl rounded-3xl afacad-flux-normal transition-all ease-in duration-200 h-7 flex items-center justify-center`}>
                            Request a demo
                        </button>
                    </ContactPopup>
                </div>
            </div>
        </div>
    );
}


export default ExamMasterProduct;